import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import RctThemeProvider from "./RctThemeProvider";
import RctDefaultLayout from "./DefaultLayout";
import AppSignIn from "./SigninFirebase";
import AppSignUp from "./SignupFirebase";
import { setIsAdmin, getUserData } from "Store/Actions";
import { auth, db } from "../Firebase/index";
import { ForgotPassword } from "./ForgotPassword";
import PrivacyPolicy from "../Routes/privacy-policy";

const InitialPath = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/auth/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const getUserRole = async (userUid, dispatch) => {
  const snap = await db.collection("restaurants").doc(userUid).get();

  const data = snap.data();
  dispatch(getUserData(data));
  if (data) {
    if (data.role === "admin") {
      dispatch(setIsAdmin(true));
    } else {
      dispatch(setIsAdmin(false));
    }
  }
};

function App(props) {
  const authUser = useSelector((state) => state.authUser);
  const localUserData = useSelector((state) => state.authUser.localUserData);
  const userDaata = useSelector((state) => state.authUser.userDaata);
  const reloadPageOnAuthChange = useSelector((state) => state.authUser.reloadPageOnAuthChange);
  const dispatch = useDispatch();
  const { user } = authUser;
  const { location, match } = props;
  let history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (location.pathname !== "/auth/signin" && location.pathname !== "/auth/signup") {
        if (!user) {
          //return <Redirect to={"/auth/signin"} />;
          // history.push("/auth/signin");
        }
      }
      if (location.pathname === "/auth/signin" || location.pathname === "/auth/signup") {
        if (user) {
          //leave it empty because we are stuck on react hooks loop
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!userDaata) {
      const userLocal = auth.currentUser ? auth.currentUser : undefined;
      if (userLocal?.uid) {
        getUserRole(userLocal?.uid, dispatch);
      }
    }
  }, [userDaata]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (auth.currentUser) {
        if (userDaata?.role === "admin") {
          history.replace("/dashboard/account-request");
          return;
        } else if (userDaata?.role === "merchant") {
          history.replace("/dashboard/goldfinch");
          return;
        }
      } else {
        history.replace("/auth/signin");
      }
    }
  }, [userDaata, location.pathname]);

  return (
    <RctThemeProvider>
      <NotificationContainer />
      <InitialPath path={`${match.url}dashboard`} authUser={user} component={RctDefaultLayout} />
      <Route path="/auth/signin" component={AppSignIn} />
      <Route path="/auth/signup" component={AppSignUp} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
    </RctThemeProvider>
  );
}

export default App;
