import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@material-ui/core";
import { auth } from "../../Firebase";
import { capitalize } from "lodash";
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import SelectPaymentMethod from "./select-payment-method";
import CardInput from "./card-input";
import { addPaymentMethodsAction } from "Store/Actions/UserActions";

export default function FranchiseCard({ data }) {
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const [quantity, setQuantity] = useState(1);
  const [loader, setLoader] = useState(false);
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const confirm = useConfirm();
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const buyPackage = async (event, paymentMethodId = paymentMethod) => {
    try {
      if (!paymentMethodId) {
        document.getElementById("card-form-submit").click();
        return;
      } else if (paymentMethodId) {
        handleClose();
        setLoader(true);
      }

      let fetchURL = function_url + "/createSubscription";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/createSubscription";
      const authToken = await auth.currentUser.getIdToken();

      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": authToken },
        body: JSON.stringify({
          customer: localUserData.stripeId,
          packageType: data?.packageType,
          itemId: data?.stripePriceId,
          quantity,
          paymentMethodId,
          customerUid: localUserData.uid,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        let successMessage = "Package Subscribed Successfully. ";
        if (result?.isFree) {
          successMessage += "30 days free trial started, you'll be charged after free trail.";
        }

        NotificationManager.success(successMessage);
      }
      setLoader(false);
    } catch (err) {
      console.log(err, "error response");
      if (err) NotificationManager.error(JSON.stringify(err));
      setLoader(false);
    }
    setPaymentMethod("");
  };

  const createNewCardAndPay = async (token) => {
    if (!token || !localUserData?.stripeId) {
      return;
    }
    handleClose();
    setLoader(true);

    let fetchURL = function_url + "/createCard";
    // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/createCard";
    const authToken = await auth.currentUser.getIdToken();

    try {
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": authToken },
        body: JSON.stringify({
          token: token.id,
          customer: localUserData?.stripeId,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        dispatch(addPaymentMethodsAction(result?.paymentMethods));
        await buyPackage(null, result?.paymentMethods?.id);
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
      setLoader(false);
    }
  };

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 20 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="franchise-card__container">
        <div class="ribbon">
          {data?.discount && (
            <div class="wrap">
              <span class="ribbon6">{data.discount}% discount</span>
            </div>
          )}
        </div>
        <div className="franchise-card">
          <div className="franchise-card__header">
            <h6 className="franchise-card__header__price">
              {" "}
              <sup style={{ fontSize: 15 }}>$</sup>
              {data?.price}
            </h6>
            <h6 className="franchise-card__header__duration">
              {" "}
              {capitalize(data?.isTest ? "Test Per Day" : data?.type)}
            </h6>
            <div className={"theme-line-breaker mb-4"} />
          </div>
          <div className="franchise-card__body">
            <p>
              <img src="/assets/images/img/arrow.svg" width="22" alt="Arrow" className="mr-20" />
              Live Portal Demo
            </p>
            <p>
              <img src="/assets/images/img/arrow.svg" width="22" alt="Arrow" className="mr-20" />
              Account Set up
            </p>
            <p>
              <img src="/assets/images/img/arrow.svg" width="22" alt="Arrow" className="mr-20" />
              E-mail support
            </p>
          </div>
          <div className="franchise-card__footer">
            <div className={"theme-line-breaker mb-4"} />
            <div>
              <span>Franchise:</span>{" "}
              <select value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))}>
                {new Array(20).fill(0).map((_, i) => (
                  <option key={v4()} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <Button onClick={handleOpen} variant="contained" color="primary" className="buy-now__button">
          Buy Now
        </Button>
      </div>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} disableEnforceFocus>
        <DialogTitle>Buy Franchise Package</DialogTitle>
        <DialogContent>
          <div className="buy-franchise__pricing_details">
            <div className="buy-franchise__table">
              <div className="buy-franchise__table_header">
                <p>#</p>
                <p>Name</p>
                <p>Quantity</p>
                <p>Price</p>
              </div>
              <div className={"mb-25 theme-line-breaker"} />
              <div className="buy-franchise__table_body">
                <p>01</p>
                <p>{capitalize(data?.name + " " + data?.type)}</p>
                <p>{quantity}</p>
                <p>${data?.price}</p>
              </div>
              <div className="mt-30 buy-franchise__table_footer">
                <p></p>
                <p></p>
                <p>Total</p>
                <p>${data?.price * quantity}</p>
              </div>
            </div>
            <>
              <h3>Payment Details</h3>
              <div className={"mb-25 theme-line-breaker"} />
              <CardInput submitFunction={createNewCardAndPay} />
              <SelectPaymentMethod setValue={setPaymentMethod} value={paymentMethod} />
            </>
          </div>
        </DialogContent>
        <DialogActions className="mt-10">
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={buyPackage} type="submit" variant="contained" color="primary">
            Buy Package
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
