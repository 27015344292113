import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "@material-ui/core/Button";
import draftToHtml from "draftjs-to-html";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MUIDataTable from "mui-datatables";
import EditIcon from "../../Assets/img/pen.svg";
import ViewIcon from "../../Assets/img/view.svg";
import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { auth, db } from "../../Firebase";
import Header from "./../../Components/NewHeader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { convertToRaw, EditorState } from "draft-js";
import { sendMail } from "../../Util/mail";

function AllAccounts({ loading, setLoading, ...props }) {
  const history = useHistory();
  const [allAccountsData, setAllAccountsData] = useState(props.allAccountsData);
  const [viewModal, setViewModal] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [statusReasonMessage, setStatusReasonMessage] = useState("");
  const [changeStatusData, setChangeStatusData] = useState({
    uid: null,
    key: null,
    status: null,
    email: null,
  });
  const [selectedId, setSelectedId] = useState("");
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPopover, setAnchorPopover] = useState(null);
  const [idPopover, setIdPopover] = useState(null);

  const handlePopoverOpen = (event, id) => {
    setAnchorPopover(event.currentTarget);
    setIdPopover(id);
  };

  const handlePopoverClose = () => {
    setAnchorPopover(null);
    setIdPopover(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approveClient = async (userUid, key, status, email) => {
    try {
      setLoading(true);
      setIsMore(false);
      const partner = allAccountsData.find((item) => item.uid === userUid);

      let stripeId = partner?.stripeId || null;
      if (partner && status === "approved" && !partner?.stripeId) {
        // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/createStripeUser";
        let fetchURL = function_url + "/createStripeUser";
        const authToken = await auth.currentUser.getIdToken();

        let response = await fetch(fetchURL, {
          method: "POST",
          headers: { "Content-Type": "application/json", "Authorization": authToken },
          body: JSON.stringify({
            email: partner.email,
            userId: partner.uid,
            stripeId: partner?.stripeId,
            name: partner?.companyName,
            phone: partner?.phone,
          }),
        });
        if (response.ok) {
          let result = await response.json();
          if (result?.customer) stripeId = result.customer;
          NotificationManager.info("User account approved successfully");
        }
      }
      await db.collection("restaurants").doc(userUid).update({
        status,
        labelClass: "badge-success",
        actionReason: statusReasonMessage,
      });

      // sendMail({
      //   email: partner.email,
      //   status: "Account Approved",
      //   html: "<h1>Your account has been approved by admin.</h1>",
      //   text: "Your account has been approved by admin.",
      // });

      props.getData();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
    }
  };

  const handleChangeStatus = async (changeStatusData) => {
    if (changeStatusData?.uid && changeStatusData?.status !== "approved") {
      setOpen(true);
    } else if (changeStatusData?.uid && changeStatusData?.status === "approved") {
      await approveClient(changeStatusData.uid, changeStatusData.key, changeStatusData.status, changeStatusData.email);
    }
  };

  const changeStatus = async (html, text) => {
    handleClose();
    await approveClient(changeStatusData.uid, changeStatusData.key, changeStatusData.status, changeStatusData.email);

    sendMail({ email: changeStatusData.email, status: "Account " + changeStatusData.status, text, html });
    setChangeStatusData({ uid: null, key: null, status: null, email: null });
  };

  const columns = [
    {
      name: "index",
      label: "Partner ID",
      options: {
        customBodyRender: (value) => {
          const currentIndex = value + 1;
          return currentIndex > 99 ? currentIndex : currentIndex > 9 ? "0" + currentIndex : "00" + currentIndex;
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        customBodyRender: (value) => moment(value).format("MMM-DD-YYYY"),
      },
    },
    {
      name: "companyName",
      label: "Partner Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone",
      label: "Contact Number",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const order = allAccountsData[tableMeta?.rowIndex];
          const key = tableMeta?.rowIndex;
          return (
            <>
              <span
                id={key}
                aria-owns={Boolean(anchorPopover) ? "mouse-over-popover" + key : undefined}
                aria-haspopup="true"
                onMouseEnter={(anchor) =>
                  value != "approved" && value != "Waiting For Approval" && handlePopoverOpen(anchor, key)
                }
                onMouseLeave={(anchor) => handlePopoverClose(anchor, key)}
                className={`badge ${
                  value === "approved"
                    ? "badge-success"
                    : value === "Waiting For Approval"
                    ? "badge-info"
                    : value === "Suspended"
                    ? "badge-warning"
                    : "badge-danger"
                } text-capitalize`}
                style={{
                  padding: "0.8rem 1rem",
                  fontSize: 12,
                }}
              >
                <b>{value}</b>
              </span>
              {
                <Popover
                  id={"mouse-over-popover" + key}
                  style={{
                    pointerEvents: "none",
                  }}
                  open={anchorPopover && idPopover == key}
                  anchorEl={anchorPopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <div style={{ padding: 10 }}>
                    <Typography variant="body2">
                      {"\n"}
                      {order?.actionReason}
                    </Typography>
                  </div>
                </Popover>
              }
            </>
          );
        },
      },
    },
    {
      name: "Actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const order = allAccountsData[tableMeta?.rowIndex];
          const key = tableMeta?.rowIndex;
          return (
            <div style={{ position: "relative" }}>
              <img
                style={{ cursor: "pointer", margin: "0 5px" }}
                onClick={(event) => {
                  history.push(`/dashboard/admin/view-merchant/${order.uid}`);
                }}
                src={ViewIcon}
                alt={"View details"}
              />
              <img
                style={{ cursor: "pointer", margin: "0 5px" }}
                onClick={(event) => {
                  history.push(`/dashboard/admin/update-merchant/${order.uid}`);
                }}
                src={EditIcon}
                alt={"Edit details"}
              />
              <span
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  marginLeft: 25,
                }}
                aria-controls={selectedId === order?.uid ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={selectedId === order?.uid ? "true" : undefined}
                onClick={(event) => {
                  setSelectedId(order?.uid);
                  setIsMore((prev) => !prev);
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </span>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isMore && selectedId === order?.uid}
                onClose={() => {
                  setAnchorEl(null);
                  setSelectedId(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  value={10}
                  onClick={() =>
                    setChangeStatusData({
                      uid: order.uid,
                      key,
                      status: "approved",
                      email: order.email,
                    })
                  }
                  disabled={order.status === "approved"}
                >
                  Approved
                </MenuItem>

                <MenuItem
                  value={20}
                  onClick={() =>
                    setChangeStatusData({
                      uid: order.uid,
                      key,
                      status: "Rejected",
                      email: order.email,
                    })
                  }
                  disabled={order.status === "Rejected"}
                >
                  Rejected
                </MenuItem>
                <MenuItem
                  value={20}
                  onClick={() =>
                    setChangeStatusData({
                      uid: order.uid,
                      key,
                      status: "Suspended",
                      email: order.email,
                    })
                  }
                  disabled={order.status === "Suspended"}
                >
                  Suspended
                </MenuItem>
              </Menu>
              {/* ) : (
                ""
              )} */}
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    handleChangeStatus(changeStatusData);
  }, [changeStatusData]);

  useEffect(() => {
    const data = props.allAccountsData;
    setAllAccountsData(data);
  }, [props.allAccountsData]);

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 20 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Header title="Account Requests" />
      <div className="table-responsive" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <MUIDataTable
          data={(allAccountsData || []).map((f, index) => ({ ...f, index }))}
          title={
            <h1 className={"font-weight-bold"} style={{ color: "#242424" }}>
              All Partners
            </h1>
          }
          columns={columns}
          options={{
            selectableRows: "none",
            rowsPerPageOptions: [10, 50, 100],
            elevation: 0,
            download: false,
            filter: false,
            print: false,
          }}
        />
        <TextEditorDialog
          open={open}
          handleClose={handleClose}
          setStatusReasonMessage={setStatusReasonMessage}
          changeStatus={changeStatus}
          statusReasonMessage={statusReasonMessage}
        />
        <NotificationContainer />
      </div>
    </>
  );
}

function TextEditorDialog({ open, handleClose, setStatusReasonMessage, changeStatus, statusReasonMessage }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [!open]);
  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
      <DialogContent>
        <h1 className="text-center mb-25">Add Reason</h1>
        <div style={{ position: "relative" }}>
          <Editor
            editorState={editorState}
            editorClassName="htmlEditorContentArea"
            onEditorStateChange={setEditorState}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          // disabled={editorState.getCurrentContent().hasText()}
          onClick={() =>
            changeStatus(
              draftToHtml(convertToRaw(editorState.getCurrentContent())),
              editorState.getCurrentContent().getPlainText()
            )
          }
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AllAccounts;
