import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { getDrenchAction, deleteDrenchAction, activeDrenchByAdmin } from "Store/Actions/createForm";
import { Input, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import PenIcon from "../../Assets/img/pen.svg";
import BinIcon from "../../Assets/img/bin.svg";
import { makeStyles } from "@material-ui/core/styles";
import { auth, db } from "../../Firebase";
import Header from "./../../Components/NewHeader";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import { store } from "Store";
import DayPicker from "./../../Components/DayPicker";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Input as TimeInput } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ActivateInactivateCampaign(props) {
  const classes = useStyles();
  const history = useHistory();
  const [currentCategory, setCurrentCategory] = useState("Select");
  const [recentOrders, setRecentOrders] = useState(null);
  const drenches = useSelector((state) => state.Drench.drenches);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({ id: null, type: null });
  const [editLoading, setEditLoading] = useState(false);
  const [showSwitchToGoldfinchDialog, setShowSwitchToGoldfinchDialog] = useState(false);
  const [selectedFinchOfTypeChange, setSelectedFinchOfTypeChange] = useState({});

  const handleClose = () => {
    setOpen(false);

    setTimeout(() => {
      getData();
    }, [500]);
  };

  useEffect(() => {
    getData();

    return () => {
      setCurrentCategory("Select");
      setRecentOrders(null);
    };
  }, []);

  const selectCategory = async (title) => {
    setCurrentCategory(title);
    const filter = recentOrders?.filter((item) => item.companyName === title);
    dispatch(getDrenchAction(filter[0]?.uid));
  };

  const deleteDrench = (key, type, restuarantId) => {
    dispatch(
      deleteDrenchAction(key, type === undefined || type === "drenches" ? "drenches" : "drizzles", restuarantId)
    );
  };

  const drenchActiveInactive = (data) => {
    const btAdmin = "assignByAdmin";
    const checkAdmin = data.active;
    dispatch(activeDrenchByAdmin({ data, btAdmin, checkAdmin }));
  };

  const switchType = async (data, notificationMessage, startTime, endTime, skipDays) => {
    try {
      const function_url = store?.getState()?.Cf?.function_url || "";
      const token = await auth.currentUser.getIdToken();
      await fetch(function_url + "/changeFinchType", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          ...data,
          type: data.type === "finch" ? "drenches" : "drizzles",
          notificationMessage,
          finchId: data.id,
          startTime,
          endTime,
          skipDays,
        }),
      });

      dispatch(getDrenchAction());
    } catch (e) {
      console.log(e.message);
    }
  };

  const getData = async () => {
    try {
      const snap = await db.collection("restaurants").where("role", "==", "merchant").get();
      const data = snap.docs.map((doc) => doc.data());
      setRecentOrders([...data]);
    } catch (error) {
      console.log(error, "RECENT_ORDERS_ERROR");
    }
  };

  const columns = [
    {
      name: "title",
      label: "Title",
      options: {},
    },

    {
      name: "drenchTimeStart",
      label: "Start Date",
      options: {
        setCellProps: () => ({ style: { minWidth: "200px" } }),
        customBodyRender: (value) => moment(new Date(value?.seconds * 1000)).format("MMM-DD-YYYY"),
      },
    },
    {
      name: "drenchTimeEnd",
      label: "End Date",
      options: {
        setCellProps: () => ({ style: { minWidth: "200px" } }),
        customBodyRender: (value) => moment(new Date(value?.seconds * 1000)).format("MMM-DD-YYYY"),
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              padding: "4px 14px",
              backgroundColor: value === "finch" ? "#11A4C2" : "#E5B11E",
              borderRadius: 6,
              color: "#fff",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </div>
        ),
      },
    },
    {
      name: "currentStatus",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const goldfinch = drenches[tableMeta?.rowIndex];
          let backgroundColor,
            fontSize = 14;
          if (!goldfinch?.active) {
            backgroundColor = "gray";
          } else if (value === "Claimed All") {
            backgroundColor = "#1B5E20";
          } else if (value === "Awaiting") {
            backgroundColor = "#11A4C2";
          } else if (value === "Dying Up") {
            backgroundColor = "#4950BA";
            fontSize = 14;
          } else if (value === "Active") {
            backgroundColor = "#1CBC3C";
          } else {
            backgroundColor = "#DC2845";
          }

          return (
            <div
              style={{
                padding: "4px 6px",
                backgroundColor,
                borderRadius: 6,
                color: "#fff",
                textAlign: "center",
                fontWeight: 500,
                fontSize,
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMetaData) => {
          const data = drenches[tableMetaData?.rowIndex];
          return (
            <div className={"d-flex justify-content-start align-items-center"} style={{ gap: 16 }}>
              <img
                src={PenIcon}
                style={{ cursor: "pointer" }}
                alt={"edit"}
                onClick={() =>
                  history.push(
                    `/dashboard/update-campaign/${data?.type === "goldfinch" ? "GoldFinch" : "Finch"}/${value}`
                  )
                }
              />
              <img
                src={BinIcon}
                style={{ cursor: "pointer" }}
                alt={"delete"}
                onClick={() => {
                  const data = drenches.filter((drench) => drench.id === value)[0];
                  setDeleteData({
                    id: data.drenchKey || data.key,
                    type: data.type,
                  });
                  setOpen(true);
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: "enableGoldfinch",
      label: "Active/Inactive",
      options: {
        filter: false,
        customBodyRender: (_, tableMetaData) => {
          const data = drenches[tableMetaData?.rowIndex];
          return (
            <div className={"d-flex justify-content-start align-items-center"}>
              <Switch
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={data.active}
                onChange={() => drenchActiveInactive(data)}
              />
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Switch To Goldfinch",
      options: {
        filter: false,
        customBodyRender: (value, tableMetaData) => {
          const data = drenches[tableMetaData?.rowIndex];
          return (
            <div className={"d-flex justify-content-around align-items-center"}>
              <input
                type="checkbox"
                checked={data.type === "goldfinch"}
                disabled={data.type === "goldfinch"}
                onChange={() => {
                  if (data.type !== "goldfinch") {
                    setShowSwitchToGoldfinchDialog(true);
                    setSelectedFinchOfTypeChange(data);
                  }
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    recentOrders && (
      <>
        <Header title="Active Campaigns" />
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <SwitchToGoldfinchDialog
            switchType={switchType}
            showSwitchToGoldfinchDialog={showSwitchToGoldfinchDialog}
            setShowSwitchToGoldfinchDialog={setShowSwitchToGoldfinchDialog}
            selectedFinchOfTypeChange={selectedFinchOfTypeChange}
          />

          <Backdrop className={classes.backdrop} open={editLoading} onClick={() => setEditLoading(!editLoading)}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <Label style={{ fontSize: 17, marginBottom: 8 }}>Select Partner</Label>
              <div className="mui-default-input-styles">
                <Autocomplete
                  multiple={false}
                  options={recentOrders}
                  getOptionLabel={(option) => option.companyName}
                  style={{ padding: 0, width: 300, height: "3rem" }}
                  onChange={(_event, value) => value && selectCategory(value.companyName)}
                  renderInput={(params) => (
                    <TextField {...params} style={{ padding: 0, border: "none" }} variant="outlined" />
                  )}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <MUIDataTable
                data={currentCategory !== "Select" && drenches?.length ? drenches : []}
                title={<h2>Campaigns</h2>}
                columns={columns}
                options={{
                  selectableRows: false,
                  rowsPerPageOptions: [10, 50, 100],
                  elevation: 0,
                  download: false,
                  print: false,
                }}
              />
            </Grid>
          </Grid>

          <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
            <DialogTitle id="draggable-dialog-title">
              Delete {deleteData.type === "drizzles" ? "finch" : "Goldfinch"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this {deleteData.type === "drizzles" ? "finch" : "Goldfinch"}?<br />{" "}
                This action can not be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                No
              </Button>
              <Button
                onClick={async () => {
                  await deleteDrench(deleteData?.id, deleteData.type, deleteData?.restuarantID);
                  setDeleteData({ id: null, type: null });
                  handleClose();
                }}
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  );
}

const SwitchToGoldfinchDialog = ({
  switchType,
  showSwitchToGoldfinchDialog,
  setShowSwitchToGoldfinchDialog,
  selectedFinchOfTypeChange,
}) => {
  const [goldfinchNotification, setGoldfinchNotification] = useState({
    details: null,
    value: "",
  });
  const [selectedDay, setSelectedDay] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const closeDialog = () => {
    setShowSwitchToGoldfinchDialog(false);
    setGoldfinchNotification({ details: null, value: "" });
    setSelectedDay([]);
    setStartTime(null);
    setEndTime(null);
  };
  return (
    <Dialog open={showSwitchToGoldfinchDialog} onClose={closeDialog}>
      <DialogTitle>Convert to GoldFinch</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <>
            <Grid item sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                Opening Time
                <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Label>
              <TimeInput
                // type={"time"}
                // value={startTime}
                // showSecond={false}
                // defaultValue={moment()}
                // className="xxx"
                // onChange={({ target: { value } }) => setStartTime(moment(value, "HH:mm"))}
                // use12Hours={false}
                // fullWidth
                type="time"
                name="timePicker"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                Closing Time
                <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Label>
              <TimeInput type="time" name="timePicker" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                Days to Skip
                <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Label>
              <DayPicker selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                Enter notification message for goldfinch.
                <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Label>
              <Input
                type="text"
                value={goldfinchNotification.value}
                onChange={(e) =>
                  setGoldfinchNotification({
                    ...goldfinchNotification,
                    value: e.target.value,
                  })
                }
              />
            </Grid>
          </>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Close
        </Button>
        <Button
          onClick={() =>
            switchType(selectedFinchOfTypeChange, goldfinchNotification.value, startTime, endTime, selectedDay).then(
              () => closeDialog()
            )
          }
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
