import React from "react";
import { Label } from "reactstrap";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

function CardInput({ submitFunction }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) return;
    submitFunction(paymentMethod);
  };

  return (
    <form onSubmit={handleSubmit} id="card-form">
      <Label className="mb-10">Card</Label>
      <CardElement id="card-element" />
      <input type="submit" id="card-form-submit" style={{ display: "none" }} />
    </form>
  );
}
export default CardInput;
