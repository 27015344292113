/**
 * Firebase Login
 * Reactify comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { getReactAppAPIKey } from "../Helpers/helpers";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY || getReactAppAPIKey(),
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || "goldfinch-staging-ce848.firebaseapp.com",
  databaseURL: process.env.REACT_APP_DATABASE_URL || "https://goldfinch-staging-ce848-default-rtdb.firebaseio.com",
  projectId: process.env.REACT_APP_PROJECT_ID || "goldfinch-staging-ce848",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || "goldfinch-staging-ce848.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || "201404244650",
  appId: process.env.REACT_APP_APP_ID || "1:201404244650:web:2f5bfc2737a1405e1026d3",
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || "G-NEJW80ER5B",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
if (process.env.REACT_APP_IS_EMULATOR) {
  auth.useEmulator(process.env.REACT_APP_AUTH_DOMAIN);
}
const database = firebase.database();
const db = firebase.firestore();
const storage = firebase.storage();
if (process.env.REACT_APP_IS_EMULATOR) {
  storage.useEmulator(
    process.env.REACT_APP_STORAGE_BUCKET_HOST,
    parseInt(process.env.REACT_APP_STORAGE_BUCKET_PORT) || 9199
  );
}
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  database,
  db,
  storage,
};
