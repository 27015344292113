import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { collapsedSidebarAction, logoutUserFromFirebase } from "Store/Actions";
import SidebarContent from "./SidebarContent";
import AgencySidebar from "../AgencyMenu/AgencySidebar";
import Button from "@material-ui/core/Button";
import LogoutIcon from "../../Assets/img/logout-icon.svg";
import BuyPackagesIcon from "../../Assets/img/buy-packages-icon.svg";
import MyProfileIcon from "../../Assets/img/my-profile.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  shouldComponentUpdate(nextProps) {
    const {
      enableSidebarBackgroundImage,
      selectedSidebarImage,
      isDarkSidenav,
      locale,
    } = this.props;
    if (
      enableSidebarBackgroundImage !== nextProps.enableSidebarBackgroundImage ||
      selectedSidebarImage !== nextProps.selectedSidebarImage ||
      isDarkSidenav !== nextProps.isDarkSidenav ||
      locale
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  render() {
    const {
      enableSidebarBackgroundImage,
      selectedSidebarImage,
      isDarkSidenav,
      agencySidebar,
    } = this.props;
    return (
      <Fragment>
        <div
          className={classNames("rct-sidebar", {
            "background-none": !enableSidebarBackgroundImage,
          })}
          style={{
            backgroundImage: enableSidebarBackgroundImage
              ? `url(${selectedSidebarImage})`
              : "none",
          }}
        >
          <div
            className={classNames("rct-sidebar-content", {
              "sidebar-overlay-dark": isDarkSidenav,
              "sidebar-overlay-light": !isDarkSidenav,
            })}
          >
            <div
              className="site-logo pt-20"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Link to="/" className="logo-mini">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/img/logo-color.png`}
                  className="mr-14"
                  alt="site logo"
                  width="35"
                  height="35"
                />
              </Link>
              <Link to="/" className="logo-normal">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/img/logo-color.png`}
                  className="img-fluid ml-1"
                  alt="site-logo"
                  width="75"
                />
              </Link>
              <p
                style={{
                  fontSize: 20,
                  color: "white",
                  fontWeight: 700,
                }}
              >
                GoldFinch
              </p>
            </div>
            <div className="rct-sidebar-wrap" style={{ marginTop: "0.5rem" }}>
              <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={{ height: "calc(100vh - 60px)" }}
              >
                {!agencySidebar ? <SidebarContent /> : <AgencySidebar />}
              </Scrollbars>
            </div>
            <div
              style={{
                width: "88%",
                position: "absolute",
                bottom: 130,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              {this.props?.authUser?.userDaata &&
                this.props?.authUser?.userDaata?.role !== "admin" && (
                  <>
                    <Button
                      // className={"buy-more-packages-button"}
                      variant={"contained"}
                      onClick={() =>
                        this.props.history.push("/dashboard/profile-merchant")
                      }
                      style={{
                        width: "100%",
                        height: 50,
                        backgroundColor: "#363636",
                        marginBottom: 20,
                      }}
                    >
                      <img
                        className={"mr-2"}
                        src={MyProfileIcon}
                        alt={"icon"}
                      />
                      My Profile
                    </Button>
                    <Button
                      // className={"buy-more-packages-button"}
                      variant={"contained"}
                      onClick={() =>
                        this.props.history.push("/dashboard/purchase-packages")
                      }
                      style={{
                        width: "100%",
                        height: 50,
                        backgroundColor: "#28B873",
                      }}
                    >
                      <img
                        className={"mr-2"}
                        src={BuyPackagesIcon}
                        alt={"icon"}
                      />
                      Buy Packages
                    </Button>
                  </>
                )}
              <Button
                onClick={() => this.setState({ ...this.state, open: true })}
                variant={"contained"}
                style={{ width: "100%", marginTop: "auto", height: 50 }}
                className={"mt-3 logout-button"}
              >
                <img className={"mr-2"} src={LogoutIcon} alt={"icon"} />
                Logout
              </Button>
            </div>
          </div>
        </div>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Logout</DialogTitle>
          <DialogContent style={{ width: "35rem" }}>
            <DialogContentText>
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleClose} color="primary">
              No
            </Button>
            <Button
              onClick={() => {
                this.props.logoutUserFromFirebase();
              }}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    collapsedSidebarAction: (value) => dispatch(collapsedSidebarAction(value)),
    logoutUserFromFirebase: () => dispatch(logoutUserFromFirebase()),
  };
};
// map state to props
const mapStateToProps = (state) => {
  const {
    enableSidebarBackgroundImage,
    selectedSidebarImage,
    collapsedSidebar,
    isDarkSidenav,
    locale,
  } = state?.settings;
  return {
    enableSidebarBackgroundImage,
    selectedSidebarImage,
    collapsedSidebar,
    isDarkSidenav,
    locale,
    authUser: state?.authUser,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
