import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Input, Label } from "reactstrap";
import "./index.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { capitalize, startCase } from "lodash";
import { v4 } from "uuid";
import {
  addPaymentMethodsAction,
  getCustomerSubscriptionAction,
  getPaymentMethodsAction,
  removePaymentMethodsAction,
  toggleSubscriptionAction,
} from "../../Store/Actions/UserActions";
import { auth, db } from "../../Firebase";
import { getUserData } from "../../Store/Actions/AuthActions";
import moment from "moment";
import { useConfirm } from "material-ui-confirm";

export default function OrderHistory() {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const confirm = useConfirm();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const paymentMethods = useSelector((state) => state?.UserReducer?.paymentMethods);
  const customerSubscription = useSelector((state) => state?.UserReducer?.customerSubscription);
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const [activePackages, setActivePackages] = useState({
    campaign: {},
    franchise: [],
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewCardSubmit = async (event) => {
    event.preventDefault();
    if (!localUserData?.stripeId) return;
    handleClose();
    setLoader(true);
    try {
      let fetchURL = function_url + "/createCard";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/createCard";
      const authToken = await auth.currentUser.getIdToken();

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) return;
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": authToken,
        },
        body: JSON.stringify({
          token: paymentMethod.id,
          customer: localUserData?.stripeId,
        }),
      });

      let result = await response.json();
      if (response.ok) {
        dispatch(addPaymentMethodsAction(result?.paymentMethods));
        if (result?.paymentMethods?.alreadyExists) {
          NotificationManager.info("Card already exists");
        } else NotificationManager.info("Card added successfully");
        await getUserInformation();

        return;
      }

      if (!response?.ok) {
        if (result?.message) NotificationManager.info(result?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
      setLoader(false);
    }
  };

  const getPaymentMethods = async () => {
    try {
      if (!localUserData?.stripeId) return;
      let fetchURL = function_url + "/getPaymentMethods";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/getPaymentMethods";

      const authToken = await auth.currentUser.getIdToken();
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": authToken,
        },
        body: JSON.stringify({
          customer: localUserData?.stripeId,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        dispatch(getPaymentMethodsAction(result?.paymentMethods));
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
    }
  };

  const getCustomerSubscription = async () => {
    try {
      if (!localUserData?.stripeId) return;
      setLoader(true);
      let fetchURL = function_url + "/getCustomerSubscription";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/getCustomerSubscription";

      const authToken = await auth.currentUser.getIdToken();
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": authToken,
        },
        body: JSON.stringify({
          customer: localUserData?.stripeId,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        dispatch(getCustomerSubscriptionAction(result?.subscription));
        getActivePackages(result?.subscription);
      }
      setLoader(false);
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
      setLoader(false);
    }
  };

  const removePaymentMethod = async (paymentMethodId) => {
    try {
      await confirm({
        title: "Are you sure you want to delete this card?",
        description: "All subscriptions associated with this card will be stopped.",
      });
    } catch (err) {
      return;
    }

    try {
      if (!paymentMethodId) return;
      setLoader(true);
      let fetchURL = function_url + "/removePaymentMethod";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/removePaymentMethod";
      const authToken = await auth.currentUser.getIdToken();

      let response = await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": authToken,
        },
        body: JSON.stringify({
          paymentMethodId,
          customer: localUserData?.stripeId,
        }),
      });
      if (response.ok) {
        dispatch(removePaymentMethodsAction(paymentMethodId));
        await getUserInformation();
        handleClose();
      }
      setLoader(false);
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
      handleClose();
      setLoader(false);
    }
  };

  const changeDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      if (!paymentMethodId) return;
      setLoader(true);

      await db.collection("restaurants").doc(localUserData.uid).update({
        defaultPaymentMethod: paymentMethodId,
      });

      db.collection("restaurants")
        .doc(localUserData.uid)
        .get()
        .then((doc) => dispatch(getUserData(doc.data())));
      setLoader(false);
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
      setLoader(false);
    }
  };

  const toggleSubscription = async (subscriptionId, type, toggleValue) => {
    setLoader(true);

    confirm({
      title: `${toggleValue ? "Activate" : "Cancel"} Subscription`,
      description: `Are you sure you want to ${toggleValue ? "activate" : "cancel"} this subscription?`,
    })
      .then(async () => {
        if (!subscriptionId) return;
        let fetchURL = function_url + "/toggleSubscription";
        // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/toggleSubscription";

        const authToken = await auth.currentUser.getIdToken();
        let response = await fetch(fetchURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": authToken,
          },
          body: JSON.stringify({
            userId: localUserData?.uid,
            subscriptionId,
            type,
            value: !toggleValue,
          }),
        });
        if (response.ok) {
          dispatch(toggleSubscriptionAction(subscriptionId));
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "error response");
        // NotificationManager.error(JSON.stringify(err));
        setLoader(false);
      });
  };

  const getActivePackages = (subscription) => {
    const tempObj = {
      campaign: {},
      franchise: [],
    };
    subscription?.forEach((item) => {
      if (
        item?.status !== "canceled" &&
        item?.packageType === "CAMPAIGN" &&
        moment().isBefore(moment(item?.currentPeriodEnd * 1000))
      ) {
        tempObj.campaign = item;
      }
      if (
        item?.status !== "canceled" &&
        item?.packageType === "FRANCHISE" &&
        moment().isBefore(moment(item?.currentPeriodEnd * 1000))
      ) {
        tempObj.franchise.push(item);
      }
    });
    setActivePackages(tempObj);
  };

  async function getUserInformation() {
    const snap = await db.collection("restaurants").doc(localUserData?.uid).get();
    const data = snap.data();
    dispatch(getUserData(data));
  }

  useEffect(() => {
    if (!paymentMethods?.length) getPaymentMethods();
    getCustomerSubscription();
    getUserInformation();
  }, []);

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 20 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={"mt-25 theme-line-breaker mb-4"} />
      <div className="mt-30 billing-container">
        <div className="container-small">
          <p className="heading">Order History</p>
          <div className="billing-container-child-parent">
            <div className="table">
              <div className="header">
                <span>Created Date</span>
                <span>Next Invoice</span>
                <span>Name</span>
                <span>Card</span>
                <span>Price</span>
                <span>Receipt Url</span>
              </div>
              <div className={"mt-15 mb-10 theme-line-breaker"} />
              <div className="body">
                {customerSubscription?.map((item) => (
                  <div key={v4()}>
                    <span>{moment(new Date(item?.created * 1000))?.format("ll")}</span>
                    {item?.status === "active" ? (
                      <span>
                        {item?.cancelAtPeriodEnd && "End On "}
                        {moment(item?.currentPeriodEnd * 1000).format("ll")}
                      </span>
                    ) : (
                      <span>Canceled, at {moment(item?.canceledAt * 1000).format("lll")}</span>
                    )}
                    <span>{item?.product}</span>
                    <span>{"**** " + item?.cardLast4}</span>
                    <span>${item?.price / 100}</span>
                    <span>
                      <a href={item?.receiptUrl} rel="noreferrer" target="_blank">
                        <img alt="Url" src="/assets/images/img/url.svg" />
                      </a>
                    </span>
                  </div>
                ))}
              </div>
              <div className="my-30 card-list-container">
                <p className="heading">Payment Method On File</p>
                <div className={"mt-15 mb-10 theme-line-breaker"} />
                <div className="cards">
                  {paymentMethods?.map((method) => (
                    <div key={v4()} className="card my-10">
                      {startCase(method?.cardBrand)} ending in {method?.cardLast4} expiring{" "}
                      {method?.cardExpMonth <= 9 ? "0" + method?.cardExpMonth : method?.cardExpMonth}/
                      {method?.cardExpYear}
                      <div className="actions">
                        <Button
                          onClick={() => removePaymentMethod(method.id)}
                          className="action_remove"
                          variant="contained"
                        >
                          Remove
                        </Button>
                        <Button
                          disabled={localUserData?.defaultPaymentMethod === method.id}
                          onClick={() => changeDefaultPaymentMethod(method.id)}
                          className="action_default"
                          variant="contained"
                        >
                          {localUserData?.defaultPaymentMethod !== method.id
                            ? "Make It Default"
                            : "Default Payment Method"}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>

                <p className="add_new" style={{ cursor: "pointer" }} onClick={handleClickOpen}>
                  Add New Credit Card
                </p>
              </div>
            </div>

            <div className="packages">
              {activePackages?.campaign?.id ? (
                <div className="card">
                  <div className="card-details">
                    <p>Active Campaign Package</p>
                    <div className={"theme-line-breaker"} />
                    <span>{capitalize(activePackages.campaign.productName)}</span>
                    <p>Expiry Date: {moment(activePackages.campaign.subscriptionEnd * 1000)?.format("DD-MM-YYYY")}</p>
                  </div>

                  {activePackages?.campaign?.cancelAtPeriodEnd ? (
                    <Button
                      onClick={() =>
                        toggleSubscription(
                          activePackages?.campaign.subscriptionId,
                          activePackages?.campaign.packageType,
                          activePackages?.campaign?.cancelAtPeriodEnd
                        )
                      }
                      className="reactivate_subscription button__absolute"
                      variant="contained"
                    >
                      Reactivate
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        toggleSubscription(
                          activePackages?.campaign.subscriptionId,
                          activePackages?.campaign.packageType,
                          activePackages?.campaign?.cancelAtPeriodEnd
                        )
                      }
                      className="cancel_subscription button__absolute"
                      variant="contained"
                    >
                      Cancel Subscriptions
                    </Button>
                  )}
                </div>
              ) : null}

              {activePackages?.franchise?.length ? (
                <div className="card" style={{ padding: "0.8rem 1rem" }}>
                  <div className="card-details card-long">
                    <p>Active Franchise Package</p>
                    <div className={"theme-line-breaker"} />
                    <div className="card-list__style_details_container">
                      {activePackages?.franchise?.map((item) => (
                        <div className="card-list__style_details">
                          <span style={{ flexBasis: "25%" }}>{capitalize(item.productName)}</span>
                          <span style={{ flexBasis: "45%" }}>
                            Expiry: {moment(item.subscriptionEnd * 1000)?.format("DD-MM-YYYY")}
                          </span>
                          {item?.cancelAtPeriodEnd ? (
                            <Button
                              style={{
                                flexBasis: "30%",
                                height: "1.8rem",
                                fontSize: "14px !important",
                              }}
                              onClick={() =>
                                toggleSubscription(item.subscriptionId, item.packageType, item?.cancelAtPeriodEnd)
                              }
                              className="reactivate_subscription"
                              variant="contained"
                            >
                              Reactivate
                            </Button>
                          ) : (
                            <Button
                              style={{
                                flexBasis: "30%",
                                height: "1.8rem",
                                fontSize: "14px !important",
                              }}
                              onClick={() =>
                                toggleSubscription(item?.subscriptionId, item?.packageType, item?.cancelAtPeriodEnd)
                              }
                              className="cancel_subscription"
                              variant="contained"
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle>{"Add New Payment Card"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleNewCardSubmit} id="card-form">
            <Label className="mb-10">Card</Label>
            <CardElement id="card-element" />
          </form>
        </DialogContent>
        <DialogActions className="mt-10">
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form="card-form" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
