import {
  ADD_FRANCHISES,
  DELETE_FRANCHISE,
  UPDATE_FRANCHISE,
} from "Store/Actions/types";

const INITIAL_STATE = {
  franchises: [],
};

let Franchise = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FRANCHISES:
      return {
        ...state,
        franchises: action.payload,
      };

    case DELETE_FRANCHISE:
      return {
        ...state,
        franchises: [
          ...state.franchises.filter(
            (franchise) => franchise.franchiseId !== action.payload
          ),
        ],
      };

    case UPDATE_FRANCHISE:
      return {
        ...state,
        franchises: [
          ...state.franchises.map((franchise) => {
            if (franchise.franchiseId === action.payload.franchiseId) {
              return action.payload;
            }
            return franchise;
          }),
        ],
      };

    default:
      return state;
  }
};

export default Franchise;
