import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup as InputGroup, FormGroup, Label, Input as TimeInput } from "reactstrap";
import { Input, Form, Feedback as FormFeedback, Input as FormInput } from "@availity/form";
import "react-rangeslider/lib/index.css";
import "rc-time-picker/assets/index.css";
import { getCategories } from "Store/Actions/createForm";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Grid, Tooltip } from "@material-ui/core";
import * as yup from "yup";
import Select from "@availity/select";
import "./style.scss";
import AppConfig from "../../Constants/AppConfig";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { GoldfinchConfig } from "../../Helpers/helpers";
import { useParams } from "react-router-dom";
import Header from "./../../Components/NewHeader";
import { db, storage } from "../../Firebase";
import { extension } from "mime-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ADD_FRANCHISES } from "./../../Store/Actions/types";
import { getActiveFranchisePackages, getActivePackagesItemsDetails } from "Util/subscriptions";
import DayPicker from "./../../Components/DayPicker";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const EditDrench = () => {
  let { type, id } = useParams();
  const drenches = useSelector((state) => state?.Drench?.drenches);
  const [editLoading, setEditLoading] = useState(false);
  const [singleDrenchData, setDrench] = useState({});
  const [openingHoursObj, setOpeningHoursObj] = useState({});
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const [companyName, setCompanyName] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [discountPercentage, setDiscountPercentage] = useState();
  const [WebsiteUrl, setWebsiteUrl] = useState();
  const [address, setAddress] = useState();
  const [quantity, setQuantity] = useState();
  const [phone, setPhone] = useState();
  const [latitude, setLatitude] = useState(AppConfig.defaultLat);
  const [longitude, setLongitude] = useState(AppConfig.defaultLng);
  const [startDate, onStartDate] = useState();
  const [endDate, onEndDate] = useState();
  const [range, setRange] = useState(500);
  const [drenchCode, setDrenchCode] = useState();
  const [drenchTitle, setDrenchTitle] = useState();
  const [drenchSubTitle, setDrenchSubTitle] = useState();
  const [notificationMessage, setNotificationMessage] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [errors, setErrors] = useState({
    termsAndConditions: "",
    startDate: "",
    endDate: "",
    location: "",
  });
  const [uploadedImages, setUploadedImages] = useState({
    image: null,
    success_image: null,
    logo: null,
  });
  const [remainingSubscription, setRemainingSubscription] = useState({});
  const [selectedFranchises, setSelectedFranchises] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // const loading = useSelector((state) => state.Drench.loading);
  const categories = useSelector((state) => state.Drench.categories);
  const franchises = useSelector((state) => state.Franchise.franchises);
  const function_url = useSelector((state) => state.Cf.function_url);

  const validateDates = () => {
    let endDateError = "";
    let startDateError = "";
    const current = moment();

    if (endDate && moment(endDate) < current) {
      endDateError = "End date should be after current date";
    } else {
      if (startDate && endDate) {
        const diff = moment(endDate).diff(startDate, "hours");
        if (moment(endDate).isBefore(moment(startDate))) {
          endDateError = "End date should be after start date";
          NotificationManager.error("Invalid form data!");
        }
        if (diff > GoldfinchConfig.hours) {
          // endDateError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_hours_text}!`;
          endDateError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_duration_text}!`;
          NotificationManager.error("Invalid form data!");
        }
      }
    }

    setErrors({ ...errors, endDate: endDateError, startDate: startDateError });
  };

  const getFranchise = async (data) => {
    try {
      const snap = await db.collection("franchises").where("restaurantId", "==", localUserData?.uid).get();
      const data = snap.docs.map((doc) => doc.data());
      dispatch({
        type: ADD_FRANCHISES,
        payload: data,
      });
      console.log(data, "DATA_FRANCHISE");
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  useEffect(() => {
    if (localUserData?.uid) {
      getFranchise();
    }
  }, [localUserData?.uid]);

  useEffect(() => {
    validateDates();
  }, [startDate, endDate]);

  useEffect(() => {
    if (categories.length) {
      getFinch();
    }
  }, [categories]);

  const getFinch = async () => {
    try {
      const snap = await db.collection("drenches").where("id", "==", id).get();
      const data = snap.docs.map((doc) => doc.data());
      setDrench(data?.[0]);
      const initialData = data?.[0];
      console.log(initialData, "initialData");
      let initial = {
        "title-name": initialData?.title,
        "phone": initialData?.phoneNumberText,
        "description": initialData?.drenchDescription,
        "notificationMessage": initialData?.notificationMessage,
        "quantity": initialData?.quantity || 0,
        "discountPercentage": initialData?.discountPercentage || "",
        "categories": categories.filter((c) => initialData?.categoriesID?.includes(c.id)).map((c) => c.id),
        "franchises":
          initialData?.franchises?.map((v) => {
            return franchises?.find((b) => v.franchiseId === b.franchiseId)?.franchiseId;
          }) || [],
      };
      setInitialValues(initial);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "GET_FINCH_EDIT");
    }
  };

  useEffect(() => {
    if (singleDrenchData) {
      if (singleDrenchData.drenchTimeStart) {
        onStartDate(
          moment(new Date(moment(singleDrenchData.drenchTimeStart.seconds * 1000))).format("YYYY-MM-DD HH:mm")
        );
      }
      if (singleDrenchData.drenchTimeEnd) {
        onEndDate(moment(new Date(singleDrenchData.drenchTimeEnd.seconds * 1000)).format("YYYY-MM-DD HH:mm"));
      }

      setDescription(singleDrenchData.drenchDescription);
      setPhone(singleDrenchData.phoneNumberText);
      setDrenchCode(singleDrenchData.drenchCode);
      setDiscountPercentage(singleDrenchData?.discountPercentage);
      setWebsiteUrl(singleDrenchData.drenchUrl);
      setQuantity(singleDrenchData.quantity);
      setDrenchTitle(singleDrenchData.drenchTitle);
      setDrenchSubTitle(singleDrenchData.drenchSubTitle);
      setLatitude(singleDrenchData.lat);
      setLongitude(singleDrenchData.lng);
      setRange(singleDrenchData.range && parseInt(singleDrenchData.range) / 1000);
      setNotificationMessage(singleDrenchData.notificationMessage);
      setAddress(singleDrenchData.address);
      setCompanyName(singleDrenchData.companyName);
      setSelectedDay(singleDrenchData.skipDays);
      setStartTime(singleDrenchData.startTime);
      setEndTime(singleDrenchData.endTime);
      setTitle(singleDrenchData.title);
      getAddress(singleDrenchData.address, singleDrenchData.lat, singleDrenchData.lng);
    }
  }, [singleDrenchData]);

  const onEdit = async (values) => {
    const franchisesData =
      values?.franchises?.map((v) => {
        return {
          franchiseId: v,
          geofenceId: franchises?.find((b) => b.franchiseId === v)?.geofenceId,
        };
      }) || [];

    if (
      !!(
        description !== "" &&
        latitude !== null &&
        longitude !== null &&
        startDate &&
        endDate &&
        title &&
        discountPercentage &&
        !errors.endDate &&
        !errors.startDate
      )
    ) {
      if (type === "GoldFinch") {
        editGoldfinch({
          ...singleDrenchData,
          companyName: companyName || localUserData?.companyName,
          lat: latitude || localUserData?.latitude,
          lng: longitude || localUserData?.longitude,
          address: address || singleDrenchData.address,
          contactText: phone || localUserData?.phone,
          quantity: parseInt(quantity) || "UNLIMITED",
          dateUpdated: new Date().toUTCString(),
          // detailedAddress: address || singleDrenchData.address,
          drenchDescription: description || "",
          drenchTimeStart: new Date(startDate),
          drenchTimeEnd: new Date(endDate),
          phoneNumberText: phone || "",
          title: title || "",
          categoriesID: values?.categories || [],
          drenchTitle: drenchTitle || "",
          drenchSubTitle: drenchSubTitle || "",
          drenchCode: drenchCode || "",
          notificationMessage: notificationMessage || "",
          openingHours: openingHoursObj,
          // range: range
          //   ? parseInt(range * 1000 + "")
          //   : singleDrenchData.range,
          // drenchUrl: `https://${replaceString}` || "",
          discountPercentage,
          key: singleDrenchData.key,
          id: singleDrenchData.key,
          finchId: singleDrenchData.key,
          type: `${type === "GoldFinch" ? "drenches" : "drizzles"}`,
          createdDate: +new Date(),
          geoFenceDistance: range,
          geoFenceId: singleDrenchData.geoFenceId,
          notificationId: singleDrenchData.notificationId,
          geoFenceCampaignId: singleDrenchData.geoFenceCampaignId,
          promotionalMessage: "",
          images: uploadedImages.image || singleDrenchData?.images,
          franchises: franchisesData,
          startTime,
          endTime,
          skipDays: selectedDay,
        });
      } else {
        editFinch({
          ...singleDrenchData,
          companyName: companyName || localUserData?.companyName,
          lat: latitude || localUserData?.latitude,
          lng: longitude || localUserData?.longitude,
          address: address || singleDrenchData.address,
          contactText: phone || localUserData?.phone,
          quantity: parseInt(quantity) || "UNLIMITED",
          dateUpdated: new Date().toUTCString(),
          detailedAddress: address || singleDrenchData.address,
          drenchDescription: description || "",
          drenchTimeStart: new Date(startDate),
          drenchTimeEnd: new Date(endDate),
          phoneNumberText: phone || "",
          title: title || "",
          categoriesID: values?.categories || [],
          drenchTitle: drenchTitle || "",
          drenchSubTitle: drenchSubTitle || "",
          // drenchUrl: `https://${replaceString}` || "",
          drenchCode: drenchCode || "",
          notificationMessage: notificationMessage || "",
          openingHours: openingHoursObj,
          discountPercentage,
          key: singleDrenchData.key,
          id: singleDrenchData.key,
          type: `${type === "GoldFinch" ? "drenches" : "drizzles"}`,
          // createdDate: +new Date(),
          // geoFenceDistance: range,
          // range: range
          //   ? parseInt(range * 1000 + "")
          //   : singleDrenchData.range,
          // geoFenceId: singleDrenchData.geoFenceId,
          // geoFenceCampaignId: singleDrenchData.geoFenceCampaignId,
          promotionalMessage: "",
          images: uploadedImages.image || singleDrenchData?.images,
          franchises: franchisesData,
        });
      }
      // setEditLoading(false);
      // toggle("");
    }
  };

  const editGoldfinch = async (data) => {
    try {
      setEditLoading(true);
      if (data?.images && typeof data?.images !== "string") {
        const imageRef = storage.ref(`/goldfinch/${data.drenchKey}/thumbnail.${extension(data?.images?.type)}`);
        await imageRef.put(data?.images);
        data.imageContentType = data?.images?.type;
        data.images = await imageRef.getDownloadURL();
      }
      data.notificationId = singleDrenchData?.notificationId;
      const editCampaign = await fetch(function_url + "/editGoldFinch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const editCampaignResult = await editCampaign.json();
      if (editCampaignResult?.success) {
        NotificationManager.success(`GoldFinch Updated Successfully`);
        setEditLoading(false);
      } else {
        setEditLoading(false);
      }
    } catch (e) {
      setEditLoading(false);
      console.error(e, "edit drench");
    }
  };

  const editFinch = async (data) => {
    try {
      setEditLoading(true);
      if (data?.images && typeof data?.images !== "string") {
        const imageRef = storage.ref(`/goldfinch/${data.drenchKey}/thumbnail.${extension(data?.images.type)}`);
        await imageRef.put(data?.images);
        data.imageContentType = data?.images.type;
        data.images = await imageRef.getDownloadURL();
      }
      console.log(data, "DATA_UWU");
      await db
        .collection("drenches")
        .doc(data.key)
        .update({
          ...data,
        });
      NotificationManager.success(`Finch Updated Successfully`);
      setEditLoading(false);
    } catch (e) {
      setEditLoading(false);
      console.log(e, "add drench");
    }
  };
  const onSubmitClick = () => {
    setErrors({
      ...errors,
      startDate: !startDate ? "Please select start date" : "",
      endDate: !endDate ? "Please select end date" : "",
      location: !latitude || !longitude ? "Please select location" : "",
    });

    if (
      !(
        title &&
        description !== "" &&
        latitude !== null &&
        longitude !== null &&
        startDate &&
        endDate &&
        discountPercentage
      )
    )
      NotificationManager.error("Please fill all field");

    if (startDate && endDate) validateDates();
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    const type = event.target.name;
    const MAX_SIZE = 2 * 1024 * 1024;
    const errorLabel = document.querySelector(`.upload-${type}-error-label`);
    const uploadContainer = document.querySelector(`.goldfinch-${type}-upload-container`);
    uploadContainer.classList.remove("uploaded");

    if (!image) return;

    errorLabel.textContent = "";
    if (image?.size > MAX_SIZE) return (errorLabel.textContent = "Image size should be lower than 2MB.");

    setUploadedImages({ ...uploadedImages, [type]: image });
    const url = window && window.URL.createObjectURL(image);
    uploadContainer.style.backgroundImage = `url(${url})`;
    uploadContainer.classList.add("uploaded");
  };

  const getAddress = (addressProps, latProps, lngProps) => {
    setAddress(addressProps);
    if (latProps && lngProps) {
      setLatitude("");
      setLongitude("");
      setLatitude(latProps);
      setLongitude(lngProps);
    }
  };

  useEffect(() => {
    dispatch(getCategories());
    if (localUserData?.uid) {
      getActiveFranchisePackages(localUserData?.uid).then((data) => {
        setRemainingSubscription(data);
      });
    }
  }, []);

  const getStartEndDateDiff = () => {
    if (!(endDate && startDate)) return "";
    let momentEndDate = moment(endDate);
    let momentStartDate = moment(startDate);
    const diff = moment.duration(momentEndDate.diff(momentStartDate, "seconds"), "seconds");
    return diff.format("y [years], M [months], w [weeks], d [days], h [hours], m [minutes]", {
      trim: "all",
    });
  };

  useEffect(() => {
    if (!loading) {
      if (singleDrenchData?.images) {
        const uploadContainer = document.querySelector(`.goldfinch-image-upload-container`);
        uploadContainer.style.backgroundImage = `url(${singleDrenchData?.images})`;
        uploadContainer?.classList?.add("uploaded");
      }
    }

    // if (singleDrenchData?.logoImages) {
    //   const uploadContainer = document.querySelector(`.goldfinch-logo-upload-container`);
    //   uploadContainer.style.backgroundImage = `url(${singleDrenchData?.logoImages})`;
    //   uploadContainer.classList.add("uploaded");
    // }
    // if (singleDrenchData?.successImages) {
    //   const uploadContainer = document.querySelector(`.goldfinch-success_image-upload-container`);
    //   uploadContainer.style.backgroundImage = `url(${singleDrenchData?.successImages})`;
    //   uploadContainer.classList.add("uploaded");
    // }
  }, [loading]);

  const validationSchema = yup.object({
    "title-name": yup.string().label("Title").max(20).required(),
    "description": yup.string().label("Description").max(120).required(),
    "discountPercentage": yup.number().max(100).min(1).label("Discount Percentage").required(),
    "categories": yup.array().label("Categories").min(1).required(),
    "franchises": yup.array().label("Categories").min(1).required(),
    "notificationMessage": type === "GoldFinch" ? yup.string().label("Notification Message").max(30).required() : null,
  });
  console.log(startTime, endTime, "END_TIME");
  return (
    <>
      <Header title={"Edit " + type} />

      {loading ? (
        <Grid container style={{ height: "90vh" }} justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      ) : (
        <Form
          onSubmit={onEdit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          style={{ padding: 25 }}
        >
          <div
            style={{
              marginTop: -16,
              paddingBottom: "3rem",
              marginBottom: 24,
              position: "relative",
            }}
          >
            <h2 style={{ fontWeight: 600 }}>Basic Info</h2>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12}>
                <Label>{type + " Title"}</Label>
                <FormGroup className="has-wrapper">
                  <Input
                    type="text"
                    value={title}
                    name="title-name"
                    id="title-name"
                    className="has-input input-lg"
                    placeholder={`Enter ${type} Title`}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <FormFeedback name="title-name" />
                </FormGroup>
              </Grid>
              <Grid item md={4} sm={12}>
                <Label>Number of Quantities</Label>
                <FormGroup className="has-wrapper">
                  <Input
                    value={quantity}
                    type="number"
                    name="quantity"
                    id="quantity"
                    className="has-input input-lg"
                    placeholder="Unlimited"
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <FormFeedback name="quantity" />
                </FormGroup>
              </Grid>
              <Grid item md={4} sm={12}>
                <Label>Discount Percentage</Label>
                <FormGroup className="has-wrapper">
                  <Input
                    value={discountPercentage}
                    type="number"
                    name="discountPercentage"
                    id="description"
                    className="has-input input-lg"
                    placeholder={`eg: 10`}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                  />
                  <FormFeedback name="discountPercentage" />
                </FormGroup>
              </Grid>
              <Grid item md={4} sm={12}>
                <InputGroup className="has-wrapper mui-datetime-pickers">
                  <Label>{type + " "} Launch Date & Time</Label>
                  <KeyboardDateTimePicker
                    inputVariant="outlined"
                    ampm={false}
                    placeholder="Select Date & Time"
                    value={startDate}
                    onChange={(value) => (moment(value).isValid() ? onStartDate(value) : onStartDate(null))}
                    onError={console.log}
                    format="yyyy/MM/DD HH:mm"
                    fullWidth
                    name={"startDate"}
                  />
                  {errors.startDate && (
                    <small className={"mt-2"} style={{ color: "red" }}>
                      {errors.startDate}
                    </small>
                  )}
                </InputGroup>
              </Grid>
              <Grid item md={4} sm={12}>
                <InputGroup className="has-wrapper mui-datetime-pickers">
                  <Label>{type + " "} End Date & Time</Label>
                  <KeyboardDateTimePicker
                    inputVariant="outlined"
                    ampm={false}
                    placeholder="Select Date & Time"
                    value={endDate}
                    onChange={(value) => (moment(value).isValid() ? onEndDate(value) : onEndDate(null))}
                    onError={console.log}
                    disablePast
                    format="yyyy/MM/DD HH:mm"
                    fullWidth
                    name={"endDate"}
                  />
                  {/*<Input*/}
                  {/*  style={{ width: "100%" }}*/}
                  {/*  variant="outlined"*/}
                  {/*  type="datetime-local"*/}
                  {/*  onChange={({ target: { value } }) => moment(value).isValid() ? onEndDate(value) : onEndDate(null)}*/}
                  {/*  value={endDate}*/}
                  {/*  min={startDate ? moment(startDate).format("YYYY-MM-DDTHH:mm") : moment().format("YYYY-MM-DDTHH:mm")}*/}
                  {/*  name={"endDate"}*/}
                  {/*/>*/}
                  {errors.endDate && (
                    <small className={"mt-2"} style={{ color: "red" }}>
                      {errors.endDate}
                    </small>
                  )}
                </InputGroup>
              </Grid>
              <Grid item md={4} sm={12}>
                <InputGroup className="has-wrapper">
                  <Label>{type + " "} Duration</Label>
                  <FormInput
                    style={{ width: "100%" }}
                    variant="outlined"
                    type="text"
                    disabled={true}
                    value={getStartEndDateDiff()}
                    name={"duration-readonly"}
                  />
                </InputGroup>
              </Grid>
              {type === "GoldFinch" && (
                <>
                  <Grid item md={4} sm={12} xs={12}>
                    <Label className="d-flex justify-content-start align-items-center">
                      Opening Time
                      <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </Label>
                    <TimeInput
                      // type={"time"}
                      // value={startTime}
                      // showSecond={false}
                      // defaultValue={moment()}
                      // className="xxx"
                      // onChange={({ target: { value } }) => setStartTime(moment(value, "HH:mm"))}
                      // use12Hours={false}
                      // fullWidth
                      type="time"
                      name="timePicker"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <Label className="d-flex justify-content-start align-items-center">
                      Closing Time
                      <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </Label>
                    <TimeInput
                      type="time"
                      name="timePicker"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Label className="d-flex justify-content-start align-items-center">
                      Days to Skip
                      <Tooltip className="ml-10" title="Please enter the date when your campaign will come to an end.">
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </Label>
                    <DayPicker selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
                  </Grid>
                </>
              )}
              {type === "GoldFinch" && (
                <Grid item md={12} sm={12}>
                  <Label>Notification Message</Label>
                  <FormGroup className="has-wrapper">
                    <Input
                      value={notificationMessage}
                      type="text"
                      name="notificationMessage"
                      id="notificationMessage"
                      className="has-input input-lg"
                      placeholder="Enter Notification Message"
                      onChange={(e) => setNotificationMessage(e.target.value)}
                    />
                    <FormFeedback name="notificationMessage" />
                  </FormGroup>
                </Grid>
              )}
              <Grid item md={12} sm={12}>
                <Label>Description</Label>
                <FormGroup className="has-wrapper">
                  <Input
                    style={{ height: 100 }}
                    value={description}
                    type="textarea"
                    name="description"
                    id="description"
                    className="has-input input-lg"
                    placeholder="Short Description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <FormFeedback name="description" />
                </FormGroup>
              </Grid>
            </Grid>
            <div className={"theme-line-breaker"} style={{ position: "absolute", left: 0, bottom: 0 }} />
          </div>
          <div
            style={{
              position: "relative",
              paddingBottom: "2rem",
              marginBottom: 16,
            }}
          >
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              <Grid item md={4} sm={12}>
                <Label>Upload Pictures </Label>
                <div
                  className={"main-goldfinch-upload-container d-flex justify-content-stretch flex-wrap"}
                  // style={{ gap: 14, margin: "24px 0" }}
                >
                  <div>
                    <div className={"goldfinch-upload-container goldfinch-image-upload-container"}>
                      <InputGroup className="has-wrapper">
                        <Label for={"image-label"}>
                          <h2>
                            Upload thumbnail image <i className="icon-cloud-upload" />
                          </h2>
                        </Label>
                        <input
                          style={{ display: "none" }}
                          type={"file"}
                          accept={"image/png, image/jpeg, image/jpg, image/gif, image/svg"}
                          onChange={handleImageChange}
                          id={"image-label"}
                          name={"image"}
                        />
                      </InputGroup>
                    </div>
                    <small style={{ color: "red" }} className={"upload-image-error-label"} />
                  </div>
                </div>
              </Grid>
              <Grid item md={8}>
                <Grid container spacing={3}>
                  <Grid item md={12} sm={12}>
                    <FormGroup className="has-wrapper">
                      <Label>Select Categories</Label>
                      <Select
                        className={"availity-form-select"}
                        name="categories"
                        isMulti={true}
                        options={categories?.map((data) => ({
                          value: data?.id,
                          label: data?.title,
                        }))}
                      />
                      <FormFeedback name="categories" style={{ display: "block" }} />
                    </FormGroup>
                  </Grid>
                  <Grid item md={12} sm={12}>
                    <InputGroup>
                      <Label>Select Franchises</Label>
                      <Select
                        maxLength={remainingSubscription}
                        className={"availity-form-select"}
                        name="franchises"
                        placeholder="Select Franchises"
                        isMulti={true}
                        onChange={(val) => setSelectedFranchises([...val])}
                        options={franchises?.map((data) => ({
                          value: data?.franchiseId,
                          label: data?.name,
                        }))}
                      />
                      {remainingSubscription <= selectedFranchises.length &&
                      franchises.length > remainingSubscription ? (
                        <p style={{ color: "#3F83F8", display: "block", marginTop: 5 }}>
                          Your active franchise quantity is {remainingSubscription}, you can only select{" "}
                          {remainingSubscription} franchise.
                        </p>
                      ) : null}
                      <FormFeedback name="franchises" style={{ display: "block" }} />
                    </InputGroup>
                  </Grid>
                  {/* <Grid item md={12} sm={12}>
                    <InputGroup>
                      <Label>Full Address</Label>
                      <Input
                        name={"readonly-address"}
                        disabled
                        value={address}
                      />
                    </InputGroup>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </div>

          {/* <Grid container spacing={2}>
            <Grid item md={12} sm={12}>
              <div
                style={{
                  position: "relative",
                  // top: 0,
                  // left: 0,
                  height: 330,
                  width: "100%",
                }}
              >
                {latitude && longitude && (
                  <GoogleMap
                    latitude={latitude}
                    longitude={longitude}
                    getAddress={getAddress}
                    range={range && parseInt(range * 1000 + "")}
                    options={{
                      gestureHandling: "none",
                      disableDefaultUI: true,
                    }}
                  />
                )}
              </div>
            </Grid> */}

          {/* </Grid> */}

          {/* <OpeningHours
            previousSavedData={singleDrenchData}
            openingHoursObj={openingHoursObj}
            setOpeningHoursObj={setOpeningHoursObj}
          /> */}
          <div
            className={"theme-line-breaker mt-20"}
            // style={{ position: "absolute", left: 0, bottom: 0 }}
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <FormGroup style={{ marginTop: "2rem" }} className="mb-12">
                <Button
                  onClick={onSubmitClick}
                  className="btn-dark text-white btn-block w-70"
                  variant="contained"
                  size="large"
                  type={"submit"}
                  disabled={editLoading}
                >
                  {editLoading && <CircularProgress size={24} style={{ color: "#fff", marginRight: 10 }} />}
                  Update
                </Button>
              </FormGroup>
            </Grid>
          </Grid>
        </Form>
      )}
    </>
  );
};
export default EditDrench;
