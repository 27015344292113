import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Form, FormGroup, Input, Label } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Header from "./../../Components/NewHeader";
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { db } from "../../Firebase";

export default function UpdatePrivacyPolicy(props) {
  const [editorState, setEditorState] = useState();

  const onSend = async (event) => {
    try {
      event.preventDefault();
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      await db.collection("application-documents").doc("privacy-policy").update({
        content: html,
      });
      NotificationManager.success("Privacy Policy Updated Successfully");
    } catch (error) {
      NotificationManager.error(error.message);
    }
  };

  const getPrivacyPolicy = async () => {
    db.collection("application-documents")
      .doc("privacy-policy")
      .get()
      .then(async (querySnapshot) => {
        const data = querySnapshot.data();
        if (!data) {
          await db.collection("application-documents").doc("privacy-policy").set({
            content: "",
          });
          return getPrivacyPolicy();
        }

        setEditorState(
          EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data?.content)))
        );
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <div>
      <Header title="Update Privacy Policy" />
      <Form onSubmit={onSend} style={{ marginTop: "", paddingLeft: 20, paddingRight: 20 }}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Label required style={{ fontSize: 17 }}>
              Privacy Policy
            </Label>
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: 8,
              }}
            >
              <Editor
                spellCheck={true}
                editorState={editorState}
                editorClassName="htmlEditorContentArea"
                onEditorStateChange={setEditorState}
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FormGroup className="mb-15">
              <Button
                className="btn-dark text-white btn-block w-50"
                style={{ margin: "0 auto", display: "block" }}
                variant="contained"
                size="large"
                type="submit"
              >
                Send
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
