import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FranchiseCard from "./franchise-card";
import "./index.scss";
import { auth, db } from "../../Firebase";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { v4 } from "uuid";
import { getAllPackagesAction } from "../../Store/Actions/PackagesActions";
import SelectPaymentMethod from "./select-payment-method";
import CardInput from "./card-input";
import { addPaymentMethodsAction } from "Store/Actions/UserActions";

function Packages(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const paymentMethods = useSelector((state) => state.UserReducer.paymentMethods);
  const [open, setOpen] = useState(false);
  const packages = useSelector((state) => state.PackagesReducer.packages);
  const function_url = useSelector((state) => state?.Cf?.function_url || "");

  const [buyPackages, setBuyPackages] = useState({
    packageType: "",
    packageId: "",
    paymentMethodId: "",
  });
  const [subscriptions, setSubscriptions] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  async function getPackages() {
    setLoader(true);
    const packagesSnapchat = await db.collection("packages").get();
    let campaigns = [];
    let franchise = [];
    packagesSnapchat.forEach((doc) => {
      const data = doc.data();
      if (data.packageType === "CAMPAIGN") {
        campaigns.push(data);
      } else {
        if(data.name !== "BASIC") {
          const basicPackage = packagesSnapchat.docs.find((d) => {
            const data = d.data();
            return data.name === "BASIC" && data.packageType === "FRANCHISE";
          }).data();
          const packageLength = {
            'QUARTERLY': 6,
            "YEARLY": 12,
            "MONTHLY": 1,
          }
          const packageType = packageLength[data.type];
          const priceDiff = (basicPackage.price * packageType) - data.price;
          const finalPercentage = priceDiff / (basicPackage.price * packageType);
          
          if(finalPercentage > 0) {
            data.discount = Math.round(finalPercentage * 100);
          }
        }
        franchise.push(data);
      }
    });

    franchise.sort((a, b) => a.price - b.price);
    dispatch(getAllPackagesAction({ campaigns, franchise }));
    setLoader(false);
  }

  const getSinglePackageValue = (key, name, value) => {
    return packages[key]?.find((p) => p.name === name)?.[value] || "";
  };

  const PACKAGES_TYPE_ENUM = {
    CAMPAIGN: "campaigns",
    FRANCHISE: "franchise",
  };

  const CAMPAIGN_PACKAGES_NAME_ENUM = {
    BASIC: "BASIC",
    STANDARD: "STANDARD",
    PREMIUM: "PREMIUM",
  };

  const validateIfUserDoNotHaveActivePackage = () => {
    if (!subscriptions?.length) return true;
    for (const subscription of subscriptions) {
      if (
        !subscription?.isAssigned &&
        subscription?.packageType === "CAMPAIGN" &&
        moment().isBefore(moment(subscription?.current_period_end * 1000))
      ) {
        return false;
      }
    }
    return true;
  };

  const getCustomerSubscription = async () => {
    if (!localUserData?.uid) return null;
    const snapshot = await db.collection("subscriptions").where("uid", "==", localUserData.uid).get();
    let docs = [];
    snapshot.forEach((doc) => {
      docs.push(doc.data());
    });
    docs = await Promise.all(docs);
    setSubscriptions(docs);
  };

  const ImageComponentForPackageTable = ({
    image = "/assets/images/img/check.svg",
    width = "22",
    alt = "Included",
  }) => (
    <div>
      <img width={width} src={image} alt={alt} />
    </div>
  );

  const buyPackage = async (event, paymentMethodId = buyPackages.paymentMethodId) => {
    if (!buyPackages.packageType || !buyPackages.packageId) return;

    const packageToBuy = packages[buyPackages.packageType].find((p) => p.stripePriceId === buyPackages.packageId);
    if (!packageToBuy) return;

    if (!paymentMethodId) {
      document.getElementById("card-form-submit").click();
      return;
    } else {
      setLoader(true);
      handleClose();
    }

    try {
      let fetchURL = function_url + "/createSubscription";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/createSubscription";
      const authToken = await auth.currentUser.getIdToken();

      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": authToken },
        body: JSON.stringify({
          customer: localUserData.stripeId,
          itemId: packageToBuy?.stripePriceId,
          paymentMethodId: paymentMethodId,
          customerUid: localUserData.uid,
          packageType: packageToBuy?.packageType,
        }),
      });
      let result = await response.json();
      if (response.ok) {
        await getCustomerSubscription();
        let successMessage = "Package Subscribed Successfully. ";
        if (result?.isFree) {
          successMessage += "30 days free trial started, you'll be charged after free trail.";
        }
        NotificationManager.success(successMessage);
      }
      if (result?.message) {
        NotificationManager.error(Array.isArray(result.message) ? result.message[0] : result.message);
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
    }
    setLoader(false);
    setBuyPackages({ packageType: "", packageId: "", paymentMethodId: "" });
  };

  const createNewCardAndPay = async (token) => {
    if (!token) return;
    if (!localUserData?.stripeId) return;
    setLoader(true);
    handleClose();

    let fetchURL = function_url + "/createCard";
    // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/createCard";
    const authToken = await auth.currentUser.getIdToken();

    try {
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": authToken },
        body: JSON.stringify({
          token: token.id,
          customer: localUserData?.stripeId,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        dispatch(addPaymentMethodsAction(result?.paymentMethods));
        console.log(buyPackages, "buyPackages");
        buyPackage(null, result?.paymentMethods.id);
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!packages.franchise?.length || !packages.campaigns?.length) {
      getPackages();
    }
    getCustomerSubscription();
  }, [localUserData?.uid]);

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 20 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ marginTop: "3rem" }}>
        <div style={{ marginBottom: "4rem" }}>
          <h6
            style={{
              color: "#242424",
              fontSize: "1.8rem",
              fontWeight: 400,
            }}
          >
            Franchise Packages
          </h6>
          <div className={"theme-line-breaker mb-4"} />
        </div>
        <div
          style={{
            margin: "1rem 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "4rem",
            flexWrap: "wrap",
            marginBottom: 20,
          }}
        >
          {packages.franchise.map((p) => (
            <FranchiseCard paymentMethods={paymentMethods} key={v4()} data={p} />
          ))}
        </div>
      </div>

      <div style={{ marginTop: "5rem", paddingBottom: "3rem" }}>
        <div style={{ marginBottom: "4rem" }}>
          <h6
            style={{
              color: "#242424",
              fontSize: "1.8rem",
              fontWeight: 400,
            }}
          >
            Campaigns Packages
          </h6>
          <div className={"theme-line-breaker mb-4"} />
        </div>
        <div className="container campaigns_packages-container">
          <div className="header">
            <div className="bold">Packages</div>
            {/* <div>
              <span>Basic</span>
              <span className="price">
                ${getSinglePackageValue(PACKAGES_TYPE_ENUM.CAMPAIGN, CAMPAIGN_PACKAGES_NAME_ENUM.BASIC, "price")}
              </span>
              <span>Price per month</span>
            </div> */}
            <div>
              <span>Standard</span>
              <span className="price">
                ${getSinglePackageValue(PACKAGES_TYPE_ENUM.CAMPAIGN, CAMPAIGN_PACKAGES_NAME_ENUM.STANDARD, "price")}
              </span>
              <span>Price per month</span>
            </div>
            <div>
              <span>Premium</span>
              <span className="price">
                ${getSinglePackageValue(PACKAGES_TYPE_ENUM.CAMPAIGN, CAMPAIGN_PACKAGES_NAME_ENUM.PREMIUM, "price")}
              </span>
              <span>Price per month</span>
            </div>
          </div>

          <div className="description-container gray">
            <div className="heading">Number Of Finches</div>
            {/* <div>
              {getSinglePackageValue(PACKAGES_TYPE_ENUM.CAMPAIGN, CAMPAIGN_PACKAGES_NAME_ENUM.BASIC, "numberOfFinches")}
            </div> */}
            <div>
              {getSinglePackageValue(
                PACKAGES_TYPE_ENUM.CAMPAIGN,
                CAMPAIGN_PACKAGES_NAME_ENUM.STANDARD,
                "numberOfFinches"
              )}
            </div>
            <div>
              {getSinglePackageValue(
                PACKAGES_TYPE_ENUM.CAMPAIGN,
                CAMPAIGN_PACKAGES_NAME_ENUM.PREMIUM,
                "numberOfFinches"
              )}
            </div>
          </div>
          <div className="description-container">
            <div className="heading">Number of GoldFinches</div>
            {/* <div>
              {getSinglePackageValue(
                PACKAGES_TYPE_ENUM.CAMPAIGN,
                CAMPAIGN_PACKAGES_NAME_ENUM.BASIC,
                "numberOfGoldFinches"
              )}
            </div> */}
            <div>
              {getSinglePackageValue(
                PACKAGES_TYPE_ENUM.CAMPAIGN,
                CAMPAIGN_PACKAGES_NAME_ENUM.STANDARD,
                "numberOfGoldFinches"
              )}
            </div>
            <div>
              {getSinglePackageValue(
                PACKAGES_TYPE_ENUM.CAMPAIGN,
                CAMPAIGN_PACKAGES_NAME_ENUM.PREMIUM,
                "numberOfGoldFinches"
              )}
            </div>
          </div>
          <div className="description-container gray">
            <div className="heading">Unlimited Notifications</div>
            {/* <ImageComponentForPackageTable /> */}
            <ImageComponentForPackageTable />
            <ImageComponentForPackageTable />
          </div>
          <div className="description-container">
            <div className="heading">Analytics</div>
            {/* <ImageComponentForPackageTable /> */}
            <ImageComponentForPackageTable />
            <ImageComponentForPackageTable />
          </div>
          <div className="description-container gray">
            <div className="heading">Number Of Finches</div>
            {/* <ImageComponentForPackageTable /> */}
            <ImageComponentForPackageTable />
            <ImageComponentForPackageTable />
          </div>
          <div className="footer">
            <div></div>
            {/* <div>
              <Button
                onClick={() => {
                  const packageId = getSinglePackageValue(
                    PACKAGES_TYPE_ENUM.CAMPAIGN,
                    CAMPAIGN_PACKAGES_NAME_ENUM.BASIC,
                    "stripePriceId"
                  );
                  setBuyPackages({
                    ...buyPackages,
                    packageId,
                    packageType: PACKAGES_TYPE_ENUM.CAMPAIGN,
                  });
                  handleOpen();
                }}
              >
                Buy Now
              </Button>
            </div> */}
            <div>
              <Button
                onClick={() => {
                  const packageId = getSinglePackageValue(
                    PACKAGES_TYPE_ENUM.CAMPAIGN,
                    CAMPAIGN_PACKAGES_NAME_ENUM.STANDARD,
                    "stripePriceId"
                  );
                  setBuyPackages({
                    ...buyPackages,
                    packageId,
                    packageType: PACKAGES_TYPE_ENUM.CAMPAIGN,
                  });
                  handleOpen();
                }}
              >
                Buy Now
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  const packageId = getSinglePackageValue(
                    PACKAGES_TYPE_ENUM.CAMPAIGN,
                    CAMPAIGN_PACKAGES_NAME_ENUM.PREMIUM,
                    "stripePriceId"
                  );
                  setBuyPackages({
                    ...buyPackages,
                    packageId,
                    packageType: PACKAGES_TYPE_ENUM.CAMPAIGN,
                  });
                  handleOpen();
                }}
              >
                Buy Now
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle>
          {!validateIfUserDoNotHaveActivePackage() ? "Package Description" : "Select Card For Payment"}
        </DialogTitle>
        <DialogContent>
          {validateIfUserDoNotHaveActivePackage() ? (
            <>
              <CardInput submitFunction={createNewCardAndPay} />
              <SelectPaymentMethod value={buyPackages} setValue={setBuyPackages} valueName="paymentMethodId" />
            </>
          ) : (
            <p>You already have an active campaign package.</p>
          )}
        </DialogContent>
        <DialogActions className="mt-10">
          <Button onClick={handleClose} color="primary">
            {validateIfUserDoNotHaveActivePackage() ? "Close" : "Cancel"}
          </Button>
          {validateIfUserDoNotHaveActivePackage() && (
            <Button type="submit" variant="contained" color="primary" onClick={buyPackage}>
              Buy Package
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default Packages;
