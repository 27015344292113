import * as React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { auth, database, db } from "../../Firebase/index";
import Header from "./../../Components/NewHeader";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { startCase } from "lodash";

const UsersList = () => {
  const [users, setUsers] = useState(null);
  const [statusLoading, setStatusLoading] = useState(null);
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "fullName",
      label: "Full Name",
      options: {
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "phone",
      label: "Contact",
      options: {
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "dateOfBirth",
      label: "Date Of Birth",
      options: {
        customBodyRender: (value) =>
          moment({ ...value }.seconds * 1000).format("lll"),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => {
          const data = users.find((v) => v.id === value);
          const index = users.findIndex((v) => v.id === value);
          const isLoading = statusLoading === index;
          return data.status === "blocked" ? (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              style={{ backgroundColor: "#28B873" }}
              onClick={() => handleUserUnblock(value)}
            >
              {isLoading ? <CircularProgress size={16} /> : "Unblock"}
            </Button>
          ) : (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              style={{ backgroundColor: "#DC2845" }}
              onClick={() => handleUserBlock(value)}
            >
              {isLoading ? <CircularProgress size={16} /> : "Block"}
            </Button>
          );
        },
      },
    },
    {
      name: "loginType",
      label: "Sign Up",
      options: {
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) =>
          value ? (value === "blocked" ? startCase(value) : "Unblock") : "-",
      },
    },
    // {
    //   name: "",
    //   label: "Claimed Goldfinches",
    // },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const snap = await db.collection("users").get();
      const data = snap.docs.map((doc) => doc.data());
      console.log(data);
      setUsers(data.filter((doc) => doc.id));
    } catch (error) {
      console.log(error, "USER_LIST_ERROR");
    }
  };

  const handleUserBlock = async (userId) => {
    try {
      const user = auth.currentUser;

      const index = users.findIndex((user) => user.id === userId);
      setStatusLoading(index);
      const token = await user.getIdToken();

      const response = await fetch(function_url + "/blockUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({ userId }),
      });
      let result = await response.json();

      if (result.success) {
        NotificationManager.success("User Blocked successfully.");
        const newUsers = [...users];
        newUsers[index].status = "blocked";
        setUsers(newUsers);
      } else {
        NotificationManager.error(
          "Something went wrong, please try again later"
        );
      }
      setStatusLoading(null);
      console.log(result);
    } catch (error) {
      setStatusLoading(null);
      console.log(error, "USER_BLOCK_ERROR");
      NotificationManager.error("Something went wrong, please try again later");
    }
  };

  const handleUserUnblock = async (userId) => {
    try {
      const user = auth.currentUser;
      const token = await user.getIdToken();
      const index = users.findIndex((user) => user.id === userId);
      setStatusLoading(index);
      const response = await fetch(function_url + "/unBlockUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({ userId }),
      });
      let result = await response.json();

      if (result.success) {
        NotificationManager.success("User Unblocked successfully.");
        const newUsers = [...users];
        newUsers[index].status = "active";
        setUsers(newUsers);
      } else {
        NotificationManager.error(
          "Something went wrong, please try again later"
        );
      }
      setStatusLoading(null);
    } catch (error) {
      setStatusLoading(null);
      NotificationManager.error("Something went wrong, please try again later");
      console.log(error, "USER_UNBLOCK_ERROR");
    }
  };
  return (
    <>
      <Header title="App Users" onRefresh={getData} />
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <MUIDataTable
          // title={
          //   <h1 style={{ color: "#242424" }} className={"font-weight-bold"}>
          //     Users
          //   </h1>
          // }
          data={users ? users : []}
          columns={columns}
          options={{
            download: false,
            print: false,
            filter: false,
            search: true,
            viewColumns: false,
            selectableRows: false,
          }}
        />
      </div>
    </>
  );
};

export default UsersList;
