import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInMinutes } from "date-fns";
import {
  getDrenchAction,
  deleteDrenchAction,
  activeDrenchActive,
} from "Store/Actions/createForm";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import BinIcon from "../../Assets/img/bin.svg";
import PenIcon from "../../Assets/img/pen.svg";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import RctCollapsibleCard from "../../Components/RctCollapsibleCard/RctCollapsibleCard";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { getUserData } from "../../Store/Actions";
import { db, auth } from "../../Firebase";
import Header from "./../../Components/NewHeader";
import { useHistory } from "react-router-dom";
import { ADD_FRANCHISES } from "Store/Actions/types";
import Chip from "@mui/material/Chip";
import { getActivePackagesItemsDetails } from "../../Util/subscriptions";
import "./style.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function MerchantDashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const [drenchList, setDrenchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const deleteDrench = (key, type) => {
    dispatch(
      deleteDrenchAction(
        key,
        type === undefined || type === "drenches" ? "drenches" : "drizzles"
      )
    );
  };
  const dispatch = useDispatch();
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const drenches = useSelector((state) => state.Drench.drenches);
  const franchises = useSelector((state) => state.Franchise.franchises);
  const [open, setOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({ id: null, type: null });
  const [subscriptionsDetails, setSubscriptionsDetails] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const getFranchise = async (data) => {
    try {
      if (!localUserData?.uid) return;
      const snap = await db
        .collection("franchises")
        .where("restaurantId", "==", localUserData?.uid)
        .get();
      const data = snap.docs.map((doc) => doc.data());
      dispatch({
        type: ADD_FRANCHISES,
        payload: data,
      });
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  const getDrenches = async () => {
    dispatch(getDrenchAction());
  };

  const drenchActiveInactive = (data) => {
    dispatch(activeDrenchActive(data));
  };

  const columns = [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "franchises",
      label: "Franchise",
      options: {
        customBodyRender: (value) => {
          return value
            ?.filter((v) =>
              franchises?.some((fran) => v?.franchiseId === fran?.franchiseId)
            )
            ?.map((v) => {
              const franchise = franchises?.find(
                (fran) => v?.franchiseId === fran?.franchiseId
              );
              return { name: franchise?.name, id: franchise.franchiseId };
            })
            ?.map((data) => (
              <Chip
                onClick={() =>
                  history.push("/dashboard/franchises/" + data?.id)
                }
                label={data?.name}
                size="small"
                variant="outlined"
                sx={{ m: 0.2, cursor: "pointer" }}
              />
            ));
        },
      },
    },
    {
      name: "drenchTimeStart",
      label: "Start Date",
      options: {
        customBodyRender: (value) =>
          moment(new Date(value.seconds * 1000)).format("MMM-DD-YYYY"),
      },
    },
    {
      name: "drenchTimeEnd",
      label: "End Date",
      options: {
        customBodyRender: (value) =>
          moment(new Date(value.seconds * 1000)).format("MMM-DD-YYYY"),
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              padding: "4px 14px",
              backgroundColor: value === "finch" ? "#11A4C2" : "#E5B11E",
              borderRadius: 6,
              color: "#fff",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </div>
        ),
      },
    },
    {
      name: "currentStatus",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const goldfinch = drenches[tableMeta?.rowIndex];
          let backgroundColor,
            fontSize = 14;
          if (!goldfinch?.active) {
            backgroundColor = "gray";
          } else if (value === "Claimed All") {
            backgroundColor = "#1B5E20";
          } else if (value === "Awaiting") {
            backgroundColor = "#11A4C2";
          } else if (value === "Dying Up") {
            backgroundColor = "#4950BA";
            fontSize = 14;
          } else if (value === "Active") {
            backgroundColor = "#1CBC3C";
          } else {
            backgroundColor = "#DC2845";
          }

          return (
            <div
              style={{
                padding: "4px 6px",
                backgroundColor,
                borderRadius: 6,
                color: "#fff",
                textAlign: "center",
                fontWeight: 500,
                fontSize,
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div
            className={"d-flex justify-content-start align-items-center"}
            style={{ gap: 16 }}
          >
            <img
              src={PenIcon}
              style={{ cursor: "pointer" }}
              alt={"edit"}
              onClick={() => {
                const data = drenches.find((drench) => drench.id === value);
                // editData(drenches.filter((drench) => drench.id === value)[0])
                history.push(
                  `/dashboard/update-campaign/${
                    data?.type === "goldfinch" ? "GoldFinch" : "Finch"
                  }/${value}`
                );
              }}
            />
            <img
              src={BinIcon}
              style={{ cursor: "pointer" }}
              alt={"delete"}
              onClick={() => {
                const data = drenches.filter(
                  (drench) => drench.id === value
                )[0];
                setDeleteData({
                  id: data.drenchKey || data.key,
                  type: data.type,
                });
                setOpen(true);
              }}
            />
          </div>
        ),
      },
    },
    {
      name: "id",
      label: "Active/Inactive",
      options: {
        filter: false,
        customBodyRender: (_, tableMetaData) => {
          const data = drenches[tableMetaData?.rowIndex];
          return (
            <div className={"d-flex justify-content-start align-items-center"}>
              <Switch
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={data.active}
                onChange={() => drenchActiveInactive(data)}
              />
            </div>
          );
        },
      },
    },
  ];

  const createFinchButton = (
    <Button
      onClick={() => history.push("/dashboard/create-campaign/Finch")}
      variant="contained"
      color="primary"
      disabled={subscriptionsDetails?.campaign?.finches <= 0}
      className={`ml-2 ${
        subscriptionsDetails?.campaign?.finches <= 0 ? "disabled" : ""
      }`}
      style={{ backgroundColor: "#11A4C2" }}
    >
      Create Finch
    </Button>
  );

  const createGoldfinchButton = (
    <Button
      onClick={() => history.push("/dashboard/create-campaign/GoldFinch")}
      disabled={subscriptionsDetails?.campaign?.goldfinches <= 0}
      variant="contained"
      color="primary"
      className={`bg-yellow-new ml-2 ${
        subscriptionsDetails?.campaign?.goldfinches <= 0 ? "disabled" : ""
      }`}
    >
      Create GoldFinch
    </Button>
  );

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      db.collection("restaurants")
        .doc(userId)
        .get()
        .then((snap) => {
          dispatch(getUserData(snap.data()));
        });
    }
  }, []);

  useEffect(() => {
    getFranchise();
  }, [localUserData]);

  useEffect(() => {
    if (drenchList.length) {
      setDrenchList(
        drenchList.map((item) => {
          if (item.createdDate) {
            if (differenceInMinutes(new Date(), item.createdDate) >= 1) {
              return { ...item, active: false, disabled: true };
            }
          }
          return item;
        })
      );
    }
    getDrenches();
    if (localUserData?.uid) {
      setLoading(true);
      getActivePackagesItemsDetails(localUserData.uid).then((subscriptions) => {
        setSubscriptionsDetails(subscriptions);
        setLoading(false);
      });
    }
  }, [localUserData]);

  return (
    <>
      <Header
        title={
          <>
            <div className="remaining-goldfinches__container">
              <span className="page-heading">Campaigns</span>
              <div>
                Remaining Finches: {subscriptionsDetails?.campaign?.finches}
              </div>
              <div>
                Remaining Goldfinches:{" "}
                {subscriptionsDetails?.campaign?.goldfinches}
              </div>
            </div>
          </>
        }
        onRefresh={getDrenches}
      />
      <div className="video-player-wrapper">
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {localUserData?.isProfileCompleted === false && (
          <div className={"my-10 px-20"}>
            <Alert style={{ backgroundColor: "#E5F6FD" }} severity="info">
              Please setup your profile to create goldfinch.{" "}
              <Link
                style={{ textDecoration: "underline", fontSize: 12 }}
                to={"/dashboard/profile-merchant"}
              >
                Setup Profile
              </Link>
            </Alert>
          </div>
        )}
        {!franchises?.length && (
          <div className={"my-10 px-20"}>
            <Alert style={{ backgroundColor: "#E5F6FD" }} severity="info">
              Please add at least one franchise to create campaign.{" "}
              <Link
                style={{ textDecoration: "underline", fontSize: 12 }}
                to={"/dashboard/franchises"}
              >
                Create Franchise
              </Link>
            </Alert>
          </div>
        )}
        <div className="row">
          <RctCollapsibleCard colClasses="col-sm-12 col-md-12 col-lg-12">
            <MUIDataTable
              data={drenches || []}
              columns={columns}
              options={{
                selectableRows: false,
                elevation: 0,
                rowsPerPageOptions: [10, 50, 100],
                download: false,
                print: false,
                customToolbar: () => {
                  return localUserData?.isProfileCompleted === false ||
                    !franchises?.length ? null : (
                    <React.Fragment>
                      {subscriptionsDetails?.campaign?.finches <= 0 ? (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                className="mb-5"
                                color="inherit"
                                variant="body2"
                              >
                                {/* <b>
                                  Buy a package to start promoting with
                                  GoldFinch
                                </b> */}
                                <b>Campaign package reached.</b>
                              </Typography>
                              Buy a package to start promoting with Finch
                            </React.Fragment>
                          }
                        >
                          <span>{createFinchButton}</span>
                        </HtmlTooltip>
                      ) : (
                        createFinchButton
                      )}
                      {subscriptionsDetails?.campaign?.goldfinches <= 0 ? (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography
                                className="mb-5"
                                color="inherit"
                                variant="body2"
                              >
                                <b>Campaign package reached.</b>
                              </Typography>
                              Buy a package to start promoting with GoldFinch
                            </React.Fragment>
                          }
                        >
                          <span>{createGoldfinchButton}</span>
                        </HtmlTooltip>
                      ) : (
                        createGoldfinchButton
                      )}
                    </React.Fragment>
                  );
                },
              }}
            />
          </RctCollapsibleCard>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="draggable-dialog-title">
            Delete {deleteData.type === "drizzles" ? "finch" : "Goldfinch"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this{" "}
              {deleteData.type === "drizzles" ? "finch" : "Goldfinch"}?<br />{" "}
              This action can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              No
            </Button>
            <Button
              onClick={async () => {
                await deleteDrench(deleteData?.id, deleteData.type);
                setDeleteData({ id: null, type: null });
                handleClose();
              }}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default MerchantDashboard;
