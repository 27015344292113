import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { FormGroup as RsFormGroup, InputGroup } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import GoogleMap from "../../../src/Components/GoogleMap/GoogleMap";
import Geocode from "react-geocode";
import LocationApi from "../../Container/locationapi";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Feedback as FormFeedback,
} from "@availity/form";
import * as yup from "yup";
import _ from "underscore";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.css";
import { extension } from "mime-types";
import { Grid } from "@material-ui/core";
import AppConfig from "../../Constants/AppConfig";
import { db } from "../../Firebase";
import Header from "./../../Components/NewHeader";

const UpdateProfile = ({ readonly = false, userData, ...props }) => {
  const [openingHoursObj, setOpeningHoursObj] = useState({});
  const [address, setAddress] = useState(userData?.address ?? null);
  const [fullAddress, setFullAddress] = useState(userData?.address ?? null);
  const [latitude, setLatitude] = useState(
    userData?.latitude ?? AppConfig.defaultLat
  );
  const [longitude, setLongitude] = useState(
    userData?.longitude ?? AppConfig.defaultLng
  );
  const [logoImage, setLogoImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState({
    fullName: userData?.fullName ?? "",
    companyName: userData?.companyName ?? "",
    email: userData?.email ?? "",
    description: userData?.description ?? "",
    phone: userData?.phone ?? "",
    website: userData?.website ?? "",
    facebookLink: userData?.facebookLink ?? "",
    instagramLink: userData?.instagramLink ?? "",
    twitterLink: userData?.twitterLink ?? "",
    youtubeLink: userData?.youtubeLink ?? "",
    businessContact: userData?.businessContact ?? "",
  });

  console.log(userData?.businessContact, "userData?.businessContact");

  const validationSchema = yup.object({
    fullName: yup.string().label("Full Name").required().min(3),
    companyName: yup.string().label("Company Name").required().min(3).max(60),
    email: yup.string().label("Email").required().email(),
    description: yup
      .string()
      .label("Short Description")
      .required()
      .min(3)
      .max(180),
    phone: yup.string().label("Phone").required(),
    website: yup.string().label("Website"),
    facebookLink: yup.string().label("Facebook Link"),
    instagramLink: yup.string().label("Instagram Link"),
    twitterLink: yup.string().label("Twitter Link"),
    youtubeLink: yup.string().label("Youtube Link"),
    businessContact: yup.string().label("Business Contact"),
  });

  const handleLogoChange = (e) => {
    const logoFile = e.target.files[0];
    const logoUrl = window && window.URL.createObjectURL(logoFile);
    setLogoImage(logoFile);
    document.querySelector(
      ".user-logo-container"
    ).style.backgroundImage = `url(${logoUrl})`;
  };

  const onUpdateUserProfile = async (values) => {
    setLoading(true);
    let formData = {
      fullName: values["fullName"] ?? userData?.fullName ?? "",
      email: values["email"] ?? userData?.email ?? "",
      companyName: values["companyName"] ?? "",
      description: values["description"] ?? "",
      address: fullAddress || userData?.address,
      phone: values["phone"] ?? "",
      longitude: longitude ?? "",
      latitude: latitude ?? "",
      website: values["website"]
        ? values["website"]?.includes("https://")
          ? values["website"]
          : `https://${values["website"]}`
        : "",
      facebookLink: values["facebookLink"]
        ? values["facebookLink"]?.includes("https://")
          ? values["facebookLink"]
          : `https://${values["facebookLink"]}`
        : "",
      twitterLink: values["twitterLink"]
        ? values["twitterLink"]?.includes("https://")
          ? values["twitterLink"]
          : `https://${values["twitterLink"]}`
        : "",
      instagramLink: values["instagramLink"]
        ? values["instagramLink"]?.includes("https://")
          ? values["instagramLink"]
          : `https://${values["instagramLink"]}`
        : "",
      youtubeLink: values["youtubeLink"]
        ? values["youtubeLink"]?.includes("https://")
          ? values["youtubeLink"]
          : `https://${values["youtubeLink"]}`
        : "",
      isProfileCompleted: true,
      openingHours: openingHoursObj,
    };

    if (
      _.isBlank(formData["address"]) ||
      _.isBlank(formData["longitude"]) ||
      _.isBlank(formData["latitude"])
    ) {
      NotificationManager.error("Please Select Location on Map");
      return;
    }

    if (
      !_.isBlank(formData["email"]) &&
      !_.isBlank(formData["fullName"]) &&
      !_.isBlank(formData["companyName"]) &&
      !_.isBlank(formData["description"]) &&
      !_.isBlank(formData["address"]) &&
      !_.isBlank(formData["phone"]) &&
      !_.isBlank(formData["longitude"]) &&
      !_.isBlank(formData["latitude"]) &&
      // !_.isBlank(formData["website"]) &&
      !(!userData?.logoUrl && !logoImage)
    ) {
      try {
        if (logoImage) {
          const storageRef = firebase
            .storage()
            .ref(
              `restaurants/${userData.uid}/logo.${extension(logoImage.type)}`
            );
          await storageRef.put(logoImage);
          const logoUrl = await storageRef.getDownloadURL();
          formData = { ...formData, logoUrl, imageContentType: logoImage.type };
        }

        await db.collection("restaurants").doc(userData.uid).update(formData);
        // await database
        //   .ref(`restaurants/${localUserData.uid}`)
        //   .update(formData);
        NotificationManager.success("Successfully updated");
        // window.location.reload();
        setLoading(false);
      } catch (error) {
        console.log(error, "error");
        setLoading(false);
        NotificationManager.error(error.message);
      }
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  useEffect(() => {
    const [lat, lng] = [userData?.latitude, userData?.longitude];
    if (!lat || !lng) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, [userData?.latitude, userData?.longitude]);

  useEffect(() => {
    const logoContainer = document.querySelector(".user-logo-container");
    if (userData?.logoUrl && logoContainer)
      logoContainer.style.backgroundImage = `url(${userData?.logoUrl})`;
  }, [userData, document.querySelector(".user-logo-container")]);

  useEffect(async () => {
    setLatitude(userData?.latitude);
    setLongitude(userData?.longitude);
    setAddress(userData?.address);
  }, []);

  useEffect(() => {
    if (!address) {
      Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          getAddress(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);

  const handleValidation = () => {
    const logoContainer = document.querySelector(".logo-error");
    if (!userData?.logoUrl && !logoImage) {
      logoContainer.innerText = "Please Upload Logo";
      logoContainer.style.color = "red";
    } else {
      logoContainer.innerText = "";
    }
  };

  const getAddress = (address, lat, lng) => {
    if (lat && lng) {
      setLatitude("");
      setLongitude("");
      setLatitude(lat);
      setLongitude(lng);
    }
    if (address.length > 50) {
      setAddress(address);
      setFullAddress(address);
    } else {
      setAddress(address);
      setFullAddress(address);
    }
  };

  return (
    <div>
      <Header title="Profile Details" />
      {loading && <LinearProgress />}
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onUpdateUserProfile}
        style={{ paddingLeft: 24, paddingRight: 24 }}
      >
        <Grid container spacing={2}>
          <Grid item md={4} sm={12}>
            <FormGroup
              className="has-wrapper text-left  form-group-signup"
              for="fullName"
            >
              <Label for="fullName" required>
                Full Name
              </Label>
              <InputGroup>
                <Input
                  readOnly={readonly}
                  type="text"
                  name="fullName"
                  id="fullName"
                  className="has-input input-lg"
                  placeholder="Enter your full name"
                  feedback={true}
                />
                <FormFeedback name="fullName" />
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid item md={4} sm={12}>
            <FormGroup
              className="has-wrapper text-left  form-group-signup"
              for="email"
            >
              <Label for="email" required>
                Email Address
              </Label>
              <InputGroup>
                <Input
                  type="email"
                  readOnly={readonly}
                  name="email"
                  id="email"
                  className="has-input input-lg"
                  placeholder="Enter Email Address"
                  feedback={true}
                  disabled={true}
                />
                <FormFeedback name="email" />
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid item md={4} sm={12}>
            <FormGroup
              className="has-wrapper text-left  form-group-signup"
              for="phone"
            >
              <Label for="phone" required>
                Contact Number
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="phone"
                  readOnly={readonly}
                  id="phone"
                  className="has-input input-lg"
                  placeholder="Contact Number"
                  feedback={true}
                />
                <FormFeedback name="phone" />
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid item md={4} sm={12}>
            <FormGroup
              className="has-wrapper text-left  form-group-signup"
              for="companyName"
            >
              <Label for="companyName" required>
                Business Name
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="companyName"
                  readOnly={readonly}
                  id="companyName"
                  className="has-input input-lg"
                  placeholder="Enter Business Name "
                  feedback={true}
                />
                <FormFeedback name="companyName" />
              </InputGroup>
            </FormGroup>
          </Grid>

          <Grid item md={8} sm={12}>
            <FormGroup
              className="has-wrapper text-left  form-group-signup"
              for="description"
            >
              <Label for="description" required>
                Short description of restaurant/business
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="description"
                  readOnly={readonly}
                  id="description"
                  className="has-input input-lg"
                  placeholder="Short description of restaurant/business"
                  feedback={true}
                />
                <FormFeedback name="description" />
              </InputGroup>
            </FormGroup>
          </Grid>
        </Grid>

        <div className={"theme-line-breaker mb-4 mt-4"} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className={"user-logo-container"}>
              <FormGroup
                className="has-wrapper text-left  form-group-signup"
                for="logo"
              >
                {!readonly && (
                  <Label
                    for="logo"
                    className={"text-center"}
                    style={{ cursor: "pointer", fontWeight: 500 }}
                  >
                    <h3>
                      Upload logo <i className="icon-cloud-upload" />
                    </h3>
                    <h5>
                      (Preferred aspect ratio is 145x145 and size should be
                      lower than 2MB)
                    </h5>
                  </Label>
                )}
                <InputGroup>
                  <Input
                    type="file"
                    name="logo"
                    readOnly={readonly}
                    accept={
                      "image/png, image/jpeg, image/jpg, image/gif, image/svg"
                    }
                    id="logo"
                    style={{ display: "none" }}
                    className="has-input input-lg"
                    feedback={true}
                    onChange={handleLogoChange}
                  />
                  <FormFeedback name="logo" />
                </InputGroup>
                <span className="has-icon top-45" />
              </FormGroup>
            </div>
            <small className={"logo-error mt-10"}></small>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="website"
                >
                  <Label for="website">Website</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      readOnly={readonly}
                      name="website"
                      id="website"
                      className="has-input input-lg"
                      placeholder="Website"
                      feedback={true}
                    />
                    <FormFeedback name="website" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="facebookLink"
                >
                  <Label for="facebookLink">Facebook Link</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="facebookLink"
                      readOnly={readonly}
                      id="facebookLink"
                      className="has-input input-lg"
                      placeholder="Facebook Link"
                      feedback={true}
                    />
                    <FormFeedback name="facebookLink" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="instagramLink"
                >
                  <Label for="instagramLink">Instagram Link</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="instagramLink"
                      id="instagramLink"
                      readOnly={readonly}
                      className="has-input input-lg"
                      placeholder="Instagram Link"
                      feedback={true}
                    />
                    <FormFeedback name="instagramLink" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="twitter-Link"
                >
                  <Label for="twitterLink">Twitter Link</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="twitterLink"
                      readOnly={readonly}
                      id="twitter-Link"
                      className="has-input input-lg"
                      placeholder="Twitter Link"
                      feedback={true}
                    />
                    <FormFeedback name="twitterLink" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="youtubeLink"
                >
                  <Label for="youtubeLink">YouTube Link</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="youtubeLink"
                      readOnly={readonly}
                      id="youtubeLink"
                      className="has-input input-lg"
                      placeholder="YouTube Link"
                      feedback={true}
                    />
                    <FormFeedback name="youtubeLink" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="businessContact"
                >
                  <Label for="businessContact">Business contact</Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="businessContact"
                      readOnly={readonly}
                      id="businessContact"
                      className="has-input input-lg"
                      placeholder="Business contact"
                      feedback={true}
                    />
                    <FormFeedback name="Business contact" />
                  </InputGroup>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <small className={"logo-error mt-10"}></small>
        <h2 style={{ marginTop: "2rem" }} className={"mb-3"}>
          <b>Location</b>
        </h2> */}
        <div className={"theme-line-breaker mb-4 mt-10"} />
        <Grid container spacing={2}>
          {!readonly && (
            <Grid item md={12} sm={12}>
              <RsFormGroup className="has-wrapper">
                <Label for="displayAddressInput">Search Address</Label>
                <LocationApi getAddress={getAddress} address={fullAddress} />
              </RsFormGroup>
            </Grid>
          )}
          {/* <Grid item  md={6} sm={12}>
            <Label for="displayAddressInput">Full Address</Label>
            <Input
              type="text"
              className="has-input input-lg"
              readOnly
              name={"readonly-address"}
              value={fullAddress}
            />
          </Grid> */}
          <Grid item md={12} sm={12}>
            <RsFormGroup className="has-wrapper">
              <div>
                <div
                  style={{
                    // position: "absolute",
                    // top: 0,
                    height: 400,
                    width: "100%",
                  }}
                >
                  {!!(latitude && longitude) && (
                    <GoogleMap
                      latitude={latitude}
                      longitude={longitude}
                      getAddress={getAddress}
                    />
                  )}
                </div>
              </div>
            </RsFormGroup>
          </Grid>
        </Grid>
        {/* <div style={{ marginTop: 410 }}>
          <OpeningHours
            previousSavedData={localUserData}
            openingHoursObj={openingHoursObj}
            setOpeningHoursObj={setOpeningHoursObj}
            showAllDays={true}
          />
        </div> */}
        <div className={"theme-line-breaker mb-4 mt-10"} />
        {!readonly && (
          <Grid container>
            <Grid item xs={12} align="center">
              <RsFormGroup style={{ marginTop: 20, marginBottom: 30 }}>
                <Button
                  className="btn-dark text-white btn-block w-70"
                  variant="contained"
                  size="large"
                  onClick={handleValidation}
                  type={"submit"}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress color={"white"} size={25} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </RsFormGroup>
            </Grid>
          </Grid>
        )}
      </Form>
    </div>
  );
};

export default UpdateProfile;
