import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { Checkbox, Typography } from "@material-ui/core";

import { FormGroup as RsFormGroup, InputGroup } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import GoogleMap from "../Components/GoogleMap/GoogleMap";
import LocationApi from "./locationapi";
import AppConfig from "Constants/AppConfig";
import { signupUserInFirebase } from "Store/Actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Feedback as FormFeedback,
} from "@availity/form";
import * as yup from "yup";
import _ from "underscore";
import { NotificationManager } from "react-notifications";
import { Grid, FormControlLabel, RadioGroup } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

function SignupFirebase(props) {
  // const [name, setName] = useState();
  const [logoImage, setLogoImage] = useState(null);
  const [address, setAddress] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.authUser.currentUser);
  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);

  const [initialValues, setInitialValues] = useState({
    fullName: "",
    companyName: "",
    email: "",
    password: "",
    description: "",
    phone: "",
    website: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    youtubeLink: "",
  });

  const validationSchema = yup.object({
    fullName: yup.string().label("Full Name").required().min(3),
    companyName: yup.string().label("Business Name ").required().min(3).max(60),
    email: yup.string().label("Email").required().email(),
    password: yup.string().label("Password").required().min(8).strongPassword(),
    // confirmPassword: yup
    //   .string()
    //   .label("Confirm Password")
    //   .required()
    //   .oneOf([yup.ref("password")], "Passwords do not match"),
    description: yup
      .string()
      .label("Short Description")
      .required()
      .min(3)
      .max(180),
    phone: yup.string().label("Phone").required(),
  });

  const onUserSignUp = async (values) => {
    setLoading(true);
    let formData = {
      fullName: values["fullName"] ?? "",
      email: values["email"] ?? "",
      companyName: values["companyName"] ?? "",
      password: values["password"] ?? "",
      description: values["description"] ?? "",
      address: fullAddress ?? "",
      phone: values["phone"] ?? "",
      longitude: longitude ?? "",
      latitude: latitude ?? "",
      createdAt: new Date(),
    };

    if (
      _.isBlank(formData["address"]) ||
      _.isBlank(formData["longitude"]) ||
      _.isBlank(formData["latitude"])
    ) {
      NotificationManager.error("Please Select Location on Map");
      return;
    }

    if (
      !_.isBlank(formData["email"]) &&
      !_.isBlank(formData["companyName"]) &&
      !_.isBlank(formData["description"]) &&
      !_.isBlank(formData["password"]) &&
      !_.isBlank(formData["address"]) &&
      !_.isBlank(formData["phone"]) &&
      !_.isBlank(formData["longitude"]) &&
      !_.isBlank(formData["latitude"])
    ) {
      if (!agreedToPrivacyPolicy) {
        NotificationManager.error(
          "Please accept our privacy policy to continue."
        );
        setLoading(false);
        return;
      }
      await dispatch(signupUserInFirebase(formData, props.history));
      setLoading(false);
    } else {
      NotificationManager.error("Please Fill Required Fields");
      setLoading(false);
      return;
    }
    props.setViewModal(false);
  };

  const getAddress = (address, lat, lng) => {
    if (lat && lng) {
      setLatitude("");
      setLongitude("");
      setLatitude(lat);
      setLongitude(lng);
    }
    if (address.length > 50) {
      setAddress(address.substr(0, 50) + "...");
      setFullAddress(address);
    } else {
      setAddress(address);
      setFullAddress(address);
    }
  };

  const handleLogoChange = (e) => {
    const logoFile = e.target.files[0];
    setLogoImage(logoFile);
    const logoUrl = window && window.URL.createObjectURL(logoFile);
    document.querySelector(
      ".user-logo-container"
    ).style.backgroundImage = `url(${logoUrl})`;
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        () => {
          setLatitude(AppConfig.defaultLat);
          setLongitude(AppConfig.defaultLng);
        }
      );
    }
  }, []);

  return (
    <QueueAnim type="bottom" duration={2000}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", overflow: "auto", paddingTop: 50 }}
        // className="rct-session-wrapper"
      >
        <Grid item xs={11} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item sm={4} xs={12} align="center">
                  <img
                    src={AppConfig.appLogo}
                    alt="session-logo"
                    className="img-fluid"
                    width="110"
                    height="35"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{ color: "#363636" }}
                  >
                    Sign Up
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="theme-line-breaker" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} md={5} align="center">
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onUserSignUp}
            style={{ marginTop: "3.5rem", padding: "1.5rem 0" }}
          >
            <div
              className={"mb-4"}
              style={{
                width: "97.8%",
                position: "absolute",
                left: 12,
                top: 90,
              }}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left form-group-signup"
                  for="fullName"
                >
                  <Label for="fullName" required>
                    Full Name
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="fullName"
                      id="fullName"
                      className="has-input input-lg"
                      placeholder="Enter Full Name"
                      feedback={true}
                    />
                    <FormFeedback name="fullName" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="email"
                >
                  <Label for="email" required>
                    Email Address
                  </Label>
                  <InputGroup>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      className="has-input input-lg"
                      placeholder="Enter Email Address"
                      feedback={true}
                    />
                    <FormFeedback name="email" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="password"
                >
                  <Label for="password" required>
                    Create Password
                  </Label>
                  <InputGroup>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="has-input input-lg"
                      placeholder="Create Password"
                      feedback={true}
                    />
                    <FormFeedback name="password" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="phone"
                >
                  <Label for="phone" required>
                    Contact Number
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      className="has-input input-lg"
                      placeholder="Contact Number"
                      feedback={true}
                    />
                    <FormFeedback name="phone" />
                  </InputGroup>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormGroup
                  className="has-wrapper text-left form-group-signup"
                  for="companyName"
                >
                  <Label for="companyName" required>
                    Business Name
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="companyName"
                      id="companyName"
                      className="has-input input-lg"
                      placeholder="Enter Your Business Name"
                      feedback={true}
                    />
                    <FormFeedback name="companyName" />
                  </InputGroup>
                </FormGroup>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <FormGroup
                  className="has-wrapper text-left  form-group-signup"
                  for="description"
                >
                  <Label for="description" required>
                    Business Description
                  </Label>
                  <InputGroup>
                    <Input
                      type="textarea"
                      style={{ height: 100 }}
                      name="description"
                      id="description"
                      className="has-input input-lg"
                      placeholder="Enter your Business Description"
                      feedback={true}
                    />
                    <FormFeedback name="description" />
                  </InputGroup>
                </FormGroup>
              </Grid>
            </Grid>

            <div className={"theme-line-breaker mt-4"} />
            <h2 style={{ marginTop: "2rem" }} className={"mb-4 text-left"}>
              <b>Location</b>
            </h2>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={12}>
                <RsFormGroup>
                  <LocationApi getAddress={getAddress} />
                </RsFormGroup>
              </Grid>
              {/* <Grid item xs={12} md={6} sm={12}>
                <Input
                  disabled
                  value={fullAddress}
                  name={"disabled-full-address"}
                />
              </Grid> */}
              <Grid item md={12} sm={12} xs={12}>
                <RsFormGroup>
                  <div
                    style={{
                      // position: "absolute",
                      // top: 0,
                      height: 300,
                      width: "100%",
                    }}
                  >
                    {latitude && (
                      <GoogleMap
                        latitude={latitude}
                        longitude={longitude}
                        getAddress={getAddress}
                      />
                    )}
                  </div>
                </RsFormGroup>
              </Grid>
            </Grid>
            <Grid style={{ margin: "5px 0 20px" }} item xs={12} align="start">
              <Grid
                container
                justifyContent="start"
                alignItems="start"
                direction="row"
              >
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Typography variant="body1">
                        <Checkbox
                          color="black"
                          checked={agreedToPrivacyPolicy}
                          onChange={(e) =>
                            setAgreedToPrivacyPolicy(e.target.checked)
                          }
                          style={{ marginTop: -8 }}
                        /> 
                        <span>By signing up you agree to Goldfinch</span>

                        <Link
                          to={"/privacy-policy"}
                          className={" text-info"}
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                            // display: "inline",
                            marginLeft: 5,
                          }}
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <RsFormGroup className="mb-15" style={{ marginTop: 0 }}>
                  <Button
                    className="btn-dark text-white btn-block w-50"
                    variant="contained"
                    size="large"
                    type={"submit"}
                    disabled={
                      loading //|| !agreedToPrivacyPolicy
                    }
                  >
                    {loading ? (
                      <CircularProgress color={"white"} size={25} />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </RsFormGroup>
              </Grid>
              <Grid item xs={12}>
                <span>
                  Already have an account.{" "}
                  <Link
                    to={"signin"}
                    className={"w-100 text-info"}
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      display: "inline",
                    }}
                  >
                    Sign in
                  </Link>
                </span>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </QueueAnim>
  );
}

export default SignupFirebase;
