import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const defaultToolbarStyles = {
  iconButton: {},
};

class AddCategoryButton extends React.Component {
  render() {
    const { classes, addCategoryModal } = this.props;

    return (
      <React.Fragment>
        {/*<Tooltip title={"Add Category"}>
          <IconButton
            className={classes?.iconButton}
            onClick={() => {
              addCategoryModal();
            }}
          >
            <AddIcon className={classes?.deleteIcon} />
          </IconButton>
        </Tooltip>*/}
        <Button
          onClick={() => {
            addCategoryModal();
          }}
          variant="contained"
          color="primary"
          className="bg-yellow-new"
        >
          {`Add Category`}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "AddCategoryButton" })(
  AddCategoryButton
);
