import { GET_ALL_PACKAGES, LOGOUT_USER } from "../Actions/types";

const initialState = {
  packages: {
    campaigns: [],
    franchise: [],
  },
};

const packagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        packages: {
          campaigns: [],
          franchise: [],
        },
      };

    default:
      return state;
  }
};

export default packagesReducer;
