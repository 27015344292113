import {
  ADD_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  REMOVE_PAYMENT_METHOD,
  GET_CUSTOMER_SUBSCRIPTION,
  TOGGLE_SUBSCRIPTION
} from "./types";

export const getPaymentMethodsAction = (payload) => ({
  type: GET_PAYMENT_METHODS,
  payload,
});

export const addPaymentMethodsAction = (payload) => ({
  type: ADD_PAYMENT_METHOD,
  payload,
});

export const removePaymentMethodsAction = (payload) => ({
  type: REMOVE_PAYMENT_METHOD,
  payload,
});

export const getCustomerSubscriptionAction = (payload) => ({
  type: GET_CUSTOMER_SUBSCRIPTION,
  payload,
});

export const toggleSubscriptionAction = (payload) => ({
  type: TOGGLE_SUBSCRIPTION,
  payload
})