import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment/moment";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@material-ui/core";
import { database } from "../../Firebase";

const IsolatedMenu = (props) => {
  const [checked, setChecked] = useState(props?.rowData?.status === "availed");

  return (
    <React.Fragment>
      <Switch
        color={props?.rowData?.status === "availed" ? "primary" : "secondary"}
        checked={checked}
        name="checkedB"
        inputProps={{ "aria-label": "primary checkbox" }}
        onChange={async ({ target: { checked } }) => {
          if (checked) {
            props.setOpen(true);
            props.setChangeStatusData({
              type: "availed",
              rowData: props?.rowData,
            });
            return setChecked(true);
          }
        }}
      />
    </React.Fragment>
  );
};

const ClaimedGoldfinchesList = () => {
  const [claimed_goldfinches, setClaimedGoldfinches] = useState(null);
  const uid = useSelector((state) => state?.authUser?.localUserData?.uid || "");
  const [open, setOpen] = React.useState(false);
  const [changeStatusData, setChangeStatusData] = useState({
    type: null,
    rowData: null,
  });

  const handleClose = () => {
    setOpen(false);
  };

  async function changeGoldFinchStatus(status, rowData) {
    await database
      .ref(`claimed/${rowData?.userId}/${rowData?.drenchKey}`)
      .update({ status: status });

    NotificationManager.success(`Status changed to ${status} Successfully`);
    setChangeStatusData({ type: null, rowData: null });
  }

  const columns = [
    {
      name: "firstName",
      label: "First Name",
    },
    {
      name: "lastName",
      label: "Last Name",
    },
    {
      name: "drenchTitle",
      label: "Goldfinch",
    },
    {
      name: "code",
      label: "Code",
    },
    {
      name: "status",
      label: "Status",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          let tableData = claimed_goldfinches;
          let currentRow = tableMeta?.currentTableData[tableMeta?.rowIndex];
          let rowData = tableData[currentRow?.index];
          return (
            <div>
              <span
                style={{
                  backgroundColor:
                    rowData?.status === "availed" ? "#28B873" : "#EBB600",
                }}
                className={`badge py-2 font-weight-bold text-uppercase`}
              >
                {rowData?.status}
              </span>
            </div>
          );
        },
      },
    },
    {
      name: "claimedAt",
      label: "Time",
      options: {
        viewColumns: true,
        filter: true,
        customBodyRender: (value) => {
          let formattedDate = moment(value).format(
            "MMM Do YYYY, dddd h:mm:ss a"
          );
          return <div>{formattedDate}</div>;
        },
        sort: true,
        sortDirection: "desc",
        sortCompare: (order) => {
          return (row1, row2) => {
            let val1 = parseInt(row1?.data, 10);
            let val2 = parseInt(row2?.data, 10);
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          let tableData = claimed_goldfinches;
          let currentRow = tableMeta?.currentTableData[tableMeta?.rowIndex];
          let rowData = tableData[currentRow?.index];
          return (
            <div>
              <IsolatedMenu
                rowIndex={currentRow?.index}
                rowData={rowData}
                tableData={tableData}
                setChangeStatusData={setChangeStatusData}
                setOpen={setOpen}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "textField",
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let tempArr = [];
    database.ref(`claimed/`).on("value", (snap) => {
      let data = snap.val();
      let dataArray = Object.values(data);
      tempArr = [];

      database.ref(`users/`).on("value", (snap) => {
        let dataU = snap.val();

        database.ref(`drenches/`).on("value", (snap) => {
          let dataD = snap.val();
          dataArray.map((items) => {
            items = Object.values(items);
            items.map((item) => {
              let drench = dataD[item?.drenchKey];
              if (
                typeof drench !== "undefined" &&
                drench !== null &&
                drench?.restaurantID[0] === uid
              ) {
                let user = dataU[item?.userId];
                if (typeof user !== "undefined" && user !== null) {
                  item.firstName = user?.firstName;
                  item.lastName = user?.lastName;
                  item.email = user?.email;
                }
                item.restaurantID = uid;
                item.drenchTitle = drench?.title;
                tempArr.push(item);
              }
            });
          });
          setClaimedGoldfinches(tempArr);
        });
      });
    });
  };
  return (
    <div>
      <MUIDataTable
        title={
          <h1 className={"font-weight-bold"} style={{ color: "#242424" }}>
            Claimed Goldfinches
          </h1>
        }
        // data={(claimed_goldfinches ? claimed_goldfinches : []).filter((f) => f?.status === "claimed")}
        data={claimed_goldfinches ? claimed_goldfinches : []}
        columns={columns}
        options={{
          selectableRows: false,
          elevation: 0,
          download: false,
          print: false,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Change Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change Goldfinch status. This action cannot
            be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={async () => {
              await changeGoldFinchStatus(
                changeStatusData.type,
                changeStatusData.rowData
              );
              setOpen(false);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClaimedGoldfinchesList;
