import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import { FormGroup as RsFormGroup, InputGroup } from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueueAnim from "rc-queue-anim";
import { Form, Input, Label, FormGroup, Feedback as FormFeedback } from "@availity/form";
import * as yup from "yup";
import _ from "underscore";
import { auth } from "../Firebase/index";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "Components/ConfirmationModal";
import { Link } from "react-router-dom";

export function ForgotPassword(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [initialValues] = useState({
    email: "",
  });

  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  console.log(function_url, "functionURL");

  const validationSchema = yup.object({
    email: yup.string().label("Email").required().email(),
  });

  const onPasswordReset = async (values) => {
    try {
      setLoading(true);
      let formData = {
        email: values["email"] ?? "",
      };

      if (!_.isBlank(formData["email"])) {
        let fetchURL = function_url + "/forgotPassword";

        let response = await fetch(fetchURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: formData.email,
            // userId: partner.uid,
            // stripeId: partner?.stripeId,
            // name: partner?.companyName,
            // phone: partner?.phone,
          }),
        });
        if (response.ok) {
          NotificationManager.success("Reset password email is sent to your inbox.");

          setOpen(true);
        } else {
          NotificationManager.error("Invalid Email");
        }
        // auth
        //   .sendPasswordResetEmail(formData.email)
        //   .then((res) => {
        //     NotificationManager.success("Email is sent to your email.");
        //     history.push("signin");
        //   })
        //   .catch((error) => {
        //     NotificationManager.error(error?.message);
        //   });
        setLoading(false);
      } else {
        setLoading(false);
        NotificationManager.error("Please Fill Required Fields");
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error(error.message);
    }
  };

  return (
    <QueueAnim type="bottom" duration={2000}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        style={{ minHeight: "100vh" }}
        className=""
      >
        <Grid item xs={11} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                {/* <Grid item xs={4}>
                  <img
                    src={AppConfig.appLogo}
                    alt="session-logo"
                    className="img-fluid"
                    width="110"
                    height="35"
                  />
                </Grid> */}
                <Grid item xs={12} align="center">
                  <Typography variant="h3" style={{ color: "#363636" }}>
                    Forgot Password
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="theme-line-breaker" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} md={4} align="center">
          <Form
            style={{ width: "90%" }}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onPasswordReset}
          >
            <FormGroup className="has-wrapper text-left" for="email">
              <Label for="email">Email Address</Label>
              <InputGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  className="has-input input-lg"
                  placeholder="Enter Email Address"
                  feedback={true}
                />
                <FormFeedback name="email" />
              </InputGroup>
              <span className="has-icon top-45">
                <i className="ti-email" />
              </span>
            </FormGroup>
            <RsFormGroup className="mb-15">
              <Button
                className="btn-block text-white mt-2 w-50 bg-primary"
                variant="contained"
                size="large"
                type={"submit"}
                disabled={loading}
              >
                {loading ? <CircularProgress color={"white"} size={25} /> : "Send Email"}
              </Button>
            </RsFormGroup>
            <span>
              <Link
                to={"/auth/signin"}
                className={"w-100 font-weight-bold text-info"}
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  display: "inline",
                }}
              >
                Go Back
              </Link>
            </span>
          </Form>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <ConfirmationModal open={open} setOpen={setOpen} />
    </QueueAnim>
  );
}

export default ForgotPassword;
