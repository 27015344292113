import { auth } from "./../Firebase";
import { NotificationManager } from "react-notifications";

export const sendMail = async ({ email, status, html, text }) => {
  try {
    if (html && text) {
      try {
        // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/sendEmail";
        let fetchURL = process.env.REACT_APP_FUNCTION_URL + "/sendEmail";
        const authToken = await auth.currentUser.getIdToken();

        let response = await fetch(fetchURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": authToken,
          },
          body: JSON.stringify({
            subject: status,
            text: text,
            html: html,
            to: email,
          }),
        });
        if (response.ok) {
          let result = await response.json();
        }
      } catch (err) {
        console.log(err, "error response");
        NotificationManager.error(JSON.stringify(err));
      }
    }
  } catch (err) {
    console.log(err, "error response");
  }
};
