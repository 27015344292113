import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import AppConfig from "Constants/AppConfig";
import { db } from "../../Firebase";

function PrivacyPolicy(props) {
  const [loading, setLoading] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const getPrivacyPolicy = () => {
    setLoading(true);
    db.collection("application-documents")
      .doc("privacy-policy")
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        setPrivacyPolicy(querySnapshot.data()?.content);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <div style={{ height: "100%", overflow: "auto", paddingBottom: 20 }}>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <img src={AppConfig.appLogo} alt="session-logo" className="img-fluid" width="110" height="35" />
        <Typography variant="h3" style={{ color: "#363636" }}>
          Privacy Policy
        </Typography>
        <div></div>
      </div>
      <div className="theme-line-breaker" />
      <div className="mt-30 container">
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />{" "}
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
        )}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
