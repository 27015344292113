import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormGroup, Input, Label } from "reactstrap";
import { db } from "../../Firebase";
import Header from "./../../Components/NewHeader";
import { v4 } from "uuid";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { sendMail } from "Util/mail";
import { NotificationManager } from "react-notifications";

export default function FreeRewards(props) {
  const [partners, setPartners] = useState(null);
  const [form, setForm] = useState({ rewardType: "", partner: "", quantity: 1, startDate: null, endDate: null });
  const rewards = ["Franchise", "Finch", "Goldfinch"];

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getData = async () => {
    try {
      const snap = await db.collection("restaurants").where("role", "==", "merchant").get();
      const data = snap.docs.map((doc) => doc.data());
      setPartners(data);
    } catch (error) {
      console.log(error, "FreeRewards -> getData");
    }
  };

  const handleSubmit = async () => {
    try {
      const { rewardType, partner, quantity, startDate, endDate } = form;
      if (!rewardType || !partner || !quantity || !startDate || !endDate) return;
      const packageType = rewardType === "Franchise" ? "FRANCHISE" : "CAMPAIGN";
      const data = {
        uid: partner,
        packageType,
        isAssigned: true,
        rewardType,
        quantity: parseInt(quantity),
        current_period_end: moment(endDate).unix(),
        current_period_start: moment(startDate).unix(),
      };
      await db.collection("subscriptions").add(data);
      const email = partners.find((p) => p.uid === partner).email;

      sendMail({
        email,
        status: "Congratulations",
        html: `<h1>You have been assigned a new reward</h1><br /><p>Your rewards are ${quantity} ${packageType}, valid from ${moment(
          startDate
        ).format("lll")} to ${moment(endDate).format("lll")}</p>`,
        text: `You have been assigned a new reward. \n Your rewards are ${quantity} ${packageType}, valid from ${moment(
          startDate
        ).format("lll")} to ${moment(endDate).format("lll")}`,
      });
      setForm({ rewardType: "", partner: "", quantity: 1, startDate: null, endDate: null });

      NotificationManager.success("Reward assigned successfully");
    } catch (error) {
      console.log(error, "FreeRewards -> handleSubmit");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    partners && (
      <>
        <Header title="Free Rewards" />
        <div className="px-25 pb-25">
          <Grid container spacing={2} className={"mt-4"} style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Grid item md={6} sm={12} xs={12}>
              <Label>Select Partner</Label>
              <FormGroup className="has-wrapper">
                <Input
                  style={{ height: "3rem" }}
                  type="select"
                  name="partner"
                  onChange={handleChange}
                  value={form.partner}
                >
                  <option key={""}>Select Partner</option>
                  {partners.map((item) => (
                    <option value={item.uid} key={item.uid}>
                      {item.companyName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Label>Reward Type</Label>
              <FormGroup className="has-wrapper">
                <Input
                  style={{ height: "3rem" }}
                  type="select"
                  name="rewardType"
                  onChange={handleChange}
                  value={form.rewardType}
                >
                  <option key={""}>Select Reward Type</option>
                  {rewards.map((reward) => (
                    <option value={reward} key={v4()}>
                      {reward}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="mui-datetime-pickers">
              <Label>Start Date</Label>
              <KeyboardDateTimePicker
                inputVariant="outlined"
                ampm={false}
                placeholder="Select Date & Time"
                disablePast
                format="yyyy/MM/DD HH:mm"
                fullWidth
                name={"startDate"}
                value={form.startDate}
                onChange={(startDate) => setForm({ ...form, startDate })}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12} className="mui-datetime-pickers">
              <Label>End Date</Label>
              <KeyboardDateTimePicker
                inputVariant="outlined"
                ampm={false}
                placeholder="Select Date & Time"
                disablePast
                format="yyyy/MM/DD HH:mm"
                fullWidth
                name="endDate"
                value={form.endDate}
                onChange={(endDate) => setForm({ ...form, endDate })}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Label>Quantity</Label>
              <FormGroup className="has-wrapper">
                <Input
                  min={1}
                  type="number"
                  name="quantity"
                  onChange={(e) => e.target.value > 0 && handleChange(e)}
                  value={form.quantity}
                />
              </FormGroup>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Button
                onClick={handleSubmit}
                className="btn-dark text-white text-white-2 btn-block w-50"
                style={{ margin: "3rem auto", display: "block" }}
                variant="contained"
                size="large"
              >
                Send Free Rewards
              </Button>
            </Grid>
          </Grid>
        </div>
      </>
    )
  );
}
