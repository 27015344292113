import appLocaleData from "react-intl/locale-data/he";
import saMessages from "../locales/he_HE";

const saLang = {
  messages: {
    ...saMessages,
  },
  locale: "he-he",
  data: appLocaleData,
};
export default saLang;
