import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ChartContainer from "./../../Components/ChartContainer";
import LineChartSmall from "./../../Routes/charts/LineChartSmall";
import LineChartBig from "./../../Routes/charts/LineChartBig";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Modal,
  Paper,
  Select,
  MenuItem,
} from "@material-ui/core";
import { auth, database, storage, db } from "../../Firebase/index";
import moment from "moment";
import _ from "lodash";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays, format } from "date-fns";
import Header from "./../../Components/NewHeader";
import AnalyticsMerchant from "Routes/analythesMercent";

function Analytics(props) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [data, setData] = useState([]);
  const [savedFinches, setSavedFinches] = useState([]);
  const [viewedFinches, setViewedFinches] = useState([]);
  const [directions, setDirections] = useState([]);
  const [phoneNo, setPhoneNo] = useState([]);
  const [website, setWebsite] = useState([]);
  const [media, setMedia] = useState([]);
  const [analyticType, setAnalyticType] = useState();
  const [partners, setPartners] = useState([]);

  const [datePicker, setDatePicker] = useState(false);
  console.log(analyticType, "analyticType");
  const getAnalytics = async (resId) => {
    try {
      const user = auth.currentUser;
      const data = await db
        .collection("analytics")
        .where("partnerId", "==", resId)
        .where("createdAt", ">=", new Date(value?.[0]?.startDate))
        .where("createdAt", "<=", new Date(value?.[0]?.endDate))
        .get();

      console.log(data);
      const dataArray = data.docs.map((doc) => doc.data());
      console.log({ ...sortFilterTypeData(dataArray, "claimed") }, resId);
      setSavedFinches({ ...sortFilterTypeData(dataArray, "claimed") });
      setViewedFinches(sortFilterTypeData(dataArray, "viewed"));
      setDirections(sortFilterData(dataArray, "location"));
      setPhoneNo(sortFilterData(dataArray, "phone"));
      setWebsite(sortFilterData(dataArray, "website"));

      setMedia(sortMediaData(dataArray, "social"));
      // console.log(dataArray, "data");
    } catch (error) {
      console.log(error);
    }
  };
  const sortFilterTypeData = (arr, type) => {
    const newArr = arr.filter((item) => item.type === type);
    const drizzle = newArr.filter((item) => item.finchType === "drizzles");
    const drenches = newArr.filter((item) => item.finchType !== "drizzles");
    const drenchesData = filterSortDrenches(drenches);
    const drizzlesData = filterSortDrenches(drizzle);

    return {
      drizzle: drizzlesData,
      drench: drenchesData,
    };
  };
  const sortMediaData = (data, type) => {
    let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const mediaSupported = ["facebook", "instagram", "twitter", "youtube"];
    const mediaData = data.filter((item) =>
      mediaSupported.some((v) => v === item?.platform)
    );
    const dateArr = mediaData.map((item) => {
      return {
        ...item,
        dateString: moment(new Date(item.createdAt?.seconds * 1000)).format(
          "ddd"
        ),
      };
    });

    const sortedData = _.groupBy(dateArr, "dateString");
    const newGroupByDay = dayArr.map((key) => {
      return [
        key,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[0])
          ?.length || 0,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[1])
          ?.length || 0,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[2])
          ?.length || 0,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[3])
          ?.length || 0,
      ];
    });
    console.log(newGroupByDay, "dateArr");
    return newGroupByDay;
  };

  const sortFilterData = (arr, type) => {
    let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const newArr = arr.filter((item) => item.type === type);
    const dateArr = newArr.map((item) => {
      return {
        ...item,
        dateString: moment(new Date(item.createdAt?.seconds * 1000)).format(
          "ddd"
        ),
      };
    });
    // console.log(newArr, "newArr", type);
    const sortedByData = dateArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.createdAt?.seconds * 1000) -
        new Date(a.createdAt?.seconds * 1000)
      );
    });

    const groupByDay = _.groupBy(sortedByData, "dateString");

    const newGroupByDay = dayArr.map((key) => {
      return groupByDay?.[key]?.length || 0;
    });
    return newGroupByDay;
  };

  const filterSortDrenches = (arr, type) => {
    let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dateArr = arr.map((item) => {
      // console.log(item, "LOLOLOs");
      return {
        ...item,
        dateString: moment(new Date(item.createdAt?.seconds * 1000)).format(
          "ddd"
        ),
      };
    });
    // console.log(newArr, "newArr", type);
    const sortedByData = dateArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.createdAt?.seconds * 1000) -
        new Date(a.createdAt?.seconds * 1000)
      );
    });

    const groupByDay = _.groupBy(sortedByData, "dateString");

    const newGroupByDay = dayArr.map((key) => {
      return groupByDay?.[key]?.length || 0;
    });
    return newGroupByDay;
  };

  const getPartners = async () => {
    try {
      const data = await db.collection("restaurants").get();
      const dataArray = data.docs.map((doc) => doc.data());
      setPartners(dataArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!datePicker) {
      // getAnalytics();
      getPartners();
    }
  }, [datePicker]);

  useEffect(() => {
    getAnalytics(analyticType);
  }, [analyticType]);
  var startDate = format(new Date(value?.[0]?.startDate), "dd/MM/yyyy");
  var endDate = format(new Date(value?.[0]?.endDate), "dd/MM/yyyy");
  return (
    <>
      <Header title="Global Analytics" />
      <div
        className="video-player-wrapper"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Grid
          style={{ marginBottom: 20 }}
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Select
              onChange={(e) => setAnalyticType(e.target.value)}
              value={analyticType}
              variant="outlined"
              style={{ width: 300, height: 50, borderRadius: 8 }}
            >
              <MenuItem value="">Global Analytics</MenuItem>
              {partners.map((item) => (
                <MenuItem value={item?.uid}>{item.companyName}</MenuItem>
              ))}
            </Select>
          </Grid>

          {!analyticType && (
            <Grid item>
              <Button
                onClick={() => setDatePicker(true)}
                variant="contained"
                color="primary"
              >
                {startDate} to {endDate}
              </Button>
            </Grid>
          )}
        </Grid>
        {analyticType ? (
          <AnalyticsMerchant adminLayout={true} id={analyticType} />
        ) : (
          <>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <ChartContainer title="User Saved Goldfinch/Finches" isSmall>
                  <LineChartSmall data={savedFinches} isMultiple />
                </ChartContainer>
              </Grid>
              <Grid item xs={6}>
                <ChartContainer title="User Viewed Goldfinch/Finches" isSmall>
                  <LineChartSmall data={viewedFinches} isMultiple />
                </ChartContainer>
              </Grid>
              {/* <Grid item xs={4}>
          <ChartContainer title="Users Pressed Email" isSmall>
            <LineChartSmall data={email} />
          </ChartContainer>
        </Grid> */}
              <Grid item xs={4}>
                <ChartContainer title="Users Pressed Get Directions" isSmall>
                  <LineChartSmall data={directions} />
                </ChartContainer>
              </Grid>
              <Grid item xs={4}>
                <ChartContainer title="Users Received Notifications" isSmall>
                  <LineChartSmall />
                </ChartContainer>
              </Grid>
              <Grid item xs={4}>
                <ChartContainer title="Users Pressed Phone Number" isSmall>
                  <LineChartSmall data={phoneNo} />
                </ChartContainer>
              </Grid>
              <Grid item xs={6}>
                <ChartContainer title="Users Pressed Website" isSmall>
                  <LineChartSmall data={website} />
                </ChartContainer>
              </Grid>
              <Grid item xs={6}>
                <ChartContainer
                  title="Goldfinches Not Claimed For Over A Week"
                  isSmall
                >
                  <LineChartSmall />
                </ChartContainer>
              </Grid>
              <Grid item xs={12}>
                <ChartContainer title="Social Media Platforms Activity" isSmall>
                  <LineChartBig data={media} />
                </ChartContainer>
              </Grid>
            </Grid>

            <Modal open={datePicker} onClose={() => setDatePicker(false)}>
              <Grid
                container
                spacing={5}
                style={{ minHeight: "100vh" }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Paper style={{ padding: 10 }}>
                    <Grid container>
                      <Grid item xs={12} align="center">
                        <DateRangePicker
                          ranges={value}
                          onChange={(item) => setValue([item.selection])}
                        />
                      </Grid>
                      <Grid item xs={12} align="right">
                        <Button
                          onClick={() => setDatePicker(false)}
                          variant="contained"
                          color="primary"
                        >
                          Done
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Modal>
          </>
        )}
      </div>
    </>
  );
}
export default Analytics;
