import React, { useState, useEffect } from "react";
import AllAccounts from "./AllAccounts";
import { useSelector } from "react-redux";
import { db } from "../../Firebase";

export default function AccountRequests(props) {
  const [data, setData] = useState(null);
  const isAdmin = useSelector((state) => state.authUser.isAdmin);
  const userDaata = useSelector((state) => state.authUser.userDaata);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [userDaata]);

  const getData = async () => {
    try {
      const snap = await db
        .collection("restaurants")
        .where("role", "==", "merchant")
        .orderBy("createdAt", "desc")
        .get();
      const data = snap.docs.map((doc) => doc.data());
      setData(
        data.map((item) => ({
          ...item,
          createdAt: new Date(item.createdAt.seconds * 1000),
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "AccountRequests -> getData");
    }
  };
  console.log(userDaata?.role, "userDaata?.role");
  return (
    data && (
      <div className="px-15">
        <div className="row">
          {userDaata?.role === "admin" && (
            <AllAccounts loading={loading} setLoading={setLoading} getData={getData} allAccountsData={data} />
          )}
        </div>
      </div>
    )
  );
}
