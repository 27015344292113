module.exports = {
  "sidebar.app": "التطبيق",
  "sidebar.news": "أخبار",
  "sidebar.horizontal": "أفقي",
  "sidebar.horizontalMenu": "القائمة الأفقية",
  "sidebar.general": "جنرال لواء",
  "sidebar.component": "مكون",
  "sidebar.features": "الميزات",
  "sidebar.applications": "تطبيقات",
  "sidebar.dashboard": "لوحة القيادة",
  "sidebar.dashboard1": "لوحة البيانات 1",
  "sidebar.dashboard2": "لوحة القيادة 2",
  "sidebar.dashboard3": "لوحة القيادة 3",
  "sidebar.modules": "وحدات",
  "sidebar.agency": "وكالة",
  "sidebar.pages": "صفحات",
  "sidebar.gallery": "صالة عرض",
  "sidebar.pricing": "التسعير",
  "sidebar.terms&Conditions": "البنود و الظروف",
  "sidebar.feedback": "ردود الفعل",
  "sidebar.report": "أبلغ عن",
  "sidebar.faq(s)": "أسئلة وأجوبة (ق)",
  "sidebar.advancedComponent": "مكون متقدم",
  "sidebar.blank": "فراغ",
  "sidebar.session": "جلسة",
  "sidebar.login": "تسجيل الدخول",
  "sidebar.register": "تسجيل",
  "sidebar.lockScreen": "اقفل الشاشة",
  "sidebar.forgotPassword": "هل نسيت كلمة المرور",
  "sidebar.404": "404",
  "sidebar.500": "500",
  "sidebar.uiComponents": "مكونات واجهة المستخدم",
  "sidebar.alerts": "التنبيهات",
  "sidebar.appBar": "شريط التطبيق",
  "sidebar.avatars": "الآلهة",
  "sidebar.buttons": "وصفت",
  "sidebar.bottomNavigations": "التنقلات السفلية",
  "sidebar.badges": "شارات",
  "sidebar.cards": "بطاقات",
  "sidebar.cardsMasonry": "بطاقات البناء",
  "sidebar.chip": "رقاقة",
  "sidebar.dialog": "الحوار",
  "sidebar.dividers": "فواصل",
  "sidebar.drawers": "أدراج",
  "sidebar.popover": "البوب",
  "sidebar.expansionPanel": "لوحة التوسعة",
  "sidebar.gridList": "قائمة الشبكة",
  "sidebar.list": "قائمة",
  "sidebar.menu": "قائمة طعام",
  "sidebar.popoverAndToolTip": "تلميحالبوب",
  "sidebar.progress": "تقدم",
  "sidebar.snackbar": "مطعم الوجبات الخفيفة",
  "sidebar.selectionControls": "ضوابط الاختيار",
  "sidebar.advanceUiComponents": "مكونات واجهة المستخدم المتقدمة",
  "sidebar.dateAndTimePicker": "التاريخ والوقت المنتقى",
  "sidebar.tabs": "علامات التبويب",
  "sidebar.stepper": "السائر",
  "sidebar.notification": "إعلام",
  "sidebar.sweetAlert": "الحلو تنبيه",
  "sidebar.autoComplete": "إكمال تلقائي",
  "sidebar.aboutUs": "معلومات عنا",
  "sidebar.widgets": "الحاجيات",
  "sidebar.forms": "إستمارات",
  "sidebar.formElements": "عناصر النموذج",
  "sidebar.textField": "حقل النص",
  "sidebar.selectList": "اختر القائمة",
  "sidebar.charts": "الرسوم البيانية",
  "sidebar.reCharts": "إعادة الرسوم البيانية",
  "sidebar.reactChartjs2": "تتفاعل خريطة شبيبة 2",
  "sidebar.icons": "الرموز",
  "sidebar.themifyIcons": "معهمإذاذ الأيقونات",
  "sidebar.simpleLineIcons": "أيقونات الخط البسيط",
  "sidebar.fontAwesome": "خط رائع",
  "sidebar.materialIcons": "أيقونات المواد",
  "sidebar.tables": "الجداول",
  "sidebar.basic": "الأساسية",
  "sidebar.dataTable": "جدول البيانات",
  "sidebar.responsive": "متجاوب",
  "sidebar.reactTable": "رد فعل الجدول",
  "sidebar.maps": "خرائط",
  "sidebar.googleMaps": "خرائط جوجل",
  "sidebar.leafletMaps": "خرائط النشرة",
  "sidebar.inbox": "صندوق الوارد",
  "sidebar.users": "المستخدمين",
  "sidebar.userProfile1": "ملف تعريف المستخدم 1",
  "sidebar.userProfile2": "ملف تعريف المستخدم 2",
  "sidebar.userManagement": "إدارةالمستخدم",
  "sidebar.userProfile": "ملف تعريفي للمستخدم",
  "sidebar.userList": "قائمة المستخدم",
  "sidebar.calendar": "التقويم",
  "sidebar.cultures": "الثقافات",
  "sidebar.dnd": "دالثانية",
  "sidebar.selectable": "اختيار",
  "sidebar.customRendering": "التقديم المخصص",
  "sidebar.chat": "دردشة",
  "sidebar.toDo": "لكى يفعل",
  "sidebar.editor": "محرر",
  "sidebar.wysiwygEditor": "محرر WYSIWYG",
  "sidebar.quillEditor": "محرر ريشة",
  "sidebar.reactAce": "رد فعل ايس",
  "sidebar.dragAndDrop": "اسحب وإسقاط",
  "sidebar.reactDragula": "رد دراغولا",
  "sidebar.reactDnd": "رد دالثانية",
  "sidebar.blogManagement": "إدارة المدونات",
  "sidebar.ecommerce": "التجارة الإلكترونية",
  "sidebar.shopList": "قائمة متجر",
  "sidebar.shopGrid": "متجر الشبكة",
  "sidebar.invoice": "فاتورة",
  "sidebar.multilevel": "متعدد المستويات",
  "sidebar.sublevel": "متعدد المستويات",
  "widgets.totalEarns": "مجموع يكسب",
  "widgets.emailsStatistics": "إحصائيات البريد الإلكتروني",
  "widgets.totalRevenue": "إجمالي الإيرادات",
  "widgets.onlineVistors": "زوار الانترنت",
  "widgets.trafficSources": "مصادر حركة المرور",
  "widgets.RecentOrders": "الطلبيات الأخيرة",
  "widgets.topSellings": "أعلى بيع",
  "widgets.productReports": "تقارير المنتجات",
  "widgets.productStats": "إحصائيات المنتج",
  "widgets.ComposeEmail": "إنشاء البريد الإلكتروني",
  "widgets.employeePayroll": "موظف الرواتب",
  "widgets.visitors": "الزائرين",
  "widgets.orders": "أوامر",
  "widgets.orderStatus": "حالة الطلب",
  "widgets.totalSales": "إجمالي المبيعات",
  "widgets.netProfit": "صافي الربح",
  "widgets.overallTrafficStatus": "الحالة العامة لحركة المرور",
  "widgets.tax": "ضريبة",
  "widgets.expenses": "نفقات",
  "widgets.currentTime": "الوقت الحالي",
  "widgets.currentDate": "التاريخ الحالي",
  "widgets.todayOrders": "أوامر اليوم",
  "widgets.toDoList": "للقيام قوائم",
  "widgets.discoverPeople": "اكتشف الناس",
  "widgets.commments": "تعليقات",
  "widgets.newCustomers": "زبائن الجدد",
  "widgets.recentNotifications": "اشعارات حديثة",
  "widgets.appNotifications": "اشعارات التطبيق",
  "widgets.newEmails": "رسائل البريد الإلكتروني الجديدة",
  "widgets.siteVisitors": "زوار الموقع",
  "widgets.socialCompanines": "الشركات الاجتماعية",
  "widgets.recentActivities": "أنشطة حالية",
  "widgets.recentOrders": "الطلبيات الأخيرة",
  "widgets.ratings": "تصنيفات",
  "widgets.gallery": "صالة عرض",
  "widgets.pricing": "التسعير",
  "widgets.enterpriseEdition": "الطبعة المؤسسة",
  "widgets.personalEdition": "الطبعة الشخصية",
  "widgets.teamEdition": "إصدار الفريق",
  "widgets.standard": "اساسي",
  "widgets.advanced": "المتقدمة",
  "widgets.master": "رئيس",
  "widgets.Mega": "ميجا",
  "widgets.logIn": "تسجيل الدخول",
  "widgets.signUp": "سجل",
  "widgets.lockScreen": "اقفل الشاشة",
  "widgets.alertsWithLink": "تنبيهات مع وصلة",
  "widgets.additionalContent": "محتوى إضافي",
  "widgets.alertDismiss": "تنبيه رفض",
  "widgets.uncontrolledDisableAlerts": "غير المنضبط تعطيل التنبيهات",
  "widgets.contexualAlerts": "التنبيهات قريني",
  "widgets.alertsWithIcons": "التنبيهات مع الأيقونات",
  "widgets.Simple App Bars": "أشرطة التطبيق بسيطة",
  "widgets.appBarsWithButtons": "أشرطة التطبيق مع أزرار",
  "widgets.imageAvatars": "الصورة الرمزية",
  "widgets.lettersAvatars": "رسائل الآلهة",
  "widgets.iconsAvatars": "أيقونات الصور الرمزية",
  "widgets.flatButtons": "أزرار مسطحة",
  "widgets.raisedButton": "زر أثار",
  "widgets.buttonWithIconAndLabel": "زر مع أيقونة وعلامة",
  "widgets.floatingActionButtons": "أزرار العمل العائمة",
  "widgets.iconButton": "أيقونة زر",
  "widgets.socialMediaButton": "زر وسائل الاعلام الاجتماعية",
  "widgets.reactButton": "رد فعل زر",
  "widgets.buttonOutline": "مخطط الزر",
  "widgets.buttonSize": "حجم الزر",
  "widgets.buttonState": "حالة الزر",
  "widgets.buttonNavigationWithNoLabel": "زر التنقل بدون تسمية",
  "widgets.buttonNavigation": "زر الملاحة",
  "widgets.iconNavigation": "رمز الملاحة",
  "widgets.badgeWithHeadings": "شارة مع عناوين",
  "widgets.contexualVariations": "الاختلافات السياقية",
  "widgets.badgeLinks": "روابط الشارات",
  "widgets.materialBadge": "شارة المواد",
  "widgets.simpleCards": "بطاقات بسيطة",
  "widgets.backgroundVarient": "متغير الخلفية",
  "widgets.cardOutline": "مخطط البطاقة",
  "widgets.overlayCard": "مخطط البطاقة",
  "widgets.cardGroup": "مجموعة البطاقات",
  "widgets.cardTitle": "عنوان البطاقة",
  "widgets.speacialTitleTreatment": "عنوان خاص العلاج",
  "widgets.chipWithClickEvent": "رقاقة مع حدث الصحافة",
  "widgets.chipArray": "رقاقة المصفوفة",
  "widgets.dialogs": "الحوارات",
  "widgets.listDividers": "قائمة المقسمات",
  "widgets.insetDividers": "الفواصل الداخلية",
  "widgets.temporaryDrawers": "أدراج مؤقتة",
  "widgets.permanentDrawers": "أدراج دائمة",
  "widgets.simpleExpansionPanel": "لوحة توسيع بسيطة",
  "widgets.controlledAccordion": "الأكورديون تحت السيطرة",
  "widgets.secondaryHeadingAndColumns": "العنوان الثانوي والأعمدة",
  "widgets.imageOnlyGridLists": "صورة قوائم الشبكة فقط",
  "widgets.advancedGridLists": "قوائم الشبكة المتقدمة",
  "widgets.singleLineGridLists": "قوائم شبكة خط واحد",
  "widgets.simpleLists": "قوائم بسيطة",
  "widgets.folderLists": "قوائم المجلدات",
  "widgets.listItemWithImage": "عنصر القائمة مع صورة",
  "widgets.switchLists": "قوائم التبديل",
  "widgets.insetLists": "القوائم الداخلية",
  "widgets.nestedLists": "القوائم المتداخلة",
  "widgets.checkboxListControl": "التحكم في قائمة خانة الاختيار",
  "widgets.pinedSubHeader": "رأس فرعي موهون",
  "widgets.InteractiveLists": "القوائم التفاعلية",
  "widgets.simpleMenus": "القوائم البسيطة",
  "widgets.selectedMenu": "القائمة المختارة",
  "widgets.maxHeightMenu": "ماكس الطول القائمة",
  "widgets.changeTransition": "تغيير التحول",
  "widgets.paper": "ورقة",
  "widgets.anchorPlayGround": "انكور بلاى جراوند",
  "widgets.tooltip": "تلميح",
  "widgets.positionedToolTips": "وضعه سناكبار",
  "widgets.circularProgressBottomStart": "التعميم التقدم القاع البدء",
  "widgets.interactiveIntegration": "التكامل التفاعلي",
  "widgets.determinate": "حاسم",
  "widgets.linearProgressLineBar": "خط خط تقدم خطي",
  "widgets.indeterminate": "غير محدد",
  "widgets.buffer": "متعادل",
  "widgets.query": "سؤال",
  "widgets.transitionControlDirection": "اتجاه التحكم في الانتقال",
  "widgets.simpleSnackbar": "بسيط مطعم الوجبات الخفيفة",
  "widgets.positionedSnackbar": "وضعه سناكبار",
  "widgets.contexualColoredSnackbars": "قريني ملون وجبة خفيفة الحانات",
  "widgets.simpleCheckbox": "خانة اختيار بسيطة",
  "widgets.interminateSelection": "انتقاء العدائية",
  "widgets.disabledCheckbox": "مربع اختيار معطل",
  "widgets.customColorCheckbox": "خانة اختيار لون مخصص",
  "widgets.VerticalStyleCheckbox": "خانة اختيار نمط عمودي",
  "widgets.horizontalStyleCheckbox": "خانة اختيار نمط أفقي",
  "widgets.radioButtons": "أزرار الراديو",
  "widgets.disabledRadio": "راديو معطل",
  "widgets.withError": "مع خطأ",
  "widgets.switches": "و swiches",
  "widgets.dateAndTimePicker": "التاريخ والوقت المنتقى",
  "widgets.defaultPicker": "الافتراضي المنتقى",
  "widgets.timePicker": "منتقي الوقت",
  "widgets.weekPicker": "أسبوع المنتقى",
  "widgets.defaultDatePicker": "منتقي التاريخ الافتراضي",
  "widgets.customPicker": "العرف المنتقى",
  "widgets.tabs": "علامات التبويب",
  "widgets.fixedTabs": "علامات ثابتة",
  "widgets.basicTab": "علامة تبويب أساسية",
  "widgets.wrappedLabels": "تسميات ملفوفة",
  "widgets.centeredLabels": "تسميات مركزية",
  "widgets.forcedScrolledButtons": "أزرار التمرير القسري",
  "widgets.iconsTabs": "الأيقونات علامات التبويب",
  "widgets.withDisableTabs": "مع تعطيل علامات التبويب",
  "widgets.iconWithLabel": "أيقونة مع تسمية",
  "widgets.stepper": "السائر",
  "widgets.horizontalLinear": "أفقي خطي",
  "widgets.horizontalNonLinear": "أفقي غير خطي",
  "widgets.horizontalLinerAlternativeLabel": "بطانة أفقية بديلة التسمية",
  "widgets.horizontalNonLinerAlternativeLabel":
    "العلامة الأفقية غير اللا Liner Alternative Label",
  "widgets.verticalStepper": "السائر الرأسي",
  "widgets.descriptionAlert": "وصف تنبيه",
  "widgets.customIconAlert": "أيقونة مخصص التنبيه",
  "widgets.withHtmlAlert": "مع تنبيه Html",
  "widgets.promptAlert": "تنبيه عاجل",
  "widgets.passwordPromptAlert": "تنبيه تنبيه كلمة المرور",
  "widgets.customStyleAlert": "تنبيه مخصص ستايل",
  "widgets.autoComplete": "إكمال تلقائي",
  "widgets.reactSelect": "رد الفعل اختر",
  "widgets.downshiftAutoComplete": "Downshift السيارات كاملة",
  "widgets.reactAutoSuggests": "رد فعل السيارات يقترح",
  "widgets.aboutUs": "معلومات عنا",
  "widgets.ourVission": "رؤيتنا",
  "widgets.ourMissions": "مهماتنا",
  "widgets.ourMotivation": "الدافع لدينا",
  "widgets.defualtReactForm": "نموذج رد الفعل الافتراضي",
  "widgets.url": "رابط",
  "widgets.textArea": "منطقة النص",
  "widgets.file": "ملف",
  "widgets.formGrid": "شبكة النموذج",
  "widgets.inlineForm": "نموذج مضمن",
  "widgets.inputSizing": "حجم المدخلات",
  "widgets.inputGridSizing": "حجم الشبكة المدخلة",
  "widgets.hiddenLabels": "التسميات الخفية",
  "widgets.formValidation": "التحقق من صحة النموذج",
  "widgets.number": "رقم",
  "widgets.date": "تاريخ",
  "widgets.time": "زمن",
  "widgets.color": "اللون",
  "widgets.search": "بحث",
  "widgets.selectMultiple": "اختر متعددة",
  "widgets.inputWithSuccess": "مدخلات مع النجاح",
  "widgets.inputWithDanger": "المدخلات مع الخطر",
  "widgets.simpleTextField": "المدخلات مع الخطر...",
  "widgets.componet": "المكونات",
  "widgets.layouts": "تخطيطات",
  "widgets.inputAdorements": "ادور المدخلات",
  "widgets.formattedInputs": "المدخلات المنسقة",
  "widgets.simpleSelect": "اختيار بسيط",
  "widgets.nativeSelect": "اختيار الأصلي",
  "widgets.MutltiSelectList": "قائمة تحديد متعددة",
  "widgets.lineChart": "خط الرسم البياني",
  "widgets.barChart": "شريط الرسم البياني",
  "widgets.stackedBarChart": "مخطط شريط مكدس",
  "widgets.lineBarAreaChart": "خط منطقة الرسم البياني",
  "widgets.areaChart": "مخطط المساحة",
  "widgets.stackedAreaChart": "مخطط مساحي مكدس",
  "widgets.verticalChart": "مخطط عمودي",
  "widgets.radarChart": "مخطط الرادار",
  "widgets.doughnut": "تبرع",
  "widgets.polarChart": "الخريطة القطبية",
  "widgets.pieChart": "مخطط دائري",
  "widgets.bubbleChart": "مخطط فقاعة",
  "widgets.horizontalBar": "عارضة أفقية",
  "widgets.basicTable": "الجدول الأساسي",
  "widgets.contexualColoredTable": "قريني ملون الطاولة",
  "widgets.dataTable": "جدول البيانات",
  "widgets.employeeList": "قائمة موظف",
  "widgets.responsiveTable": "الجدول المستجيب",
  "widgets.responsiveFlipTable": "استجابة فليب الجدول",
  "widgets.reactGridControlledStateMode": "استجابة فليب الجدول...",
  "widgets.googleMaps": "خرائط جوجل",
  "widgets.productsReports": "تقارير المنتجات",
  "widgets.taskList": "قائمة المهام",
  "widgets.basicCalender": "التقويم الأساسي",
  "widgets.culturesCalender": "التقويم الثقافات",
  "widgets.dragAndDropCalender": "سحب وإسقاط التقويم",
  "widgets.selectableCalender": "اختيار التقويم",
  "widgets.customRendering": "التقديم المخصص",
  "widgets.customCalender": "التقويم المخصص",
  "widgets.searchMailList": "البحث في قائمة البريد",
  "components.buyNow": "اشتري الآن",
  "compenets.choose": "أختر",
  "compenets.username": "اسم المستخدم",
  "compenets.passwords": "كلمات السر",
  "widgets.forgetPassword": "نسيت كلمة المرور",
  "compenets.signIn": "تسجيل الدخول",
  "compenets.dontHaveAccountSignUp": "لا تملك حساب تسجيل الدخول",
  "compenets.enterUserName": "ادخل اسم المستخدم",
  "compenets.enterEmailAddress": "أدخل عنوان البريد الالكتروني",
  "compenets.confirmPasswords": "تأكيد كلمات المرور",
  "components.alreadyHavingAccountSignIn": "بالفعل لديك حساب تسجيل الدخول",
  "components.enterYourPassword": "ادخل رقمك السري",
  "components.unlock": "فتح",
  "components.enterPasswords": "أدخل كلمات المرور",
  "components.resetPassword": "إعادة ضبط كلمة المرور",
  "components.pageNotfound": "الصفحة غير موجودة",
  "components.goToHomePage": "الذهاب إلى الصفحة الرئيسية",
  "components.sorryServerGoesWrong": "آسف خادم يذهب خاطئة",
  "components.persistentDrawer": "درج دائم",
  "components.back": "الى الخلف",
  "components.next": "التالى",
  "components.completeStep": "خطوة كاملة",
  "components.withHtml": "مع هتمل",
  "components.prompt": "مستعجل",
  "components.withDescription": "مع الوصف",
  "components.success": "نجاح",
  "components.passwordPrompt": "كلمة المرور موجه",
  "components.warning": "تحذير",
  "components.customIcon": "أيقونة مخصصة",
  "components.customStyle": "نمط مخصص",
  "components.basic": "الأساسية",
  "components.submit": "خضع",
  "components.compose": "مؤلف موسيقى",
  "components.sendMessage": "ارسل رسالة",
  "components.addNewTasks": "إضافة مهام جديدة",
  "components.addToCart": "أضف إلى السلة",
  "components.payNow": "ادفع الآن",
  "components.print": "طباعة",
  "components.cart": "عربة التسوق",
  "components.viewCart": "عرض العربة",
  "components.checkout": "الدفع",
  "widgets.QuickLinks": "روابط سريعة",
  "widgets.upgradeToPro": "تطوير",
  "widgets.upgrade": "تطوير",
  "widgets.app": "التطبيق",
  "widgets.addNew": "اضف جديد",
  "widgets.orderDate": "تاريخ الطلب",
  "widgets.status": "الحالة",
  "widgets.trackingNumber": "أرقام التتبع",
  "widgets.action": "عمل",
  "widgets.designation": "تعيين",
  "widgets.subject": "موضوع",
  "widgets.send": "إرسال",
  "widgets.saveAsDrafts": "حفظ كمسودات",
  "widgets.onlineSources": "المصادر عبر الإنترنت",
  "widgets.lastMonth": "الشهر الماضي",
  "widgets.widgets": "الحاجيات",
  "widgets.listing": "قائمة",
  "widgets.paid": "دفع",
  "widgets.refunded": "ردها",
  "widgets.done": "فعله",
  "widgets.pending": "قيد الانتظار",
  "widgets.cancelled": "ألغيت",
  "widgets.approve": "يوافق",
  "widgets.following": "التالية",
  "widgets.follow": "إتبع",
  "widgets.graphs&Charts": "الرسوم البيانية والرسوم البيانية",
  "widgets.open": "افتح",
  "widgets.bounced": "وثب",
  "widgets.spam": "بريد مؤذي",
  "widgets.unset": "غير مصلد",
  "widgets.bandwidthUse": "استخدام النطاق الترددي",
  "widgets.dataUse": "استخدام البيانات",
  "widgets.unsubscribe": "إلغاء الاشتراك",
  "widgets.profile": "الملف الشخصي",
  "widgets.messages": "رسائل",
  "widgets.support": "الدعم",
  "widgets.faq(s)": "أسئلة وأجوبة (ق)",
  "widgets.upgradePlains": "ترقية السهول",
  "widgets.logOut": "الخروج",
  "widgets.mail": "بريد",
  "widgets.adminTheme": "موضوع المسؤول",
  "widgets.wordpressTheme": "وورد الموضوع",
  "widgets.addToCart": "أضف إلى السلة",
  "widgets.plan": "خطة",
  "widgets.basic": "الأساسية",
  "widgets.pro": "طليعة",
  "widgets.startToBasic": "البدء في الأساسي",
  "widgets.upgradeToAdvance": "الترقية إلى الأمام",
  "widgets.comparePlans": "قارن الخطط",
  "widgets.free": "حر",
  "widgets.frequentlyAskedQuestions": "أسئلة مكررة",
  "widgets.searchIdeas": "البحث عن الأفكار",
  "widgets.startDate": "تاريخ البدء",
  "widgets.endDate": "تاريخ الانتهاء",
  "widgets.category": "الفئة",
  "widgets.apply": "تطبيق",
  "widgets.downloadPdfReport": "تنزيل تقرير Pdf",
  "widgets.yesterday": "في الامس",
  "widgets.totalOrders": "مجموع الطلبيات",
  "widgets.totalVisitors": "مجموع الزوار",
  "widgets.typeYourQuestions": "اكتب أسئلتك",
  "widgets.username": "اسم المستخدم",
  "widgets.password": "كلمه السر",
  "widgets.signIn": "تسجيل الدخول",
  "widgets.enterYourPassword": "ادخل رقمك السري",
  "widgets.alreadyHavingAccountLogin": "بالفعل لديك حساب تسجيل الدخول",
  "widgets.composeMail": "كتابة رسالة",
  "widgets.issue": "القضية",
  "widgets.recentChat": "الدردشة الأخيرة",
  "widgets.previousChat": "الدردشة السابقة",
  "widgets.all": "الكل",
  "widgets.filters": "مرشحات",
  "widgets.deleted": "تم الحذف",
  "widgets.starred": "تألق",
  "widgets.frontend": "نهاية المقدمة",
  "widgets.backend": "الخلفية",
  "widgets.api": "المعهد",
  "widgets.simpleAppBar": "شريط التطبيقات بسيط",
  "widgets.recents": "حديثي",
  "widgets.cardLink": "رابط البطاقة",
  "widgets.anotherLink": "رابط آخر",
  "widgets.cardSubtitle": "عنوان فرعي للبطاقة",
  "widgets.confirmationDialogs": "حوارات التأكيد",
  "widgets.deletableChip": "رقاقة محذوفة",
  "widgets.customDeleteIconChip": "مخصص حذف رمز رقاقة",
  "widgets.openAlertDialog": "فتح حوار التنبيه",
  "widgets.openResponsiveDialog": "افتح مربع حوار متجاوب",
  "widgets.openSimpleDialog": "افتح مربع حوار بسيط",
  "widgets.openFormDialog": "افتح مربع حوار النموذج",
  "widgets.follower": "تابع",
  "widgets.important": "مهم",
  "widgets.private": "نشر",
  "widgets.openLeft": "افتح اليسار",
  "widgets.openRight": "افتح الحق",
  "widgets.openTop": "مكشوفة",
  "widgets.openBottom": "فتح القاع",
  "widgets.selectTripDestination": "اختر وجهة الرحلة",
  "widgets.pinnedSubHeaderList": "مثبت رأس القائمة الفرعية",
  "widgets.singleLineItem": "بند مفرد",
  "widgets.acceptTerms": "قبول الشروط",
  "widgets.optionA": "الخيار أ",
  "widgets.optionB": "الخيار ب",
  "widgets.optionC": "الخيار جيم",
  "widgets.optionM": "الخيار م",
  "widgets.optionN": "الخيار ن",
  "widgets.optionO": "الخيار O",
  "widgets.customColor": "لون مخصص",
  "widgets.centeredTabs": "علامات التبويب توسيط",
  "widgets.multipleTabs": "علامات تبويب متعددة",
  "widgets.preventScrolledButtons": "منع أزرار التمرير",
  "widgets.browse": "تصفح",
  "widgets.formValidate": "نموذج التحقق من الصحة",
  "widgets.code": "الشفرة",
  "widgets.company": "شركة",
  "widgets.price": "السعر",
  "widgets.change": "يتغيرون",
  "widgets.high": "متوسط",
  "widgets.low": "منخفض",
  "widgets.volume": "الصوت",
  "widgets.personalDetails": "تفاصيل شخصية",
  "widgets.occupation": "الاحتلال",
  "widgets.companyName": "اسم الشركة",
  "widgets.phoneNo": "رقم الهاتف",
  "widgets.city": "مدينة",
  "widgets.zipCode": "الرمز البريدي",
  "widgets.updateProfile": "تحديث الملف",
  "widgets.reject": "رفض",
  "widgets.exportToExcel": "تصدير إلى Excel",
  "widgets.addNewUser": "إضافة مستخدم جديد",
  "widgets.workWeek": "أسبوع العمل",
  "widgets.agenda": "جدول أعمال",
  "widgets.conference": "مؤتمر",
  "widgets.dailySales": "المبيعات اليومية",
  "widgets.today": "اليوم",
  "widgets.campaignPerformance": "أداء الحملة",
  "widgets.supportRequest": "طلب دعم",
  "widgets.usersList": "قائمة المستخدمين",
  "widgets.lastWeek": "الاسبوع الماضى",
  "themeOptions.sidebarOverlay": "تراكب الشريط الجانبي",
  "themeOptions.sidebarBackgroundImages": "صور خلفية الشريط الجانبي",
  "themeOptions.sidebarImage": "صورة الشريط الجانبي",
  "themeOptions.miniSidebar": "ميني الشريط الجانبي",
  "themeOptions.boxLayout": "تخطيط مربع",
  "themeOptions.rtlLayout": "تخطيط Rtl",
  "themeOptions.darkMode": "وضع الظلام",
  "themeOptions.appSettings": "إعدادات التطبيقات",
  "themeOptions.sidebarLight": "ضوء",
  "themeOptions.sidebarDark": "داكن",
  "button.cancel": "إلغاء",
  "button.add": "إضافة",
  "button.update": "تحديث",
  "button.reply": "الرد",
  "button.delete": "حذف",
  "button.yes": "نعم فعلا",
  "button.viewAll": "عرض الكل",
  "button.like": "مثل",
  "button.assignNow": "تعيين الآن",
  "button.seeInsights": "اطلع على الإحصاءات",
  "sidebar.dateTimePicker": "التاريخ والوقت المنتقى",
  "components.summary": "ملخص",
  "hint.whatAreYouLookingFor": "ما الذي تبحث عنه",
  "components.yesterday": "بالأمس",
  "components.last7Days": "آخر 7 أيام",
  "components.last1Month": "آخر 1 شهر",
  "components.last6Month": "آخر 6 أشهر",
  "components.spaceUsed": "الفضاء المستخدمة",
  "components.followers": "أتباع",
  "components.trending": "الإمالة",
  "components.paid": "مدفوعة",
  "components.refunded": "المردودة",
  "components.done": "تم",
  "components.pending": "معلق",
  "components.cancelled": "ألغي",
  "components.approve": "الموافقة",
  "components.week": "أسبوع",
  "components.month": "شهر",
  "components.year": "السنة",
  "components.today": "اليوم",
  "components.popularity": "الشعبية",
  "components.email": "البريد الإلكتروني",
  "components.drafts": "",
  "components.sent": "المرسلة",
  "components.trash": "المهملات",
  "components.all": "الكل",
  "components.do": "هل",
  "components.title": "العنوان",
  "components.projectName": "اسم المشروع",
  "components.companyName": "اسم الشركة",
  "components.openAlert": "فتح تنبيه",
  "components.slideInAlertDialog": "الانزلاق في حوارات التنبيه",
  "components.openFullScreenDialog": "فتح مربعات شاشة كاملة",
  "components.basicChip": "رقاقة الأساسية",
  "components.clickableChip": "",
  "components.left": "اليسار",
  "components.right": "الحق",
  "components.expansionPanel1": "لوحة التوسعة 1",
  "components.expansionPanel2": "رقاقة قابلة للنقر",
  "components.generalSetting": "الإعداد العام",
  "components.advancedSettings": "إعدادات متقدمة",
  "components.firstName": "الاسم الأول",
  "components.lastName": "اسم العائلة",
  "components.occupation": "العمل",
  "components.phoneNo": "رقم الهاتف",
  "components.address": "عنوان",
  "components.city": "المدينة",
  "components.state": "الدولة",
  "components.zip Code": "الرمز البريدي",
  "component.social Connection": "التواصل الاجتماعي",
  "widgets.buyMore": "شراء المزيد",
  "widgets.trafficChannel": "قناة المرور",
  "widgets.stockExchange": "تداول الاسهم",
  "widgets.tweets": "تغريدات",
  "widgets.ourLocations": "مواقعنا",
  "widgets.sales": "المبيعات",
  "widgets.to": "إلى",
  "widgets.ship إلى": "شحن إلى",
  "widgets.description": "الوصف",
  "widgets.unitPrice": "سعر الوحدة",
  "widgets.total": "توتال",
  "widgets.note": "ملاحظة",
  "widgets.chipWithAvatar": "رقاقة مع الصورة الرمزية",
  "widgets.chipWithTextAvatar": "رقاقة مع النص الصورة الرمزية",
  "widgets.chipWithIconAvatar": "رقاقة مع رمز الصورة الرمزية",
  "widgets.customClickableChip": "شريحة قابلة للنقر مخصصة",
  "widgets.outlineChip": "مخطط تفصيلي",
  "widgets.disableChip": "تعطيل الشريحة",
  "widgets.alertDialog": "تنبيه الحوار",
  "widgets.animatedSlideDialogs": "الرسوم المتحركة الشرائح المتحركة",
  "widgets.fullScreenDialogs": "مربعات الشاشة الكاملة",
  "widgets.formDialogs": "شكل حوار",
  "widgets.simpleDialogs": " حوار بسيط",
  "widgets.responsiveFullScreen": "مستجيب ملء الشاشة",
  "widgets.primary": "الابتدائية",
  "widgets.social": "الاجتماعية",
  "widgets.user": "المستخدم",
  "widgets.admin": "المسؤول",
  "widgets.permanentdrawer": "درج دائم",
  "widgets.persistentdrawer": "درج دائم",
  "widgets.swiches": "و swiches",
  "widgets.horizontalLinearAlternativeLabel": "تسمية بديلة خطية أفقية",
  "widgets.horizontalNonLinearAlternativeLabel":
    "الأفقي البديل غير الخطي تسمية",
  "widgets.notifications": "الإشعارات",
  "widgets.basicAlert": "التنبيه الأساسي",
  "widgets.successAlert": "تنبيه النجاح",
  "widgets.warningAlert": "تنبيه تحذير",
  "widgets.reactAutoSuggest": "رد السيارات اقتراح",
  "widgets.components": "مكونات",
  "widgets.inputAdornments": "المدخلات الزينة",
  "widgets.multiSelectList": "قائمة تحديد متعددة",
  "widgets.contextualColoredTable": "",
  "widgets.updateYourEmailAddress": "تحديث عنوان بريدك الإلكتروني",
  "widgets.selectADefaultAddress": "تحديد عنوان افتراضي",
  "widgets.activity": "آخر",
  "widgets.basicCalendar": "التقويم الأساسي",
  "widgets.culturesCalendar": "التقويم الثقافات",
  "widgets.dragAndDropCalendar": "سحب إسقاط النشر",
  "widgets.quillEditor": "محرر الريشة",
  "widgets.reactDND": "رد Dnd",
  "widgets.dragula": "dragula",
  "button.acceptTerms": "قبول الشروط",
  "button.reject": "رفض",
  "button.addNew": "اضف جديد",
  "button.goToCampaign": "الذهاب الى الحملة",
  "button.viewProfile": "عرض الملف الشخصي",
  "button.sendMessage": "إرسال رسالة",
  "button.saveNow": "احفظ الان",
  "button.pen": "القلم",
  "button.search": "بحث",
  "button.downloadPdfReport": "تنزيل تقرير PDF",
  "button.primary": "الابتدائية",
  "button.secondary": "الثانوية",
  "button.danger": "خطر",
  "button.info": "معلومات",
  "button.success": "النجاح",
  "button.warning": "تحذير",
  "button.link": "لينك",
  "button.smallButton": "زر صغير",
  "button.largeButton": "زر كبير",
  "button.blockLevelButton": "زر مستوى الكتلة",
  "button.primaryButton": "الزر الرئيسي",
  "button.button": "زر",
  "button.save": "حفظ",
  "button.openMenu": "فتح القائمة",
  "button.openWithFadeTransition": "مفتوحة مع التحول تتلاشى",
  "button.openPopover": "فتح بوبوفر",
  "button.accept": "قبول",
  "button.click": "انقر فوق",
  "button.complete": "كاملة",
  "button.back": "Back",
  "button.next": "التالي",
  "button.completeStep": "إكمال الخطوة",
  "button.error": "خطأ",
  "button.writeNewMessage": "كتابة رسالة جديدة",
  "button.saveChanges": "حفظ التغييرات",
  "button.addNewUser": "إضافة مستخدم جديد",
  "button.more": "المزيد",
  "hint.searchMailList": "البحث في قائمة البريد",
  "widgets.AcceptorrRejectWithin": "قبول أو رفض في الداخل",
  "widgets.quoteOfTheDay": "اقتبس من اليوم",
  "widgets.updated10Minago": "تم التحديث قبل 10 دقيقة",
  "widgets.personalSchedule": "جدول شخصي",
  "widgets.activeUsers": "المستخدمين النشطين",
  "widgets.totalRequest": "مجموع الطلب",
  "widgets.new": "الجديد",
  "widgets.ShareWithFriends": "شارك مع الاصدقاء!",
  "widgets.helpToShareText":
    "ساعدنا في نشر العالم من خلال مشاركة موقع الويب الخاص بنا مع أصدقائك ومتابعيك على وسائل التواصل الاجتماعي!",
  "widgets.thisWeek": "هذا الاسبوع",
  "widgets.howWouldYouRateUs": "كيف تقيم لنا؟",
  "widgets.booking": "الحجز",
  "widgets.confirmed": "تم تأكيد",
  "widgets.monthly": "شهريا",
  "widgets.weekly": "أسبوعيا",
  "widgets.target": "الهدف",
  "widgets.totalActiveUsers": "مجموع المستخدمين النشطين",
  "sidebar.user": "المستخدم",
  "sidebar.miscellaneous": "متنوع",
  "sidebar.promo": "الترويجي",
  "themeOptions.themeColor": "لون الموضوع",
  "module.inbox": "صندوق الوارد",
  "module.drafts": "",
  "module.sent": "المرسلة",
  "module.trash": "المهملات",
  "module.spam": "بريد مؤذي",
  "module.frontend": "نهاية المقدمة",
  "module.backend": "الخلفية",
  "module.api": "المعهد",
  "module.issue": "القضية",
  "components.emailPrefrences": "تفضيلات البريد الإلكتروني",
  "components.myProfile": "ملفي",
  "sidebar.gettingStarted": "ابدء",
  "widgets.deadline": "الموعد النهائي",
  "widgets.team": "الفريق",
  "widgets.projectManagement": "ادارة مشروع",
  "widgets.latestPost": "آخر وظيفة",
  "widgets.projectTaskManagement": "إدارة مهام المشروع",
  "widgets.selectProject": "حدد المشروع",
  "widgets.activityBoard": "مجلس النشاط",
  "widgets.checklist": "قائمة تدقيق",
  "sidebar.shop": "متجر",
  "sidebar.cart": "عربة التسوق",
  "sidebar.checkout": "الدفع",
  "components.product": "المنتج",
  "components.quantity": "كمية",
  "components.totalPrice": "السعر الكلي",
  "components.removeProduct": "إزالة المنتج",
  "components.mobileNumber": "رقم الهاتف المحمول",
  "components.address2Optional": "العنوان 2 (اختياري)",
  "components.country": "بلد",
  "components.zip": "الرمز البريدي",
  "components.saveContinue": "حفظ ومتابعة",
  "components.placeOrder": "مكان الامر",
  "components.payment": "دفع",
  "components.billingAddress": "عنوان وصول الفواتير",
  "components.ShippingAddressText": "عنوان الشحن هو نفسه عنوان الفواتير.",
  "components.CartEmptyText": "سلة التسوق الخاصة بك فارغة!",
  "components.NoItemFound": "لا يوجد بند",
  "components.goToShop": "الذهاب للتسوق",
  "components.cardNumber": "رقم البطاقة",
  "components.expiryDate": "تاريخ الانتهاء",
  "components.cvv": "CVV",
  "components.nameOnCard": "الاسم على البطاقة",
  "components.confirmPayment": "تأكيد الدفع",
  "sidebar.saas": "أمانة الشؤون الإدارية",
  "sidebar.multiLevel": "متعدد المستويات",
  "sidebar.level1": "المستوى 1",
  "sidebar.level2": "المستوي 2",
  "sidebar.boxed": "محاصر",
  "sidebar.extensions": "ملحقات",
  "sidebar.imageCropper": "صورة كروبر",
  "sidebar.videoPlayer": "مشغل فديوهات",
  "sidebar.dropzone": "Dropzone",
  "widgets.baseConfig": "التكوين الأساسي",
  "widgets.customControlBar": "شريط التحكم المخصص",
  "widgets.withDownloadButton": "مع زر التحميل",
  "widgets.httpLiveStreaming": "HTTP بث مباشر",
  "widgets.keyboardShortcuts": "اختصارات لوحة المفاتيح",
  "button.useDefaultImage": "استخدام الصورة الافتراضية",
  "button.cropImage": "قص الصوره",
  "widgets.preview": "معاينة",
  "widgets.croppedImage": "صورة مقصوصة",
  "sidebar.crm": "CRM",
  "widgets.transactionList": "قائمة المعاملات",
  "widgets.transferReport": "نقل تقرير",
  "widgets.expenseCategory": "فئة حساب",
  "widgets.upcomingEvents": "الأحداث القادمة",
  "widgets.OngoingProjects": "مشاريع جارية",
  "widgets.ProjectStatus": "حالة المشروع",
  "widgets.LiveChatSupport": "دعم الدردشة الحية",
  "sidebar.projects": "مشاريع",
  "sidebar.projectDetail": "تفاصيل المشروع",
  "sidebar.clients": "عملاء",
  "sidebar.reports": "تقارير",
};
