import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { FormGroup as RsFormGroup, InputGroup } from "reactstrap";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Feedback as FormFeedback,
} from "@availity/form";
import * as yup from "yup";
import _ from "underscore";
import { NotificationManager } from "react-notifications";
import LinearProgress from "@material-ui/core/LinearProgress";
import { database, storage, db } from "../../Firebase/index";
import "firebase/storage";
import { Grid } from "@material-ui/core";
import "./style.scss";
import { extension } from "mime-types";

function AddCategoryForm(props) {
  const [loading, setLoading] = useState(false);
  /*const [image, setImage] = useState(null);*/
  const [initialValues] = useState({
    title: "",
    // bckcolor: "",
    // description: "",
  });

  const validationSchema = yup.object({
    title: yup.string().label("Category Name").required().min(3),
    // bckcolor: yup.string().label("Category Back Color Hash").required(),
    // description: yup.string().label("Category Description").required().min(3),
  });

  const onAddCategoryFormSubmit = async (values) => {
    setLoading(true);
    let formData = {
      title: values["title"] ?? "",
      // bckcolor: values["bckcolor"] ?? "",
      // description: values["description"] ?? "",
    };

    /*if (!_.isBlank(formData["title"]) && image) {*/
    if (!_.isBlank(formData["title"])) {
      // let newCategoryKey = database.ref("category").push().key;

      /*if (image) {
        const storageRef = storage.ref(`/category/${newCategoryKey}/logo.${extension(image.type)}`);
        await storageRef.put(image);
        formData["imageContentType"] = image.type;
        formData["imageUrl"] = await storageRef.getDownloadURL();
      }*/
      const ref = db.collection("category").doc();
      const id = ref.id;
      await db
        .collection(`category`)
        .doc(id)
        .set({
          ...formData,
          status: "active",
          id,
        });

      setLoading(false);
      props.toggleAddCategoryModal(false);
      NotificationManager.success("Category Created Successfully!");
    } else {
      NotificationManager.error("Please Fill Required Fields");
    }
    setLoading(false);
  };

  /*const handleImageChange = (event) => {
    const image = event.target.files[0];
    const MAX_SIZE = 2 * 1024 * 1024;
    const errorLabel = document.querySelector(`.category-image-error-label`);
    const uploadContainer = document.querySelector(`.category-image-upload-container`);
    uploadContainer.classList.remove("uploaded");

    if (!image) return;

    errorLabel.textContent = "";
    if (image?.size > MAX_SIZE) return errorLabel.textContent = "Image size should be lower than 2MB.";

    setImage(image);
    const url = window && window.URL.createObjectURL(image);
    uploadContainer.style.backgroundImage = `url(${url})`;
    uploadContainer.classList.add("uploaded");
  };*/

  const handleValidation = () => {
    /*if (!image) {
      document.querySelector(".category-image-error-label").textContent = "Please add category image";
    }*/
  };

  return (
    <div>
      {loading && <LinearProgress />}
      <Form
        className=""
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onAddCategoryFormSubmit}
      >
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <FormGroup className="has-wrapper text-left" for="title">
              <Label for="title" required>
                Category Name
              </Label>
              <InputGroup>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  className="has-input input-lg"
                  placeholder="Category Name"
                  feedback={true}
                />
                <FormFeedback name="title" />
              </InputGroup>
            </FormGroup>
          </Grid>
          {/*<Grid item md={12} sm={12} xs={12}>
            <Label required for={"logo"}>Category Image</Label>
            <div className={"category-image-upload-container"}>
              <InputGroup className="has-wrapper justify-content-center align-content-center">
                <Label for="logo" className={"text-center"} style={{ cursor: "pointer", fontWeight: 500 }}>
                  <h3>Upload category image <i className="icon-cloud-upload" /></h3>
                </Label>
                <Input
                  type="file"
                  name="logo"
                  accept={"image/*"}
                  id="logo"
                  style={{ display: "none" }}
                  className="has-input input-lg"
                  feedback={true}
                  onChange={handleImageChange}
                />
              </InputGroup>
            </div>
            <small style={{ color: "red" }} className={"category-image-error-label"} />
          </Grid>*/}
        </Grid>

        {/*<FormGroup className="has-wrapper text-left" for="description">*/}
        {/*  <Label for="description" required>*/}
        {/*    Category Description*/}
        {/*  </Label>*/}
        {/*  <InputGroup>*/}
        {/*    <Input*/}
        {/*      type="text"*/}
        {/*      name="description"*/}
        {/*      id="description"*/}
        {/*      className="has-input input-lg"*/}
        {/*      placeholder="Category Description"*/}
        {/*      feedback={true}*/}
        {/*    />*/}
        {/*    <FormFeedback name="description" />*/}
        {/*  </InputGroup>*/}
        {/*  <span className="has-icon top-45">*/}
        {/*    <i className="ti-id-badge" />*/}
        {/*  </span>*/}
        {/*</FormGroup>*/}
        {/*<FormGroup className="has-wrapper text-left" for="bckcolor">*/}
        {/*  <Label for="bckcolor" required>*/}
        {/*    Category Back Color Hash*/}
        {/*  </Label>*/}
        {/*  <InputGroup>*/}
        {/*    <Input*/}
        {/*      type="text"*/}
        {/*      name="bckcolor"*/}
        {/*      id="bckcolor"*/}
        {/*      className="has-input input-lg"*/}
        {/*      placeholder="#FFFFFF"*/}
        {/*      feedback={true}*/}
        {/*    />*/}
        {/*    <FormFeedback name="bckcolor" />*/}
        {/*  </InputGroup>*/}
        {/*  <span className="has-icon top-45">*/}
        {/*    <i className="ti-id-badge" />*/}
        {/*  </span>*/}
        {/*</FormGroup>*/}
        <RsFormGroup className="mb-15 mt-4">
          <Button
            onClick={handleValidation}
            className="btn-dark text-white btn-block w-100"
            variant="contained"
            size="large"
            type={"submit"}
          >
            Create
          </Button>
        </RsFormGroup>
      </Form>
    </div>
  );
}

export default AddCategoryForm;
