import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper, Circle } from "google-maps-react";
import Geocode from "react-geocode";
import { getReactAppGoogleMapAPIKey } from "../../Helpers/helpers";
const OPTIONS = {
  minZoom: 10,
  maxZoom: 18,
};

export class MapContainer extends Component {
  state = {
    range: this.props.range,
    lat: this.props.latitude,
    lng: this.props.longitude,
    markers: [
      {
        name: "Current position",
        position: {
          lat: this.props.latitude,
          lng: this.props.longitude,
        },
      },
    ],
  };

  componentDidMount() {
    Geocode.setApiKey(getReactAppGoogleMapAPIKey());
    this.getAddressFromLetLng(this.state.lat, this.state.lng);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.range !== this.props.range) {
      this.setState({ range: this.props.range });
    }
  }

  onMarkerClick = () => {};
  onInfoWindowClose = () => {};

  onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState((prevState) => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      this.getAddressFromLetLng(lat, lng);
      return { markers };
    });
    this.setState({ lat, lng });
  };

  getAddressFromLetLng = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.props.getAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  render() {
    const { lat, lng, markers } = this.state;
    return (
      <Map
        google={this.props.google}
        gestureHandling="none"
        initialCenter={{
          lat: lat || "33.6844",
          lng: lng || "73.0479",
        }}
        zoom={11.5}
      >
        {this.state.markers.map((marker, index) => (
          <Marker
            key={index}
            onClick={this.onMarkerClick}
            name={"Current location"}
            draggable={false}
            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
            position={marker.position}
          />
        ))}
        {lat && lng && (
          <Circle
            center={{
              lat: lat || "33.6844",
              lng: lng || "73.0479",
            }}
            radius={this.state.range}
            strokeColor="#FF0000"
            strokeWeight={2}
            fillColor="#FF0000"
          />
        )}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: getReactAppGoogleMapAPIKey(),
})(MapContainer);
