import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormGroup as RsFormGroup, InputGroup } from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import QueueAnim from "rc-queue-anim";
import AppConfig from "Constants/AppConfig";
import { signinUserInFirebase } from "Store/Actions";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Feedback as FormFeedback,
} from "@availity/form";
import * as yup from "yup";
import _ from "underscore";
import { NotificationManager } from "react-notifications";

function Signin(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [initialValues] = useState({
    email: "",
    password: "",
  });
  const [open, setOpen] = React.useState(false);

  const validationSchema = yup.object({
    email: yup.string().label("Email").required().email(),
    password: yup.string().label("Password").required(),
  });

  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };

  const onUserLogin = async (values) => {
    try {
      setLoading(true);
      let formData = {
        email: values["email"] ?? "",
        password: values["password"] ?? "",
      };

      if (!_.isBlank(formData["email"]) && !_.isBlank(formData["password"])) {
        await dispatch(
          signinUserInFirebase(formData, props.history, setLoading, setMessage)
        );
      } else {
        setLoading(false);
        NotificationManager.error("Please Fill Required Fields");
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error(error.message);
    }
  };

  const onUserSignUp = () => {
    props.history.push("/auth/signup");
  };

  const subdomain = window.location.host.split(".")[0];

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  return (
    <QueueAnim type="bottom" duration={2000}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        style={{ minHeight: "100vh" }}
        className=""
      >
        <Grid item xs={11} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item sm={4} xs={12} align="center">
                  <img
                    src={AppConfig.appLogo}
                    alt="session-logo"
                    className="img-fluid"
                    width="110"
                    height="35"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{ color: "#363636" }}
                  >
                    Sign Up
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="theme-line-breaker" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} md={4} align="center">
          <Form
            style={{ width: "90%" }}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onUserLogin}
          >
            <div
            // className={" mb-4"}
            // style={{
            //   width: "96.8%",
            //   position: "absolute",
            //   left: 12,
            //   top: 84,
            // }}
            />
            <FormGroup
              className="has-wrapper text-left"
              for="email"
              // style={{ marginTop: "4rem" }}
            >
              <Label for="email">Email Address</Label>
              <InputGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  className="has-input input-lg"
                  placeholder="Enter Email Address"
                  feedback={true}
                />
                <FormFeedback name="email" />
              </InputGroup>
              <span className="has-icon top-45">
                <i className="ti-email" />
              </span>
            </FormGroup>
            <FormGroup className="has-wrapper text-left" for="password">
              <Label for="password">Password</Label>
              <InputGroup>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  className="has-input input-lg"
                  placeholder="Password"
                  feedback={true}
                />
                <FormFeedback name="password" />
              </InputGroup>
              <span className="has-icon top-45">
                <i className="ti-lock" />
              </span>
            </FormGroup>
            <RsFormGroup className="mb-15">
              <Button
                className="btn-block text-white mt-2 w-50 bg-primary"
                variant="contained"
                size="large"
                /*onClick={onUserLogin}*/
                type={"submit"}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color={"white"} size={25} />
                ) : (
                  "Sign In"
                )}
              </Button>
            </RsFormGroup>

            <div
              style={{
                display: "flex",
                marginTop: "1.5rem",
                gap: "0.7rem",
                flexDirection: "column",
              }}
            >
              {subdomain === "admin" ? null : (
                <span>
                  Don't have account.{" "}
                  <Link
                    to={"signup"}
                    className={"w-100 font-weight-bold text-info"}
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      display: "inline",
                    }}
                  >
                    Sign up
                  </Link>
                </span>
              )}
              <span>
                <Link
                  to={"/auth/forgot-password"}
                  className={"w-100 font-weight-bold text-info"}
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    display: "inline",
                  }}
                >
                  Forgot Password
                </Link>
              </span>
            </div>
          </Form>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {message ===
          "Thank you for signing up for Goldfinch. You will receive an email soon regarding your approval."
            ? "Pending For Approval"
            : "User Login"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </QueueAnim>
  );
}

export default Signin;
