import React from "react";
import Lottie from "react-lottie";
import animationData from "./lotties/goldfinch loader.json";
import Loader_Icon from "../src/Assets/img/Appicon.png"
// import animationData from "./lotties/Appicobn";

export default function GoldFinchLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "canvas", // svg/canvas/html, default is svg
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: "#ffffff",
      }}
    >
      {/* <Lottie options={defaultOptions} height={150} width={150} speed={1} /> */}
      <img src={Loader_Icon} alt="Site is loading" style={{height:'300px',width:"300px"}}></img>
    </div>
  );
}
