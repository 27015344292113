module.exports = {
  "sidebar.app": "App",
  "sidebar.horizontal": "Vízszintes",
  "sidebar.horizontalMenu": "vízszintes menü",
  "sidebar.general": "Tábornok",
  "sidebar.component": "Összetevő",
  "sidebar.features": "Jellemzők",
  "sidebar.applications": "Alkalmazások",
  "sidebar.dashboard": "Irányítópult",
  "sidebar.dashboard1": "Irányítópult 1",
  "sidebar.dashboard2": "Irányítópult 2",
  "sidebar.dashboard3": "Műszerfal 3",
  "sidebar.modules": "modulok",
  "sidebar.agency": "Ügynökség",
  "sidebar.pages": "Oldalak",
  "sidebar.gallery": "Képtár",
  "sidebar.pricing": "Árazás",
  "sidebar.terms&Conditions": "Felhasználási feltételek",
  "sidebar.feedback": "Visszacsatolás",
  "sidebar.report": "Jelentés",
  "sidebar.faq(s)": "FAQ (s)",
  "sidebar.advancedComponent": "Előzetes komponens",
  "sidebar.blank": "Üres",
  "sidebar.session": "Ülés",
  "sidebar.login": "Belépés",
  "sidebar.register": "Regisztráció",
  "sidebar.lockScreen": "Zárolás képernyő",
  "sidebar.forgotPassword": "Elfelejtett jelszó",
  "sidebar.404": "404",
  "sidebar.500": "500",
  "sidebar.uiComponents": "UI összetevők",
  "sidebar.alerts": "figyelmeztetések",
  "sidebar.appBar": "App Bar",
  "sidebar.avatars": "karakterekkel",
  "sidebar.buttons": "gombok",
  "sidebar.bottomNavigations": "Alsó navigáció",
  "sidebar.badges": "Kitűzők",
  "sidebar.cards": "kártyák",
  "sidebar.cardsMasonry": "Kártyák Masonary",
  "sidebar.chip": "csip",
  "sidebar.dialog": "Párbeszéd",
  "sidebar.dividers": "mérőkörző",
  "sidebar.drawers": "fiókok",
  "sidebar.popover": "Popover",
  "sidebar.expansionPanel": "Bővítőpanel",
  "sidebar.gridList": "Rácslista",
  "sidebar.list": "Lista",
  "sidebar.menu": "Menü",
  "sidebar.popoverAndToolTip": "Pop Over & ToolTip",
  "sidebar.progress": "Előrehalad",
  "sidebar.snackbar": "snackbár",
  "sidebar.selectionControls": "Kiválasztás vezérlők",
  "sidebar.advanceUiComponents": "Advance UI Components",
  "sidebar.dateAndTimePicker": "Dátum és időválasztó",
  "sidebar.tabs": "Lapok",
  "sidebar.stepper": "Stepper",
  "sidebar.notification": "Bejelentés",
  "sidebar.sweetAlert": "Édes figyelmeztetés",
  "sidebar.autoComplete": "Auto Complete",
  "sidebar.aboutUs": "Rólunk",
  "sidebar.widgets": "Widgets",
  "sidebar.forms": "Forms",
  "sidebar.formElements": "Form Elemek",
  "sidebar.textField": "Szövegmező",
  "sidebar.selectList": "Válasszon listát",
  "sidebar.charts": "táblázatok",
  "sidebar.reCharts": "Re diagramok",
  "sidebar.reactChartjs2": "Reagálja a Chartákat 2",
  "sidebar.icons": "Ikonok",
  "sidebar.themifyIcons": "Themify ikonok",
  "sidebar.simpleLineIcons": "Egyszerű vonalikonok",
  "sidebar.materialIcons": "Anyag Ikonok",
  "sidebar.fontAwesome": "Betűtípus Fantasztikus",
  "sidebar.tables": "asztalok",
  "sidebar.basic": "Alapvető",
  "sidebar.dataTable": "Adattábla",
  "sidebar.responsive": "Fogékony",
  "sidebar.reactTable": "React táblázat",
  "sidebar.maps": "Maps",
  "sidebar.googleMaps": "Google térkép",
  "sidebar.leafletMaps": "Leaflet Térképek",
  "sidebar.inbox": "Bejövő",
  "sidebar.users": "felhasználók",
  "sidebar.userProfile1": "Felhasználói profil 1",
  "sidebar.userProfile2": "Felhasználói profil 2",
  "sidebar.userManagement": "Felhasználói menedzsment",
  "sidebar.userProfile": "Felhasználói profil",
  "sidebar.userList": "Felhasználói lista",
  "sidebar.calendar": "Naptár",
  "sidebar.cultures": "kultúrák",
  "sidebar.dnd": "dnd",
  "sidebar.selectable": "választható",
  "sidebar.customRendering": "Egyéni renderelés",
  "sidebar.chat": "Csevegés",
  "sidebar.toDo": "Csinálni",
  "sidebar.editor": "Szerkesztő",
  "sidebar.wysiwygEditor": "WYSIWYG szerkesztő",
  "sidebar.quillEditor": "Quill szerkesztő",
  "sidebar.reactAce": "React Ace",
  "sidebar.dragAndDrop": "Drag and Drop",
  "sidebar.reactDragula": "Reagálj Dragulát",
  "sidebar.reactDnd": "React Dnd",
  "sidebar.blogManagement": "Blogkezelés",
  "sidebar.ecommerce": "E-kereskedelem",
  "sidebar.shopList": "Shop lista",
  "sidebar.shopGrid": "Shop Rács",
  "sidebar.invoice": "Számla",
  "sidebar.multilevel": "többszintű",
  "sidebar.sublevel": "szintalatti",
  "widgets.totalEarns": "Összes keres",
  "widgets.emailsStatistics": "E-mailek statisztikái",
  "widgets.totalRevenue": "Összes bevétel",
  "widgets.onlineVistors": "Online Vistors",
  "widgets.trafficSources": "Forgalomforrások",
  "widgets.RecentOrders": "legutóbbi rendelések",
  "widgets.topSellings": "Legjobb eladások",
  "widgets.productReports": "Termékjelentések",
  "widgets.productStats": "Termékstatisztikák",
  "widgets.ComposeEmail": "E-mail írása",
  "widgets.employeePayroll": "Munkavállalói bérszámfejtés",
  "widgets.visitors": "Látogatók",
  "widgets.orders": "rendelés",
  "widgets.orderStatus": "Rendelés állapota",
  "widgets.totalSales": "Teljes értékesítés",
  "widgets.ratings": "Értékelések",
  "widgets.netProfit": "Nettó nyereség",
  "widgets.overallTrafficStatus": "Átfogó forgalmi állapot",
  "widgets.tax": "Adó",
  "widgets.expenses": "Költségek",
  "widgets.currentTime": "Aktuális idő",
  "widgets.currentDate": "Mostani dátum",
  "widgets.todayOrders": "Ma megrendel",
  "widgets.toDoList": "To Do Lists",
  "widgets.discoverPeople": "Fedezze fel az embereket",
  "widgets.commments": "Hozzászólások",
  "widgets.newCustomers": "új vásárlók",
  "widgets.recentNotifications": "Legutóbbi értesítések",
  "widgets.appNotifications": "Alkalmazás értesítések",
  "widgets.newEmails": "Új e-mailek",
  "widgets.siteVisitors": "Látogatók webhelye",
  "widgets.socialCompanines": "Szociális társaságok",
  "widgets.recentActivities": "Legutóbbi tevékenységek",
  "widgets.recentOrders": "legutóbbi rendelések",
  "widgets.gallery": "Képtár",
  "widgets.pricing": "Árazás",
  "widgets.enterpriseEdition": "Enterprise Edition",
  "widgets.personalEdition": "Személyes kiadás",
  "widgets.teamEdition": "Team Edition",
  "widgets.standard": "Alapértelmezett",
  "widgets.advanced": "Fejlett",
  "widgets.master": "Fő",
  "widgets.Mega": "Mega",
  "widgets.logIn": "Belépés",
  "widgets.signUp": "Regisztrálj",
  "widgets.lockScreen": "Zárolás képernyő",
  "widgets.alertsWithLink": "Figyelmeztetések a kapcsolattal",
  "widgets.additionalContent": "További tartalom",
  "widgets.alertDismiss": "Riasztás elutasítása",
  "widgets.uncontrolledDisableAlerts": "Figyelmeztetések kikapcsolása tiltva",
  "widgets.contexualAlerts": "Contexuális figyelmeztetések",
  "widgets.alertsWithIcons": "Figyelmeztetések ikonokkal",
  "widgets.Simple App Bars": "Egyszerű app bárok",
  "widgets.appBarsWithButtons": "Alkalmazásbárok gombokkal",
  "widgets.imageAvatars": "Image Avatárok",
  "widgets.lettersAvatars": "Levelek avatarok",
  "widgets.iconsAvatars": "Ikonok Avatarok",
  "widgets.flatButtons": "Lapos gombok",
  "widgets.raisedButton": "Emelt gomb",
  "widgets.buttonWithIconAndLabel": "Gomb ikon és címke",
  "widgets.floatingActionButtons": "Lebegő akciógombok",
  "widgets.iconButton": "Ikon gomb",
  "widgets.socialMediaButton": "Social Media gomb",
  "widgets.reactButton": "Reakt gomb",
  "widgets.buttonOutline": "Gomb körvonal",
  "widgets.buttonSize": "Gomb méret",
  "widgets.buttonState": "Gomb állapot",
  "widgets.buttonNavigationWithNoLabel": "gomb Navigáció nem címkével",
  "widgets.buttonNavigation": "Gomb navigáció",
  "widgets.iconNavigation": "Ikon navigáció",
  "widgets.badgeWithHeadings": "Jelvény a fejléccel",
  "widgets.contexualVariations": "Contexuális változatok",
  "widgets.badgeLinks": "Jelvény Linkek",
  "widgets.materialBadge": "Anyag jelvény",
  "widgets.simpleCards": "Egyszerű kártyák",
  "widgets.backgroundVarient": "Háttérvariáns",
  "widgets.cardOutline": "Kártya Vázlat",
  "widgets.overlayCard": "Átfedő kártya",
  "widgets.cardGroup": "Kártya csoport",
  "widgets.cardTitle": "Kártya címe",
  "widgets.speacialTitleTreatment": "Témakörök kezeléset",
  "widgets.chipWithClickEvent": "csip Val vel Kattints esemény",
  "widgets.chipArray": "csip Sor",
  "widgets.dialogs": "dialógusok",
  "widgets.listDividers": "Listaosztók",
  "widgets.insetDividers": "Beilleszkedő oszlopok",
  "widgets.temporaryDrawers": "Ideiglenes fiókok",
  "widgets.permanentDrawers": "Állandó fiókok",
  "widgets.simpleExpansionPanel": "Egyszerű bővítőpanel",
  "widgets.controlledAccordion": "Ellenőrzött harmonika",
  "widgets.secondaryHeadingAndColumns": "Másodlagos cím és oszlopok",
  "widgets.imageOnlyGridLists": "Kép csak rácslista",
  "widgets.advancedGridLists": "Haladó rácslista",
  "widgets.singleLineGridLists": "Egysoros rácslista",
  "widgets.simpleLists": "Egyszerű listák",
  "widgets.folderLists": "Mappa listák",
  "widgets.listItemWithImage": "Listaelem képekkel",
  "widgets.switchLists": "Váltás listák",
  "widgets.insetLists": "Beágyazási listák",
  "widgets.nestedLists": "Beágyazott listák",
  "widgets.checkboxListControl": "jelölőnégyzetet Lista Ellenőrzés",
  "widgets.pinedSubHeader": "pined alfejezet",
  "widgets.InteractiveLists": "Interaktív listák",
  "widgets.simpleMenus": "Egyszerű menük",
  "widgets.selectedMenu": "Kiválasztott menü",
  "widgets.maxHeightMenu": "Max Height Menü",
  "widgets.changeTransition": "Átmenet módosítása",
  "widgets.paper": "Papír",
  "widgets.anchorPlayGround": "Horgony Játék Talaj",
  "widgets.tooltip": "ToolTip",
  "widgets.positionedToolTips": "Pozícionált Snackbar",
  "widgets.circularProgressBottomStart": "Kerek előrehaladás a fenékindításhoz",
  "widgets.interactiveIntegration": "Interaktív integráció",
  "widgets.determinate": "Meghatározó",
  "widgets.linearProgressLineBar": "Lineáris haladási vonal",
  "widgets.indeterminate": "Határozatlan",
  "widgets.buffer": "ütköző",
  "widgets.query": "kérdés",
  "widgets.transitionControlDirection": "Átmeneti irányítás",
  "widgets.simpleSnackbar": "Egyszerű snackbár",
  "widgets.positionedSnackbar": "Pozícionált snackbár",
  "widgets.contexualColoredSnackbars": "Contexual színes falatozók",
  "widgets.simpleCheckbox": "Egyszerű jelölőnégyzet",
  "widgets.interminateSelection": "Közbenső kiválasztás",
  "widgets.disabledCheckbox": "Disabled Checkbox",
  "widgets.customColorCheckbox": "Egyedi színű jelölőnégyzet",
  "widgets.VerticalStyleCheckbox": "Függőleges stílus jelölőnégyzet",
  "widgets.horizontalStyleCheckbox": "Horizontális stílus jelölőnégyzet",
  "widgets.radioButtons": "Rádiógombok",
  "widgets.disabledRadio": "Tiltott rádió",
  "widgets.withError": "Hiba",
  "widgets.switches": "Swiches",
  "widgets.dateAndTimePicker": "Dátum és időválasztó",
  "widgets.defaultPicker": "Alapértelmezett választó",
  "widgets.timePicker": "Időmérő",
  "widgets.weekPicker": "Hétválasztó",
  "widgets.defaultDatePicker": "Alapértelmezett dátumválasztó",
  "widgets.customPicker": "Egyéni komissiózó",
  "widgets.tabs": "Lapok",
  "widgets.fixedTabs": "Fix lapok",
  "widgets.basicTab": "Alapvető fül",
  "widgets.wrappedLabels": "Csomagolt címkék",
  "widgets.centeredLabels": "Központosított címkék",
  "widgets.forcedScrolledButtons": "Kényszerített görgetett gombok",
  "widgets.iconsTabs": "Ikonok lapok",
  "widgets.withDisableTabs": "A Letiltás lapok segítségével",
  "widgets.iconWithLabel": "Ikon címkével",
  "widgets.stepper": "Stepper",
  "widgets.horizontalLinear": "Vízszintesen lineáris",
  "widgets.horizontalNonLinear": "Vízszintes nem lineáris",
  "widgets.horizontalLinerAlternativeLabel":
    "Vízszintes bélés alternatív címke",
  "widgets.horizontalNonLinerAlternativeLabel":
    "Vízszintes nem liner alternatív címke",
  "widgets.verticalStepper": "Függőleges léptető",
  "widgets.descriptionAlert": "Leírás Figyelmeztetés",
  "widgets.customIconAlert": "Egyéni ikon figyelmeztetés",
  "widgets.withHtmlAlert": "Html figyelmeztetéssel",
  "widgets.promptAlert": "Gyors figyelmeztetés",
  "widgets.passwordPromptAlert": "Jelszó gyors figyelmeztetés",
  "widgets.customStyleAlert": "Egyéni stílus figyelmeztetés",
  "widgets.autoComplete": "Auto Complete",
  "widgets.reactSelect": "Reagál választ",
  "widgets.downshiftAutoComplete": "Lefelé váltás automatikus befejezése",
  "widgets.reactAutoSuggests": "React Auto Javasolja",
  "widgets.aboutUs": "Rólunk",
  "widgets.ourVission": "A mi vádunk",
  "widgets.ourMissions": "Küldetéseink",
  "widgets.ourMotivation": "Motivációnk",
  "widgets.defualtReactForm": "Defualt Reagál Forma",
  "widgets.url": "url",
  "widgets.textArea": "Szövegterület",
  "widgets.file": "fájl",
  "widgets.formGrid": "Forma Rács",
  "widgets.inlineForm": "Inline formanyomtatvány",
  "widgets.inputSizing": "Bemeneti méretezés",
  "widgets.inputGridSizing": "Bemeneti rácsméretezés",
  "widgets.hiddenLabels": "Rejtett címkék",
  "widgets.formValidation": "Forma validálás",
  "widgets.number": "Szám",
  "widgets.date": "Dátum",
  "widgets.time": "Idő",
  "widgets.color": "Szín",
  "widgets.search": "Keresés",
  "widgets.selectMultiple": "Válassza a Többszörös lehetőséget",
  "widgets.inputWithSuccess": "Bevitel sikerrel",
  "widgets.inputWithDanger": "Veszélyes bemenet",
  "widgets.simpleTextField": "Egyszerű szövegmező",
  "widgets.componet": "Alkatrészek",
  "widgets.layouts": "elrendezések",
  "widgets.inputAdorements": "Beviteli kapcsolatok",
  "widgets.formattedInputs": "Formázott bemenetek",
  "widgets.simpleSelect": "Egyszerű választás",
  "widgets.nativeSelect": "Anyanyelvi választ",
  "widgets.MutltiSelectList": "Mutlti választ Lista",
  "widgets.lineChart": "Vonalrajz",
  "widgets.barChart": "Oszlopdiagram",
  "widgets.stackedBarChart": "Halmozott oszlopdiagram",
  "widgets.lineBarAreaChart": "Vonalbár területrajz",
  "widgets.areaChart": "Területi Diagram",
  "widgets.stackedAreaChart": "Halmozott területrajz",
  "widgets.verticalChart": "Függőleges diagram",
  "widgets.radarChart": "Radar diagram",
  "widgets.doughnut": "Fánk",
  "widgets.polarChart": "Polár diagram",
  "widgets.pieChart": "Kördiagram",
  "widgets.bubbleChart": "Buborék Diagram",
  "widgets.horizontalBar": "Horizonatl Bár",
  "widgets.basicTable": "Alapvető táblázat",
  "widgets.contexualColoredTable": "Contexuális színes asztal",
  "widgets.dataTable": "Adattábla",
  "widgets.employeeList": "Munkavállalói lista",
  "widgets.responsiveTable": "Érzékeny tábla",
  "widgets.responsiveFlipTable": "Érzékeny flip táblázat",
  "widgets.reactGridControlledStateMode": "React Grid vezérelt állapot mód",
  "widgets.googleMaps": "Google térkép",
  "widgets.productsReports": "Termékek jelentései",
  "widgets.taskList": "Feladat lista",
  "widgets.basicCalender": "Alapvető naptár",
  "widgets.culturesCalender": "Növénykultúrák",
  "widgets.dragAndDropCalender": "ellenállás és Csepp kalander",
  "widgets.selectableCalender": "Választható naptár",
  "widgets.customRendering": "Egyéni renderelés",
  "widgets.customCalender": "Egyéni naptár",
  "widgets.searchMailList": "Keresés a levéllisták között",
  "components.buyNow": "Vásárolj most",
  "compenets.choose": "Választ",
  "compenets.username": "Felhasználónév",
  "compenets.passwords": "jelszavak",
  "widgets.forgetPassword": "Elfelejtett jelszo",
  "compenets.signIn": "Bejelentkezés",
  "compenets.dontHaveAccountSignUp": "Dont Have Account SignUp",
  "compenets.enterUserName": "Adja meg a felhasználónevet",
  "compenets.enterEmailAddress": "Adja meg az email címét",
  "compenets.confirmPasswords": "Jelszavak megerősítése",
  "components.alreadyHavingAccountSignIn": "Már bejelentkezve",
  "components.enterYourPassword": "Írd be a jelszavad",
  "components.unlock": "Kinyit",
  "components.enterPasswords": "Adjon meg jelszavakat",
  "components.resetPassword": "Jelszó visszavonása",
  "components.pageNotfound": "Az oldal nem található",
  "components.goToHomePage": "Menj a főoldalra",
  "components.sorryServerGoesWrong": "Sajnos a szerver hibás",
  "components.persistentDrawer": "Tartós fiók",
  "components.back": "Hát",
  "components.next": "Következő",
  "components.completeStep": "Teljes lépés",
  "components.withHtml": "Html-vel",
  "components.prompt": "Gyors",
  "components.withDescription": "A leírással",
  "components.success": "Siker",
  "components.passwordPrompt": "Jelszó kérés",
  "components.warning": "Figyelem",
  "components.customIcon": "Egyéni ikon",
  "components.customStyle": "Egyéni stílus",
  "components.basic": "Alapvető",
  "components.submit": "beküldése",
  "components.compose": "Összeállít",
  "components.sendMessage": "Üzenet küldése",
  "components.addNewTasks": "Új feladatok hozzáadása",
  "components.addToCart": "Kosárba",
  "components.payNow": "Fizess most",
  "components.print": "Nyomtatás",
  "components.cart": "kocsi",
  "components.viewCart": "Kosár megtekintése",
  "components.checkout": "kijelentkezés",
  "widgets.QuickLinks": "Gyors linkek",
  "widgets.upgrade": "frissítés",
  "widgets.app": "App",
  "widgets.addNew": "Új hozzáadása",
  "widgets.orderDate": "Rendelés dátuma",
  "widgets.status": "Állapot",
  "widgets.trackingNumber": "Követési szám",
  "widgets.action": "Akció",
  "widgets.designation": "Kijelölés",
  "widgets.subject": "tantárgy",
  "widgets.send": "Elküld",
  "widgets.saveAsDrafts": "Piszkozatok mentése",
  "widgets.onlineSources": "Online források",
  "widgets.lastMonth": "Múlt hónap",
  "widgets.widgets": "Widgets",
  "widgets.listing": "felsorolás",
  "widgets.paid": "Fizetett",
  "widgets.refunded": "Visszautalva",
  "widgets.done": "Kész",
  "widgets.pending": "Függőben levő",
  "widgets.cancelled": "Törölve",
  "widgets.approve": "Jóváhagy",
  "widgets.following": "Következő",
  "widgets.follow": "kövesse",
  "widgets.graphs&Charts": "Grafikonok és grafikonok",
  "widgets.open": "Nyisd ki",
  "widgets.bounced": "Visszafordulást",
  "widgets.spam": "Levélszemét",
  "widgets.unset": "hatástalanított",
  "widgets.bandwidthUse": "Sávszélesség használata",
  "widgets.dataUse": "Adatok felhasználása",
  "widgets.unsubscribe": "Leiratkozás",
  "widgets.profile": "Profil",
  "widgets.messages": "üzenetek",
  "widgets.support": "Támogatás",
  "widgets.faq(s)": "FAQ (s)",
  "widgets.upgradePlains": "Upgrade Plains",
  "widgets.logOut": "Kijelentkezés",
  "widgets.mail": "Levél",
  "widgets.adminTheme": "Admin témát",
  "widgets.wordpressTheme": "Wordpress téma",
  "widgets.addToCart": "Kosárba",
  "widgets.plan": "Terv",
  "widgets.basic": "Alapvető",
  "widgets.pro": "Pro",
  "widgets.startToBasic": "Indítsa el az alapot",
  "widgets.upgradeToPro": "Frissítés Pro verzióra",
  "widgets.upgradeToAdvance": "Frissítés előre",
  "widgets.comparePlans": "Tervek összehasonlítása",
  "widgets.free": "Ingyenes",
  "widgets.frequentlyAskedQuestions": "Gyakran Ismételt Kérdések",
  "widgets.searchIdeas": "Keresés ötletek",
  "widgets.startDate": "Kezdő dátum",
  "widgets.endDate": "Befejezés dátuma",
  "widgets.category": "Kategória",
  "widgets.apply": "Alkalmaz",
  "widgets.downloadPdfReport": "Pdf jelentés letöltése",
  "widgets.yesterday": "Tegnap",
  "widgets.totalOrders": "Teljes rendelések",
  "widgets.totalVisitors": "Összes látogató",
  "widgets.typeYourQuestions": "Írja be a kérdéseit",
  "widgets.username": "Felhasználónév",
  "widgets.password": "Jelszó",
  "widgets.signIn": "Bejelentkezés",
  "widgets.enterYourPassword": "Írd be a jelszavad",
  "widgets.alreadyHavingAccountLogin": "Már rendelkezik fiók bejelentkezéssel",
  "widgets.composeMail": "Compose Mail",
  "widgets.issue": "Probléma",
  "widgets.recentChat": "Legutóbbi csevegés",
  "widgets.previousChat": "Előző csevegés",
  "widgets.all": "Minden",
  "widgets.filters": "Szűrők",
  "widgets.deleted": "törölve",
  "widgets.starred": "Csillagos",
  "widgets.frontend": "frontend",
  "widgets.backend": "háttér",
  "widgets.api": "Api",
  "widgets.simpleAppBar": "Egyszerű alkalmazássáv",
  "widgets.recents": "Legutóbbiak",
  "widgets.cardLink": "Kártya Link",
  "widgets.anotherLink": "Egy másik link",
  "widgets.cardSubtitle": "kártya felirat",
  "widgets.confirmationDialogs": "Megerősítő párbeszédpanelek",
  "widgets.deletableChip": "Törölhető chip",
  "widgets.customDeleteIconChip": "Egyéni ikon törlése",
  "widgets.openAlertDialog": "Nyílt figyelmeztetés párbeszédablak",
  "widgets.openResponsiveDialog": "Nyissa meg a válaszadó párbeszédablakot",
  "widgets.openSimpleDialog": "Nyissa meg az egyszerű párbeszédablakot",
  "widgets.openFormDialog": "Nyitott űrlap párbeszédablak",
  "widgets.follower": "Követő",
  "widgets.important": "Fontos",
  "widgets.private": "Magán",
  "widgets.openLeft": "Nyissa balra",
  "widgets.openRight": "Jobbra nyílik",
  "widgets.openTop": "Nyitott felső",
  "widgets.openBottom": "Nyisd meg lent",
  "widgets.selectTripDestination": "Válassza az Úticél célállomást",
  "widgets.pinnedSubheaderList": "Pinned Subheader List",
  "widgets.singleLineItem": "Egysoros tétel",
  "widgets.acceptTerms": "Feltételek elfogadása",
  "widgets.optionA": "A lehetőség",
  "widgets.optionB": "B opció",
  "widgets.optionC": "C opció",
  "widgets.optionM": "Opció M",
  "widgets.optionN": "Opció N",
  "widgets.optionO": "Opció O",
  "widgets.customColor": "Egyéni szín",
  "widgets.centeredTabs": "Központosított lapok",
  "widgets.multipleTabs": "Több lap",
  "widgets.preventScrolledButtons": "A görgetett gombok megakadályozása",
  "widgets.browse": "Böngésszen",
  "widgets.formValidate": "Forma Érvényesít",
  "widgets.code": "Kód",
  "widgets.company": "Vállalat",
  "widgets.price": "Ár",
  "widgets.change": "változás",
  "widgets.high": "Magas",
  "widgets.low": "Alacsony",
  "widgets.volume": "Hangerő",
  "widgets.personalDetails": "Személyes adatok",
  "widgets.occupation": "Foglalkozása",
  "widgets.companyName": "Cégnév",
  "widgets.phoneNo": "Telefonszám",
  "widgets.city": "Város",
  "widgets.zipCode": "Irányítószám",
  "widgets.updateProfile": "Profil frissítése",
  "widgets.reject": "Elutasít",
  "widgets.exportToExcel": "Exportálás Excel-be",
  "widgets.addNewUser": "Új felhasználó hozzáadása",
  "widgets.workWeek": "Munkahét",
  "widgets.agenda": "Napirend",
  "widgets.conference": "konferencia",
  "widgets.multilevel": "többszintű",
  "widgets.dailySales": "Napi értékesítés",
  "widgets.today": "Ma",
  "widgets.campaignPerformance": "Kampány teljesítmény",
  "widgets.supportRequest": "Támogatási kérelem",
  "widgets.usersList": "Felhasználók listája",
  "widgets.lastWeek": "Múlt hét",
  "themeOptions.sidebarOverlay": "Sidebar Overlay",
  "themeOptions.sidebarBackgroundImages": "Oldalsáv háttérképek",
  "themeOptions.appSettings": "Alkalmazásbeállítások",
  "themeOptions.sidebarImage": "Oldalsáv kép",
  "themeOptions.miniSidebar": "Mini Sidebar",
  "themeOptions.boxLayout": "Doboz elrendezés",
  "themeOptions.rtlLayout": "Rtl elrendezés",
  "themeOptions.darkMode": "Sötét mód",
  "themeOptions.sidebarLight": "Fény",
  "themeOptions.sidebarDark": "Sötét",
  "button.cancel": "Megszünteti",
  "button.add": "hozzáad",
  "button.update": "frissítés",
  "button.reply": "Válasz",
  "button.delete": "Töröl",
  "button.yes": "Igen",
  "button.viewAll": "Összes megtekintése",
  "button.like": "Mint",
  "button.assignNow": "Hozzárendelés most",
  "button.seeInsights": "Tekintse meg a Insights elemet",
  "sidebar.dateTimePicker": "Dátum és időválasztó",
  "components.summary": "összefoglalás",
  "hint.whatAreYouLookingFor": "Mit keresel",
  "Widgets.listing": "lista",
  "Components.yesterday": "Yesterday",
  "components.last7Days": "Utolsó 7 nap",
  "components.last1Month": "Last 1 Month",
  "components.last6Month": "Utolsó 6 hónap",
  "components.spaceUsed": "Használt hely",
  "components.followers": "követője",
  "components.trending": "Felkapott",
  "components.paid": "fizetett",
  "components.refunded": "Visszautalva",
  "components.done": "Kész",
  "components.pending": "Függő",
  "components.cancelled": "Törölt",
  "components.approve": "Jóváhagyás",
  "components.week": "Hét",
  "components.month": "Hónap",
  "components.year": "Year",
  "components.today": "Ma",
  "components.popularity": "Népszerűség",
  "components.email": "Email",
  "components.drafts": "",
  "components.sent": "Küldött",
  "components.trash": "Szemét",
  "components.all": "Minden",
  "components.do": "csinál",
  "components.title": "Cím",
  "components.projectName": "Projekt neve",
  "components.companyName": "Cégnév",
  "components.openAlert": "Nyílt figyelmeztetés",
  "components.slideInAlertDialog": "Csúsztassa a riasztási párbeszédablakban",
  "components.openFullScreenDialog":
    "Nyissa meg a teljes képernyős párbeszédpanelt",
  "components.basicChip": "Alap Chip",
  "components.clickableChip": "Clickable Chip",
  "components.left": "Balra",
  "components.right": "Jobb",
  "components.expansionPanel1": "Bővítőpanel 1",
  "components.expansionPanel2": "Bővítőpanel 2",
  "components.generalSetting": "Általános beállítás",
  "components.advancedSettings": "További beállítások",
  "components.firstName": "Keresztnév",
  "components.lastName": "Vezetéknév",
  "components.occupation": "Foglalkozása",
  "components.phoneNo": "Telefonszám",
  "components.address": "Cím",
  "components.city": "Város",
  "components.state": "Állapot",
  "components.zipCode": "Irányítószám",
  "components.social Connection": "Közösségi kapcsolat",
  "widgets.buyMore": "Többet vásárolnak",
  "widgets.trafficChannel": "Forgalmi csatorna",
  "widgets.stockExchange": "Tőzsde",
  "widgets.tweets": "Tweets",
  "widgets.ourLocations": "Helyszínek",
  "widgets.sales": "Sales",
  "widgets.to": "Nak nek",
  "widgets.shipTo": "Kiszállítás",
  "widgets.description": "Leírás",
  "widgets.unitPrice": "Egységár",
  "widgets.total": "Teljes",
  "widgets.note": "jegyzet",
  "widgets.chipWithAvatar": "Chip Avatárral",
  "widgets.chipWithTextAvatar": "Chip szöveggel Avatar",
  "widgets.chipWithIconAvatar": "Chip ikonral Avatar",
  "widgets.customClickableChip": "Egyéni Clickable Chip",
  "widgets.outlineChip": "Outline Chip",
  "widgets.disableChip": "Chip letiltása",
  "widgets.alertDialog": "Riasztási párbeszéd",
  "widgets.animatedSlideDialogs": "Animált dia párbeszédek",
  "widgets.fullScreenDialogs": "Teljes képernyős párbeszédek",
  "widgets.formDialogs": "Űrlap párbeszédablakok",
  "widgets.simpleDialogs": "Egyszerű párbeszédek",
  "widgets.responsiveFullScreen": "Érzékeny teljes képernyő",
  "widgets.primary": "Elsődleges",
  "widgets.social": "Társadalmi",
  "widgets.user": "használó",
  "widgets.admin": "admin",
  "widgets.permanentdrawer": "Állandó fiók",
  "widgets.persistentdrawer": "Tartós fiók",
  "widgets.swiches": "Swiches",
  "widgets.horizontalLinearAlternativeLabel":
    "Vízszintes lineáris alternatív címke",
  "widgets.horizontalNonLinearAlternativeLabel":
    "vízszintes, nem lineáris alternatív címke",
  "widgets.notifications": "értesítések",
  "widgets.basicAlert": "Alap riasztás",
  "widgets.successAlert": "Sikeres figyelmeztetés",
  "widgets.warningAlert": "Figyelmeztető figyelmeztetés",
  "widgets.reactAutoSuggest": "React Auto Javasolja",
  "widgets.components": "Alkatrészek",
  "widgets.inputAdornments": "Beviteli kapcsolatok",
  "widgets.multiSelectList": "Több kiválasztási lista",
  "widgets.contextualColoredTable": "Contexuális színes asztal",
  "widgets.updateYourEmailAddress": "Frissítse e-mail címét",
  "widgets.selectADefaultAddress": "Válasszon egy alapértelmezett címet",
  "widgets.activity": "Tevékenység",
  "widgets.basicCalendar": "Alapvető naptár",
  "widgets.culturesCalendar": "Kultúrák Naptár",
  "widgets.dragAndDropCalendar": "Húzza és tegye le a naptárat",
  "widgets.quillEditor": "Quill szerkesztő",
  "widgets.reactDND": "Reagálj DND-t",
  "widgets.dragula": "Dragula",
  "button.acceptTerms": "Feltételek elfogadása",
  "button.reject": "Elutasít",
  "button.addNew": "Új hozzáadása",
  "button.goToCampaign": "Menjen a kampányba",
  "button.viewProfile": "Profil megtekintése",
  "button.sendMessage": "Üzenet küldése",
  "button.saveNow": "Ments most",
  "button.pen": "Toll",
  "button.search": "Keresés",
  "button.downloadPdfReport": "Pdf jelentés letöltése",
  "button.primary": "Elsődleges",
  "button.secondary": "Másodlagos",
  "button.danger": "Veszély",
  "button.info": "Info",
  "button.success": "Siker",
  "button.warning": "Figyelem",
  "button.link": "Link",
  "button.smallButton": "Kis gomb",
  "button.largeButton": "Nagy gomb",
  "button.blockLevelButton": "Blokkszintű gomb",
  "button.primaryButton": "Elsődleges gomb",
  "button.button": "Gomb",
  "button.save": "Mentés",
  "button.openMenu": "Nyisd ki Menü",
  "button.openWithFadeTransition": "Nyitott a halványodás átmenetével",
  "button.openPopover": "Nyissa meg a Popover lehetőséget",
  "button.accept": "Elfogad",
  "button.click": "Kattints",
  "button.complete": "teljes",
  "button.back": "Hát",
  "button.next": "Következő",
  "button.completeStep": "Teljes lépés",
  "button.error": "Hiba",
  "button.writeNewMessage": "Új üzenet írása",
  "button.saveChanges": "Változtatások mentése",
  "button.addNewUser": "Új felhasználó hozzáadása",
  "button.more": "Több",
  "hint.searchMailList": "Keresés a levéllisták között",
  "widgets.AcceptorrRejectWithin": "Elfogadni vagy elutasítani",
  "widgets.quoteOfTheDay": "A nap idézete",
  "widgets.updated10Minago": "Frissítve 10 perccel ezelőtt",
  "widgets.personalSchedule": "Személyes ütemezés",
  "widgets.activeUsers": "Aktív felhasználók",
  "widgets.totalRequest": "Teljes kérelem",
  "widgets.new": "Új",
  "widgets.ShareWithFriends": "Oszd meg a barátaiddal!",
  "widgets.helpToShareText":
    "Segítsen elterjeszteni a világot azzal, hogy megosztja weboldalunkat barátainkkal és a közösségi médiában élő követőkkel!",
  "widgets.thisWeek": "Ezen a héten",
  "widgets.howWouldYouRateUs": "Hogyan ítélné meg nekünk?",
  "widgets.booking": "foglalás",
  "widgets.confirmed": "Megerősített",
  "widgets.monthly": "havi",
  "widgets.weekly": "heti",
  "widgets.target": "célpont",
  "widgets.totalActiveUsers": "Összes aktív felhasználó",
  "sidebar.user": "használó",
  "sidebar.miscellaneous": "Vegyes",
  "sidebar.promo": "Promo",
  "themeOptions.themeColor": "Téma Szín",
  "module.inbox": "Bejövő",
  "module.drafts": "",
  "module.sent": "Küldött",
  "module.trash": "Szemét",
  "module.spam": "Levélszemét",
  "module.frontend": "Frontend",
  "module.backend": "háttér",
  "module.api": "Api",
  "module.issue": "Probléma",
  "components.emailPrefrences": "E-mail beállítások",
  "components.myProfile": "A profilom",
  "sidebar.gettingStarted": "Elkezdeni",
  "widgets.deadline": "Határidő",
  "widgets.team": "Csapat",
  "widgets.projectManagement": "Projektmenedzsment",
  "widgets.latestPost": "Utolsó poszt",
  "widgets.projectTaskManagement": "Projekt feladatkezelés",
  "widgets.selectProject": "Válassza ki a Projektet",
  "widgets.activityBoard": "Tevékenységbizottság",
  "widgets.checklist": "Ellenőrző lista",
  "sidebar.shop": "Üzlet",
  "sidebar.cart": "kocsi",
  "sidebar.checkout": "kijelentkezés",
  "components.product": "Termék",
  "components.quantity": "Mennyiség",
  "components.totalPrice": "Teljes ár",
  "components.removeProduct": "Termék eltávolítása",
  "components.mobileNumber": "Mobil szám",
  "components.address2Optional": "2. cím (opcionális)",
  "components.country": "Ország",
  "components.zip": "Postai irányítószám",
  "components.saveContinue": "Mentés és folytatás",
  "components.placeOrder": "Rendelés",
  "components.payment": "Fizetés",
  "components.billingAddress": "számlázási cím",
  "components.ShippingAddressText":
    "A szállítási cím ugyanaz, mint a számlázási cím.",
  "components.CartEmptyText": "Az Ön kosara üres!",
  "components.NoItemFound": "Nincs találat",
  "components.goToShop": "Boltba menni",
  "components.cardNumber": "Kártyaszám",
  "components.expiryDate": "Lejárati dátum",
  "components.cvv": "CVV",
  "components.nameOnCard": "A kártyán szereplő név",
  "components.confirmPayment": "Fizetés megerősítése",
  "sidebar.saas": "SAAS",
  "sidebar.multiLevel": "többszintű",
  "sidebar.level1": "1. szint",
  "sidebar.level2": "2. szint",
  "sidebar.boxed": "Dobozban",
  "sidebar.news": "hírek",
  "sidebar.extensions": "Extensions",
  "sidebar.imageCropper": "Kép Cropper",
  "sidebar.videoPlayer": "Videó lejátszó",
  "sidebar.dropzone": "Dropzone",
  "widgets.baseConfig": "Base Config",
  "widgets.customControlBar": "Egyéni vezérlőpult",
  "widgets.withDownloadButton": "Letöltés gombbal",
  "widgets.httpLiveStreaming": "HTTP élő közvetítés",
  "widgets.keyboardShortcuts": "Gyorsbillentyűket",
  "button.useDefaultImage": "Alapértelmezett kép használata",
  "button.cropImage": "Crop kép",
  "widgets.preview": "Előnézet",
  "widgets.croppedImage": "Vágott kép",
  "sidebar.crm": "CRM",
  "widgets.transactionList": "tranzakciós lista",
  "widgets.transferReport": "átutalási jelentés",
  "widgets.expenseCategory": "költségkategória",
  "widgets.upcomingEvents": "Közelgő események",
  "widgets.OngoingProjects": "folyamatban lévő projektek",
  "widgets.ProjectStatus": "Projekt állapota",
  "widgets.LiveChatSupport": "élő chat-támogatás",
  "sidebar.projects": "projektek",
  "sidebar.projectDetail": "project részletek",
  "sidebar.clients": "ügyfelek",
  "sidebar.reports": "jelentések",
};
