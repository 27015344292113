import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  colors: ["#4267B2", "#3f729b", "#00acee", "#FF0000"],
};

function LineChartBig({ data }) {
  const graphData = [
    ["Day", "Facebook", "Instagram", "Twitter", "Youtube"],
    ...data,
  ];
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={graphData}
      options={options}
    />
  );
}
export default LineChartBig;
