import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  height: 300,
  plugins: {
    legend: {
      // display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function LineChartSmall({ data, isMultiple = false }) {
  const options = {
    responsive: true,
    height: 300,
    plugins: {
      legend: {
        display: isMultiple,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  // console.log(data, "data?.drizzle");
  const dataLabels = {
    labels,
    datasets: isMultiple
      ? [
          {
            label: "Gold Finches",
            data: isMultiple ? data?.drench : data,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Finches",
            data: isMultiple ? data?.drizzle : data,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ]
      : [
          {
            label: "",
            data: data,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
    backgroundColor: "#fff",
  };
  return <Line options={options} data={dataLabels} />;
}

export default LineChartSmall;
